.btn-icon {
  border: none;
  font-size: var(--text-lg);
  padding: 0.375rem 0.625rem;
}

.btn-icon.btn-lg {
  font-size: var(--text-xl);
  padding: 0.5rem 0.75rem;
}
