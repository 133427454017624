.breadcrumbs-container {
  display: none;
  padding: var(--s-3) 0 var(--s-2);
}

.breadcrumb-item::before {
  font-family: bootstrap-icons;
}

@include media-breakpoint-up(lg) {
  .breadcrumbs-container {
    display: block;
  }
}
