.highlight {
  --#{$prefix}highlight-bg: #{$highlight-bg};
  --#{$prefix}highlight-padding-x: #{$highlight-padding-x};
  --#{$prefix}highlight-padding-y: #{$highlight-padding-y};
  --#{$prefix}highlight-color: inherit;
  --#{$prefix}highlight-border-color: #{$highlight-border-color};
  --#{$prefix}highlight-border: #{$highlight-border-width} solid var(--#{$prefix}highlight-border-color);
  --#{$prefix}highlight-border-radius: #{$highlight-border-radius};

  position: relative;
  padding: var(--#{$prefix}highlight-padding-y) var(--#{$prefix}highlight-padding-x);
  color: var(--#{$prefix}highlight-color);
  background-color: var(--#{$prefix}highlight-bg);
  border: var(--#{$prefix}highlight-border);
  @include border-radius(var(--#{$prefix}highlight-border-radius));
}

.btn-primary .highlight,
.btn-secondary .highlight,
.btn-success .highlight,
.btn-info .highlight,
.btn-warning .highlight,
.btn-danger .highlight,
.btn-dark .highlight,
.bg-primary .highlight,
.bg-secondary .highlight,
.bg-success .highlight,
.bg-info .highlight,
.bg-warning .highlight,
.bg-danger .highlight,
.bg-dark .highlight {
  color: #fff;
  border-color: #fff;
  background: transparent;
}
