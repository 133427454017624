$gray-50: #fafafa;
$gray-100: #f4f4f5;
$gray-200: #e4e4e7;
$gray-300: #d4d4d8;
$gray-400: #a1a1aa;
$gray-500: #71717a;
$gray-600: #52525b;
$gray-700: #3f3f46;
$gray-800: #27272a;
$gray-900: #18181b;
$gray-950: #000000;

$red-50: #fff6f6;
$red-100: #fee2e2;
$red-200: #fecaca;
$red-300: #fca5a5;
$red-400: #f87171;
$red-500: #ef4444;
$red-600: #dc2626;
$red-700: #b91c1c;
$red-800: #991b1b;
$red-900: #7f1d1d;
$red-950: #280c08;

$orange-50: #fff9f1;
$orange-100: #ffedd5;
$orange-200: #fed7aa;
$orange-300: #fdba74;
$orange-400: #fb923c;
$orange-500: #f97316;
$orange-600: #f06030; // DSE Logo Orange
$orange-700: #c54412;
$orange-800: #9a3412;
$orange-900: #7c2d12;
$orange-950: #2c140c;

$yellow-50: #fffdee;
$yellow-100: #fef9c3;
$yellow-200: #fef08a;
$yellow-300: #fde047;
$yellow-400: #facc15;
$yellow-500: #eab308;
$yellow-600: #ca8a04;
$yellow-700: #a16207;
$yellow-800: #854d0e;
$yellow-900: #713f12;
$yellow-950: #352214;

$green-50: #f7fff8;
$green-100: #dffce4;
$green-200: #bbf8c7;
$green-300: #66f692;
$green-400: #1be271;
$green-500: #13c561;
$green-600: #02a34e;
$green-700: #008040; // DSE Logo Green
$green-800: #06662f;
$green-900: #0b5428;
$green-950: #132919;

$teal-50: #f2fffc;
$teal-100: #ccfbf1;
$teal-200: #99f6e4;
$teal-300: #56ebd4;
$teal-400: #2dd4bf;
$teal-500: #14b8a6;
$teal-600: #0d9488;
$teal-700: #0f766e;
$teal-800: #115e59;
$teal-900: #134e4a;
$teal-950: #0f201f;

$cyan-50: #f2feff;
$cyan-100: #cffafe;
$cyan-200: #a5f3fc;
$cyan-300: #67e8f9;
$cyan-400: #22d3ee;
$cyan-500: #06b6d4;
$cyan-600: #0891b2;
$cyan-700: #0e7490;
$cyan-800: #155e75;
$cyan-900: #164e63;
$cyan-950: #101f25;

$blue-50: #f5faff;
$blue-100: #daeafe;
$blue-200: #bfdbfe;
$blue-300: #95c4fe;
$blue-400: #5da5fa;
$blue-500: #1586f4;
$blue-600: #1377d9;
$blue-700: #126cc5;
$blue-800: #1860b0; // DSE Logo Blue
$blue-900: #00417f;
$blue-950: #061d38;

$indigo-50: #f4f7ff;
$indigo-100: #e0e7ff;
$indigo-200: #c7d2fe;
$indigo-300: #a5b4fc;
$indigo-400: #818cf8;
$indigo-500: #6366f1;
$indigo-600: #4f46e5;
$indigo-700: #4338ca;
$indigo-800: #3730a3;
$indigo-900: #312e81;
$indigo-950: #1a1b39;

$purple-50: #fcf9ff;
$purple-100: #f3e8ff;
$purple-200: #e9d5ff;
$purple-300: #d8b4fe;
$purple-400: #c084fc;
$purple-500: #a855f7;
$purple-600: #9333ea;
$purple-700: #7e22ce;
$purple-800: #6b21a8;
$purple-900: #581c87;
$purple-950: #261636;

$pink-50: #fdf6ff;
$pink-100: #fae8ff;
$pink-200: #f5d0fe;
$pink-300: #f0abfc;
$pink-400: #e879f9;
$pink-500: #d946ef;
$pink-600: #ca1ac3;
$pink-700: #af0999;
$pink-800: #8c1385;
$pink-900: #76156a;
$pink-950: #2d1329;

$dse-blue: $blue-800;
$dse-green: $green-700;
$dse-orange: $orange-600;

$blue: $blue-500;
$indigo: $indigo-500;
$purple: $purple-500;
$pink: $pink-500;
$red: $red-500;
$orange: $orange-400;
$yellow: $yellow-200;
$green: $green-500;
$teal: $teal-500;
$cyan: $cyan-500;

$primary-colors: (
  "primary-50": $blue-50,
  "primary-100": $blue-100,
  "primary-200": $blue-200,
  "primary-300": $blue-300,
  "primary-400": $blue-400,
  "primary-500": $blue-500,
  "primary-600": $blue-600,
  "primary-700": $blue-700,
  "primary-800": $blue-800,
  "primary-900": $blue-900,
  "primary-950": $blue-950,
);

$success-colors: (
  "success-50": $green-50,
  "success-100": $green-100,
  "success-200": $green-200,
  "success-300": $green-300,
  "success-400": $green-400,
  "success-500": $green-500,
  "success-600": $green-600,
  "success-700": $green-700,
  "success-800": $green-800,
  "success-900": $green-900,
  "success-950": $green-950,
);

$warning-colors: (
  "warning-50": $orange-50,
  "warning-100": $orange-100,
  "warning-200": $orange-200,
  "warning-300": $orange-300,
  "warning-400": $orange-400,
  "warning-500": $orange-500,
  "warning-600": $orange-600,
  "warning-700": $orange-700,
  "warning-800": $orange-800,
  "warning-900": $orange-900,
  "warning-950": $orange-950,
);

$danger-colors: (
  "danger-50": $red-50,
  "danger-100": $red-100,
  "danger-200": $red-200,
  "danger-300": $red-300,
  "danger-400": $red-400,
  "danger-500": $red-500,
  "danger-600": $red-600,
  "danger-700": $red-700,
  "danger-800": $red-800,
  "danger-900": $red-900,
  "danger-950": $red-950,
);

$info-colors: (
  "info-50": $indigo-50,
  "info-100": $indigo-100,
  "info-200": $indigo-200,
  "info-300": $indigo-300,
  "info-400": $indigo-400,
  "info-500": $indigo-500,
  "info-600": $indigo-600,
  "info-700": $indigo-700,
  "info-800": $indigo-800,
  "info-900": $indigo-900,
  "info-950": $indigo-950,
);

$grays: (
  50: $gray-50,
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900,
  950: $gray-950,
);

$all-colors: (
  "gray-50": $gray-50,
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900,
  "gray-950": $gray-950,
  "red-50": $red-50,
  "red-100": $red-100,
  "red-200": $red-200,
  "red-300": $red-300,
  "red-400": $red-400,
  "red-500": $red-500,
  "red-600": $red-600,
  "red-700": $red-700,
  "red-800": $red-800,
  "red-900": $red-900,
  "red-950": $red-950,
  "orange-50": $orange-50,
  "orange-100": $orange-100,
  "orange-200": $orange-200,
  "orange-300": $orange-300,
  "orange-400": $orange-400,
  "orange-500": $orange-500,
  "orange-600": $orange-600,
  "orange-700": $orange-700,
  "orange-800": $orange-800,
  "orange-900": $orange-900,
  "orange-950": $orange-950,
  "yellow-50": $yellow-50,
  "yellow-100": $yellow-100,
  "yellow-200": $yellow-200,
  "yellow-300": $yellow-300,
  "yellow-400": $yellow-400,
  "yellow-500": $yellow-500,
  "yellow-600": $yellow-600,
  "yellow-700": $yellow-700,
  "yellow-800": $yellow-800,
  "yellow-900": $yellow-900,
  "yellow-950": $yellow-950,
  "green-50": $green-50,
  "green-100": $green-100,
  "green-200": $green-200,
  "green-300": $green-300,
  "green-400": $green-400,
  "green-500": $green-500,
  "green-600": $green-600,
  "green-700": $green-700,
  "green-800": $green-800,
  "green-900": $green-900,
  "green-950": $green-950,
  "teal-50": $teal-50,
  "teal-100": $teal-100,
  "teal-200": $teal-200,
  "teal-300": $teal-300,
  "teal-400": $teal-400,
  "teal-500": $teal-500,
  "teal-600": $teal-600,
  "teal-700": $teal-700,
  "teal-800": $teal-800,
  "teal-900": $teal-900,
  "teal-950": $teal-950,
  "cyan-50": $cyan-50,
  "cyan-100": $cyan-100,
  "cyan-200": $cyan-200,
  "cyan-300": $cyan-300,
  "cyan-400": $cyan-400,
  "cyan-500": $cyan-500,
  "cyan-600": $cyan-600,
  "cyan-700": $cyan-700,
  "cyan-800": $cyan-800,
  "cyan-900": $cyan-900,
  "cyan-950": $cyan-950,
  "blue-50": $blue-50,
  "blue-100": $blue-100,
  "blue-200": $blue-200,
  "blue-300": $blue-300,
  "blue-400": $blue-400,
  "blue-500": $blue-500,
  "blue-600": $blue-600,
  "blue-700": $blue-700,
  "blue-800": $blue-800,
  "blue-900": $blue-900,
  "blue-950": $blue-950,
  "indigo-50": $indigo-50,
  "indigo-100": $indigo-100,
  "indigo-200": $indigo-200,
  "indigo-300": $indigo-300,
  "indigo-400": $indigo-400,
  "indigo-500": $indigo-500,
  "indigo-600": $indigo-600,
  "indigo-700": $indigo-700,
  "indigo-800": $indigo-800,
  "indigo-900": $indigo-900,
  "indigo-950": $indigo-950,
  "purple-50": $purple-50,
  "purple-100": $purple-100,
  "purple-200": $purple-200,
  "purple-300": $purple-300,
  "purple-400": $purple-400,
  "purple-500": $purple-500,
  "purple-600": $purple-600,
  "purple-700": $purple-700,
  "purple-800": $purple-800,
  "purple-900": $purple-900,
  "purple-950": $purple-950,
  "pink-50": $pink-50,
  "pink-100": $pink-100,
  "pink-200": $pink-200,
  "pink-300": $pink-300,
  "pink-400": $pink-400,
  "pink-500": $pink-500,
  "pink-600": $pink-600,
  "pink-700": $pink-700,
  "pink-800": $pink-800,
  "pink-900": $pink-900,
  "pink-950": $pink-950,
);

$primary: map-get($primary-colors, "primary-700");

$secondary: map-get($grays, 500);

$info: map-get($info-colors, "info-600");

$success: map-get(
  $map: $success-colors,
  $key: "success-700",
);

$warning: map-get(
  $map: $warning-colors,
  $key: "warning-500",
);

$danger: map-get(
  $map: $danger-colors,
  $key: "danger-600",
);

$gray: map-get(
  $map: $grays,
  $key: 300,
);

$light: map-get(
  $map: $grays,
  $key: 100,
);

$dark: map-get(
  $map: $grays,
  $key: 800,
);

$all-colors: map-merge($all-colors, $primary-colors);
$all-colors: map-merge($all-colors, $success-colors);
$all-colors: map-merge($all-colors, $warning-colors);
$all-colors: map-merge($all-colors, $danger-colors);
$all-colors: map-merge($all-colors, $info-colors);

$primary-text-emphasis: var(--primary-600);
$success-text-emphasis: var(--success-600);
$warning-text-emphasis: var(--warning-600);
$danger-text-emphasis: var(--danger-600);
$info-text-emphasis: var(--info-600);

$primary-text-emphasis-dark: var(--primary-400);
$success-text-emphasis-dark: var(--success-400);
$warning-text-emphasis-dark: var(--warning-400);
$danger-text-emphasis-dark: var(--danger-400);
$info-text-emphasis-dark: var(--info-400);

$primary-bg-subtle: var(--primary-50);
$success-bg-subtle: var(--success-50);
$warning-bg-subtle: var(--warning-50);
$danger-bg-subtle: var(--danger-50);
$info-bg-subtle: var(--info-50);

$primary-bg-subtle-dark: var(--primary-950);
$success-bg-subtle-dark: var(--success-950);
$warning-bg-subtle-dark: var(--warning-950);
$danger-bg-subtle-dark: var(--danger-950);
$info-bg-subtle-dark: var(--info-950);

$primary-border-subtle: var(--primary-100);
$success-border-subtle: var(--success-100);
$warning-border-subtle: var(--warning-100);
$danger-border-subtle: var(--danger-100);
$info-border-subtle: var(--info-100);

$primary-border-subtle-dark: var(--primary-900);
$success-border-subtle-dark: var(--success-900);
$warning-border-subtle-dark: var(--warning-900);
$danger-border-subtle-dark: var(--danger-900);
$info-border-subtle-dark: var(--info-900);

$primary-text-emphasis: var(--primary-700);
$success-text-emphasis: var(--success-700);
$warning-text-emphasis: var(--warning-700);
$danger-text-emphasis: var(--danger-700);
$info-text-emphasis: var(--info-700);

$code-color: var(--pink-600);
$code-color-dark: var(--pink-400);

// Borders
$border-radius: 0.375rem;
$border-radius-sm: 0.25rem;
$border-radius-lg: 0.5rem;

// Font size
$font-size-sm: var(--text-sm);
$font-size-lg: to-rem(17);

// Font families
$font-family-sans-serif: var(--font-sans);
$font-family-monospace: var(--font-monospace);
$font-family-base: var(--font-sans);
$font-family-code: var(--font-monospace);

$headings-color: var(--gray-800);
$headings-color-dark: var(--gray-200);

// Line height
$line-height-base: 1.75;
@include root-var(line-height-base, $line-height-base);
$line-height-display: 1.5;
@include root-var(line-height-display, $line-height-display);

// Input / Buttons
$input-border-radius: var(--border-radius);
$input-border-radius-sm: var(--border-radius-sm);
$input-border-radius-lg: var(--border-radius-lg);

$input-btn-font-family: var(--font-sans);
$input-btn-font-size: var(--text-sm);

$input-btn-font-size-sm: var(--text-xs);
$input-btn-font-size-lg: var(--text-lg);

$link-color: $primary;
$link-color-dark: map-get($primary-colors, "primary-300");

// Navbar
$navbar-light-color: rgba(var(--emphasis-color-rgb), 0.7);
$navbar-light-hover-color: var(--primary);

$container-padding-x: 2rem;
