.alert {
  line-height: var(--line-height-display);

  // Strip the margin from the last direct child of an alert
  > :last-child {
    margin-bottom: 0;
  }
}

.alert-lg {
  .alert-icon {
    --alert-icon-size: 1.75rem;
  }
}

.alert-icon {
  --alert-icon-size: 1.25rem;

  // Keep the original horizontal padding either side of the icon
  padding-left: calc(calc(var(--alert-padding-x) * 2) + var(--alert-icon-size));

  &::before {
    position: absolute;

    // Roughly 1/4 of the icon ends up as padding
    top: calc(var(--alert-padding-y) - calc(var(--alert-icon-size) / 4));
    left: var(--alert-padding-x);

    color: var(--alert-color);

    font-family: bootstrap-icons !important; // stylelint-disable declaration-no-important
    font-size: var(--alert-icon-size);
  }
}

.alert-info {
  &.alert-icon::before {
    content: "\F431";
  }
}

.alert-success {
  &.alert-icon::before {
    content: "\F26B";
  }
}

.alert-warning {
  &.alert-icon::before {
    content: "\F33B";
  }
}

.alert-danger {
  &.alert-icon::before {
    content: "\F333";
  }
}
