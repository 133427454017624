@charset "UTF-8";
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 100;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-Thin.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 300;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-Light.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-Regular.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-Medium.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiBold.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-Bold.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 100;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-ThinItalic.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 300;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-LightItalic.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 400;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-Italic.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 600;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiBoldItalic.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 700;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-BoldItalic.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans SemiCondensed";
  font-style: normal;
  font-weight: 100;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiCondensed.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans SemiCondensed";
  font-style: normal;
  font-weight: 300;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiCondensedLight.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans SemiCondensed";
  font-style: normal;
  font-weight: 400;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiCondensed.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans SemiCondensed";
  font-style: normal;
  font-weight: 500;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiCondensedMedium.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans SemiCondensed";
  font-style: normal;
  font-weight: 600;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiCondensedSemiBold.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans SemiCondensed";
  font-style: normal;
  font-weight: 700;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiCondensedBold.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans SemiCondensed";
  font-style: italic;
  font-weight: 100;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiCondensedItalic.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans SemiCondensed";
  font-style: italic;
  font-weight: 300;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiCondensedLightItalic.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans SemiCondensed";
  font-style: italic;
  font-weight: 400;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiCondensedItalic.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans SemiCondensed";
  font-style: italic;
  font-weight: 500;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiCondensedMediumItalic.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans SemiCondensed";
  font-style: italic;
  font-weight: 600;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiCondensedSemiBoldItalic.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans SemiCondensed";
  font-style: italic;
  font-weight: 700;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiCondensedBoldItalic.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 300;
  src: url("https://cdn.down-syndrome.org/fonts/noto-serif/v2.015/NotoSerif-Light.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 400;
  src: url("https://cdn.down-syndrome.org/fonts/noto-serif/v2.015/NotoSerif-Regular.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Serif";
  font-style: italic;
  font-weight: 300;
  src: url("https://cdn.down-syndrome.org/fonts/noto-serif/v2.015/NotoSerif-ThinItalic.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Serif";
  font-style: italic;
  font-weight: 400;
  src: url("https://cdn.down-syndrome.org/fonts/noto-serif/v2.015/NotoSerif-Italic.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 700;
  src: url("https://cdn.down-syndrome.org/fonts/noto-serif/v2.015/NotoSerif-Bold.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans Mono";
  font-style: normal;
  font-weight: 400;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans-mono/v2.014/NotoSansMono-Regular.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans Mono";
  font-style: normal;
  font-weight: 600;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans-mono/v2.014/NotoSansMono-SemiBold.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans Mono";
  font-style: normal;
  font-weight: 700;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans-mono/v2.014/NotoSansMono-Bold.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans Mono SemiCondensed";
  font-style: normal;
  font-weight: 400;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans-mono/v2.014/NotoSansMono-SemiCondensed.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans Mono SemiCondensed";
  font-style: normal;
  font-weight: 600;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans-mono/v2.014/NotoSansMono-SemiCondensedSemiBold.woff2") format("woff2");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans Mono SemiCondensed";
  font-style: normal;
  font-weight: 700;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans-mono/v2.014/NotoSansMono-SemiCondensedBold.woff2") format("woff2");
  font-display: swap;
}
:root,
[data-bs-theme=light] {
  --blue: #1586f4;
  --indigo: #6366f1;
  --purple: #a855f7;
  --pink: #d946ef;
  --red: #ef4444;
  --orange: #fb923c;
  --yellow: #fef08a;
  --green: #13c561;
  --teal: #14b8a6;
  --cyan: #06b6d4;
  --black: #000;
  --white: #fff;
  --gray: #52525b;
  --gray-dark: #27272a;
  --gray-50: #fafafa;
  --gray-100: #f4f4f5;
  --gray-200: #e4e4e7;
  --gray-300: #d4d4d8;
  --gray-400: #a1a1aa;
  --gray-500: #71717a;
  --gray-600: #52525b;
  --gray-700: #3f3f46;
  --gray-800: #27272a;
  --gray-900: #18181b;
  --gray-950: #000000;
  --primary: #126cc5;
  --secondary: #71717a;
  --success: #008040;
  --info: #4f46e5;
  --warning: #f97316;
  --danger: #dc2626;
  --light: #f4f4f5;
  --dark: #27272a;
  --primary-rgb: 18, 108, 197;
  --secondary-rgb: 113, 113, 122;
  --success-rgb: 0, 128, 64;
  --info-rgb: 79, 70, 229;
  --warning-rgb: 249, 115, 22;
  --danger-rgb: 220, 38, 38;
  --light-rgb: 244, 244, 245;
  --dark-rgb: 39, 39, 42;
  --primary-text-emphasis: var(--primary-700);
  --secondary-text-emphasis: rgb(45.2, 45.2, 48.8);
  --success-text-emphasis: var(--success-700);
  --info-text-emphasis: var(--info-700);
  --warning-text-emphasis: var(--warning-700);
  --danger-text-emphasis: var(--danger-700);
  --light-text-emphasis: #3f3f46;
  --dark-text-emphasis: #3f3f46;
  --primary-bg-subtle: var(--primary-50);
  --secondary-bg-subtle: rgb(226.6, 226.6, 228.4);
  --success-bg-subtle: var(--success-50);
  --info-bg-subtle: var(--info-50);
  --warning-bg-subtle: var(--warning-50);
  --danger-bg-subtle: var(--danger-50);
  --light-bg-subtle: rgb(249.5, 249.5, 250);
  --dark-bg-subtle: #a1a1aa;
  --primary-border-subtle: var(--primary-100);
  --secondary-border-subtle: rgb(198.2, 198.2, 201.8);
  --success-border-subtle: var(--success-100);
  --info-border-subtle: var(--info-100);
  --warning-border-subtle: var(--warning-100);
  --danger-border-subtle: var(--danger-100);
  --light-border-subtle: #e4e4e7;
  --dark-border-subtle: #71717a;
  --white-rgb: 255, 255, 255;
  --black-rgb: 0, 0, 0;
  --font-sans-serif: var(--font-sans);
  --font-monospace: var(--font-monospace);
  --gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --body-font-family: var(--font-sans);
  --body-font-size: 1rem;
  --body-font-weight: 400;
  --body-line-height: 1.75;
  --body-color: #3f3f46;
  --body-color-rgb: 63, 63, 70;
  --body-bg: #fff;
  --body-bg-rgb: 255, 255, 255;
  --emphasis-color: #000;
  --emphasis-color-rgb: 0, 0, 0;
  --secondary-color: rgba(24, 24, 27, 0.75);
  --secondary-color-rgb: 24, 24, 27;
  --secondary-bg: #e4e4e7;
  --secondary-bg-rgb: 228, 228, 231;
  --tertiary-color: rgba(24, 24, 27, 0.5);
  --tertiary-color-rgb: 24, 24, 27;
  --tertiary-bg: #f4f4f5;
  --tertiary-bg-rgb: 244, 244, 245;
  --heading-color: var(--gray-800);
  --link-color: #126cc5;
  --link-color-rgb: 18, 108, 197;
  --link-decoration: none;
  --link-hover-color: rgb(14.4, 86.4, 157.6);
  --link-hover-color-rgb: 14, 86, 158;
  --link-hover-decoration: underline;
  --code-color: var(--pink-600);
  --highlight-color: #18181b;
  --highlight-bg: #fef9c3;
  --border-width: 1px;
  --border-style: solid;
  --border-color: #d4d4d8;
  --border-color-translucent: rgba(0, 0, 0, 0.175);
  --border-radius: 0.375rem;
  --border-radius-sm: 0.25rem;
  --border-radius-lg: 0.5rem;
  --border-radius-xl: 1rem;
  --border-radius-xxl: 2rem;
  --border-radius-2xl: var(--border-radius-xxl);
  --border-radius-pill: 50rem;
  --box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --focus-ring-width: 0.25rem;
  --focus-ring-opacity: 0.25;
  --focus-ring-color: rgba(18, 108, 197, 0.25);
  --form-valid-color: #008040;
  --form-valid-border-color: #008040;
  --form-invalid-color: #dc2626;
  --form-invalid-border-color: #dc2626;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --body-color: #d4d4d8;
  --body-color-rgb: 212, 212, 216;
  --body-bg: #18181b;
  --body-bg-rgb: 24, 24, 27;
  --emphasis-color: #fff;
  --emphasis-color-rgb: 255, 255, 255;
  --secondary-color: rgba(212, 212, 216, 0.75);
  --secondary-color-rgb: 212, 212, 216;
  --secondary-bg: #27272a;
  --secondary-bg-rgb: 39, 39, 42;
  --tertiary-color: rgba(212, 212, 216, 0.5);
  --tertiary-color-rgb: 212, 212, 216;
  --tertiary-bg: rgb(31.5, 31.5, 34.5);
  --tertiary-bg-rgb: 32, 32, 35;
  --primary-text-emphasis: var(--primary-400);
  --secondary-text-emphasis: rgb(169.8, 169.8, 175.2);
  --success-text-emphasis: var(--success-400);
  --info-text-emphasis: var(--info-400);
  --warning-text-emphasis: var(--warning-400);
  --danger-text-emphasis: var(--danger-400);
  --light-text-emphasis: #f4f4f5;
  --dark-text-emphasis: #d4d4d8;
  --primary-bg-subtle: var(--primary-950);
  --secondary-bg-subtle: rgb(22.6, 22.6, 24.4);
  --success-bg-subtle: var(--success-950);
  --info-bg-subtle: var(--info-950);
  --warning-bg-subtle: var(--warning-950);
  --danger-bg-subtle: var(--danger-950);
  --light-bg-subtle: #27272a;
  --dark-bg-subtle: rgb(19.5, 19.5, 21);
  --primary-border-subtle: var(--primary-900);
  --secondary-border-subtle: rgb(67.8, 67.8, 73.2);
  --success-border-subtle: var(--success-900);
  --info-border-subtle: var(--info-900);
  --warning-border-subtle: var(--warning-900);
  --danger-border-subtle: var(--danger-900);
  --light-border-subtle: #3f3f46;
  --dark-border-subtle: #27272a;
  --heading-color: var(--gray-200);
  --link-color: #95c4fe;
  --link-hover-color: rgb(170.2, 207.8, 254.2);
  --link-color-rgb: 149, 196, 254;
  --link-hover-color-rgb: 170, 208, 254;
  --code-color: var(--pink-400);
  --highlight-color: #d4d4d8;
  --highlight-bg: #854d0e;
  --border-color: #3f3f46;
  --border-color-translucent: rgba(255, 255, 255, 0.15);
  --form-valid-color: #66f692;
  --form-valid-border-color: #66f692;
  --form-invalid-color: #fca5a5;
  --form-invalid-border-color: #fca5a5;
}

:root {
  --line-height-base: 1.75;
  --line-height-display: 1.5;
  --s-0: 0;
  --s-1: 0.25rem;
  --s-2: 0.5rem;
  --s-3: 1rem;
  --s-4: 1.5rem;
  --s-5: 3rem;
  --gray-50: #fafafa;
  --gray-100: #f4f4f5;
  --gray-200: #e4e4e7;
  --gray-300: #d4d4d8;
  --gray-400: #a1a1aa;
  --gray-500: #71717a;
  --gray-600: #52525b;
  --gray-700: #3f3f46;
  --gray-800: #27272a;
  --gray-900: #18181b;
  --gray-950: #000000;
  --red-50: #fff6f6;
  --red-100: #fee2e2;
  --red-200: #fecaca;
  --red-300: #fca5a5;
  --red-400: #f87171;
  --red-500: #ef4444;
  --red-600: #dc2626;
  --red-700: #b91c1c;
  --red-800: #991b1b;
  --red-900: #7f1d1d;
  --red-950: #280c08;
  --orange-50: #fff9f1;
  --orange-100: #ffedd5;
  --orange-200: #fed7aa;
  --orange-300: #fdba74;
  --orange-400: #fb923c;
  --orange-500: #f97316;
  --orange-600: #f06030;
  --orange-700: #c54412;
  --orange-800: #9a3412;
  --orange-900: #7c2d12;
  --orange-950: #2c140c;
  --yellow-50: #fffdee;
  --yellow-100: #fef9c3;
  --yellow-200: #fef08a;
  --yellow-300: #fde047;
  --yellow-400: #facc15;
  --yellow-500: #eab308;
  --yellow-600: #ca8a04;
  --yellow-700: #a16207;
  --yellow-800: #854d0e;
  --yellow-900: #713f12;
  --yellow-950: #352214;
  --green-50: #f7fff8;
  --green-100: #dffce4;
  --green-200: #bbf8c7;
  --green-300: #66f692;
  --green-400: #1be271;
  --green-500: #13c561;
  --green-600: #02a34e;
  --green-700: #008040;
  --green-800: #06662f;
  --green-900: #0b5428;
  --green-950: #132919;
  --teal-50: #f2fffc;
  --teal-100: #ccfbf1;
  --teal-200: #99f6e4;
  --teal-300: #56ebd4;
  --teal-400: #2dd4bf;
  --teal-500: #14b8a6;
  --teal-600: #0d9488;
  --teal-700: #0f766e;
  --teal-800: #115e59;
  --teal-900: #134e4a;
  --teal-950: #0f201f;
  --cyan-50: #f2feff;
  --cyan-100: #cffafe;
  --cyan-200: #a5f3fc;
  --cyan-300: #67e8f9;
  --cyan-400: #22d3ee;
  --cyan-500: #06b6d4;
  --cyan-600: #0891b2;
  --cyan-700: #0e7490;
  --cyan-800: #155e75;
  --cyan-900: #164e63;
  --cyan-950: #101f25;
  --blue-50: #f5faff;
  --blue-100: #daeafe;
  --blue-200: #bfdbfe;
  --blue-300: #95c4fe;
  --blue-400: #5da5fa;
  --blue-500: #1586f4;
  --blue-600: #1377d9;
  --blue-700: #126cc5;
  --blue-800: #1860b0;
  --blue-900: #00417f;
  --blue-950: #061d38;
  --indigo-50: #f4f7ff;
  --indigo-100: #e0e7ff;
  --indigo-200: #c7d2fe;
  --indigo-300: #a5b4fc;
  --indigo-400: #818cf8;
  --indigo-500: #6366f1;
  --indigo-600: #4f46e5;
  --indigo-700: #4338ca;
  --indigo-800: #3730a3;
  --indigo-900: #312e81;
  --indigo-950: #1a1b39;
  --purple-50: #fcf9ff;
  --purple-100: #f3e8ff;
  --purple-200: #e9d5ff;
  --purple-300: #d8b4fe;
  --purple-400: #c084fc;
  --purple-500: #a855f7;
  --purple-600: #9333ea;
  --purple-700: #7e22ce;
  --purple-800: #6b21a8;
  --purple-900: #581c87;
  --purple-950: #261636;
  --pink-50: #fdf6ff;
  --pink-100: #fae8ff;
  --pink-200: #f5d0fe;
  --pink-300: #f0abfc;
  --pink-400: #e879f9;
  --pink-500: #d946ef;
  --pink-600: #ca1ac3;
  --pink-700: #af0999;
  --pink-800: #8c1385;
  --pink-900: #76156a;
  --pink-950: #2d1329;
  --primary-50: #f5faff;
  --primary-100: #daeafe;
  --primary-200: #bfdbfe;
  --primary-300: #95c4fe;
  --primary-400: #5da5fa;
  --primary-500: #1586f4;
  --primary-600: #1377d9;
  --primary-700: #126cc5;
  --primary-800: #1860b0;
  --primary-900: #00417f;
  --primary-950: #061d38;
  --success-50: #f7fff8;
  --success-100: #dffce4;
  --success-200: #bbf8c7;
  --success-300: #66f692;
  --success-400: #1be271;
  --success-500: #13c561;
  --success-600: #02a34e;
  --success-700: #008040;
  --success-800: #06662f;
  --success-900: #0b5428;
  --success-950: #132919;
  --warning-50: #fff9f1;
  --warning-100: #ffedd5;
  --warning-200: #fed7aa;
  --warning-300: #fdba74;
  --warning-400: #fb923c;
  --warning-500: #f97316;
  --warning-600: #f06030;
  --warning-700: #c54412;
  --warning-800: #9a3412;
  --warning-900: #7c2d12;
  --warning-950: #2c140c;
  --danger-50: #fff6f6;
  --danger-100: #fee2e2;
  --danger-200: #fecaca;
  --danger-300: #fca5a5;
  --danger-400: #f87171;
  --danger-500: #ef4444;
  --danger-600: #dc2626;
  --danger-700: #b91c1c;
  --danger-800: #991b1b;
  --danger-900: #7f1d1d;
  --danger-950: #280c08;
  --info-50: #f4f7ff;
  --info-100: #e0e7ff;
  --info-200: #c7d2fe;
  --info-300: #a5b4fc;
  --info-400: #818cf8;
  --info-500: #6366f1;
  --info-600: #4f46e5;
  --info-700: #4338ca;
  --info-800: #3730a3;
  --info-900: #312e81;
  --info-950: #1a1b39;
  --s-6: 5rem;
  --s-hero: clamp(4rem, 3.5rem + 2vw, 5rem);
  --font-sans: Noto Sans, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --font-serif: Noto Serif, Georgia, ui-serif, serif;
  --font-monospace: Noto Sans Mono, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  --font-system: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  --text-xxxs: 0.625rem;
  --text-xxs: 0.75rem;
  --text-xs: 0.875rem;
  --text-sm: 0.9375rem;
  --text-md: 1rem;
  --text-lg: 1.0625rem;
  --text-xl: 1.25rem;
  --text-xxl: 1.5rem;
  --font-thin: 100;
  --font-extralight: 200;
  --font-light: 300;
  --font-normal: 400;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;
  --font-extrabold: 800;
  --font-black: 900;
  --letter-spacing-text: normal;
  --letter-spacing-display: -0.025em;
  --mw-prose: 45rem;
  --prose-line-height: 1.75;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
  color: var(--body-color);
  text-align: var(--body-text-align);
  background-color: var(--body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: var(--s-3);
  font-family: var(--font-sans);
  font-weight: var(--font-semibold);
  line-height: var(--line-height-display);
  color: var(--heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--highlight-color);
  background-color: var(--highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--link-color-rgb), var(--link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --link-color-rgb: var(--link-hover-color-rgb);
  text-decoration: underline;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--body-bg);
  background-color: var(--body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

a {
  overflow-wrap: break-word;
}

.lead {
  font-size: min(5vw, var(--text-lg));
  font-weight: var(--font-medium);
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: var(--font-medium);
  line-height: var(--line-height-display);
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: var(--font-medium);
  line-height: var(--line-height-display);
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: var(--font-medium);
  line-height: var(--line-height-display);
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: var(--font-medium);
  line-height: var(--line-height-display);
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: var(--font-medium);
  line-height: var(--line-height-display);
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: var(--font-medium);
  line-height: var(--line-height-display);
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #52525b;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--body-bg);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --gutter-x: 2rem;
  --gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--gutter-x) * 0.5);
  padding-left: calc(var(--gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
@media print {
  .container {
    max-width: 100%;
    padding: 0;
  }
}
:root {
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
}

.row {
  --gutter-x: 1.5rem;
  --gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--gutter-y));
  margin-right: calc(-0.5 * var(--gutter-x));
  margin-left: calc(-0.5 * var(--gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--gutter-x) * 0.5);
  padding-left: calc(var(--gutter-x) * 0.5);
  margin-top: var(--gutter-y);
}

.grid {
  display: grid;
  grid-template-rows: repeat(var(--rows, 1), 1fr);
  grid-template-columns: repeat(var(--columns, 12), 1fr);
  gap: var(--gap, 1.5rem);
}
.grid .g-col-1 {
  grid-column: auto/span 1;
}
.grid .g-col-2 {
  grid-column: auto/span 2;
}
.grid .g-col-3 {
  grid-column: auto/span 3;
}
.grid .g-col-4 {
  grid-column: auto/span 4;
}
.grid .g-col-5 {
  grid-column: auto/span 5;
}
.grid .g-col-6 {
  grid-column: auto/span 6;
}
.grid .g-col-7 {
  grid-column: auto/span 7;
}
.grid .g-col-8 {
  grid-column: auto/span 8;
}
.grid .g-col-9 {
  grid-column: auto/span 9;
}
.grid .g-col-10 {
  grid-column: auto/span 10;
}
.grid .g-col-11 {
  grid-column: auto/span 11;
}
.grid .g-col-12 {
  grid-column: auto/span 12;
}
.grid .g-start-1 {
  grid-column-start: 1;
}
.grid .g-start-2 {
  grid-column-start: 2;
}
.grid .g-start-3 {
  grid-column-start: 3;
}
.grid .g-start-4 {
  grid-column-start: 4;
}
.grid .g-start-5 {
  grid-column-start: 5;
}
.grid .g-start-6 {
  grid-column-start: 6;
}
.grid .g-start-7 {
  grid-column-start: 7;
}
.grid .g-start-8 {
  grid-column-start: 8;
}
.grid .g-start-9 {
  grid-column-start: 9;
}
.grid .g-start-10 {
  grid-column-start: 10;
}
.grid .g-start-11 {
  grid-column-start: 11;
}
@media (min-width: 576px) {
  .grid .g-col-sm-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-sm-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-sm-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-sm-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-sm-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-sm-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-sm-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-sm-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-sm-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-sm-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-sm-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-sm-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-sm-1 {
    grid-column-start: 1;
  }
  .grid .g-start-sm-2 {
    grid-column-start: 2;
  }
  .grid .g-start-sm-3 {
    grid-column-start: 3;
  }
  .grid .g-start-sm-4 {
    grid-column-start: 4;
  }
  .grid .g-start-sm-5 {
    grid-column-start: 5;
  }
  .grid .g-start-sm-6 {
    grid-column-start: 6;
  }
  .grid .g-start-sm-7 {
    grid-column-start: 7;
  }
  .grid .g-start-sm-8 {
    grid-column-start: 8;
  }
  .grid .g-start-sm-9 {
    grid-column-start: 9;
  }
  .grid .g-start-sm-10 {
    grid-column-start: 10;
  }
  .grid .g-start-sm-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 768px) {
  .grid .g-col-md-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-md-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-md-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-md-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-md-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-md-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-md-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-md-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-md-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-md-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-md-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-md-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-md-1 {
    grid-column-start: 1;
  }
  .grid .g-start-md-2 {
    grid-column-start: 2;
  }
  .grid .g-start-md-3 {
    grid-column-start: 3;
  }
  .grid .g-start-md-4 {
    grid-column-start: 4;
  }
  .grid .g-start-md-5 {
    grid-column-start: 5;
  }
  .grid .g-start-md-6 {
    grid-column-start: 6;
  }
  .grid .g-start-md-7 {
    grid-column-start: 7;
  }
  .grid .g-start-md-8 {
    grid-column-start: 8;
  }
  .grid .g-start-md-9 {
    grid-column-start: 9;
  }
  .grid .g-start-md-10 {
    grid-column-start: 10;
  }
  .grid .g-start-md-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 992px) {
  .grid .g-col-lg-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-lg-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-lg-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-lg-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-lg-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-lg-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-lg-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-lg-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-lg-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-lg-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-lg-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-lg-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-lg-1 {
    grid-column-start: 1;
  }
  .grid .g-start-lg-2 {
    grid-column-start: 2;
  }
  .grid .g-start-lg-3 {
    grid-column-start: 3;
  }
  .grid .g-start-lg-4 {
    grid-column-start: 4;
  }
  .grid .g-start-lg-5 {
    grid-column-start: 5;
  }
  .grid .g-start-lg-6 {
    grid-column-start: 6;
  }
  .grid .g-start-lg-7 {
    grid-column-start: 7;
  }
  .grid .g-start-lg-8 {
    grid-column-start: 8;
  }
  .grid .g-start-lg-9 {
    grid-column-start: 9;
  }
  .grid .g-start-lg-10 {
    grid-column-start: 10;
  }
  .grid .g-start-lg-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 1200px) {
  .grid .g-col-xl-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-xl-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-xl-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-xl-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-xl-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-xl-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-xl-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-xl-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-xl-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-xl-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-xl-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-xl-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-xl-1 {
    grid-column-start: 1;
  }
  .grid .g-start-xl-2 {
    grid-column-start: 2;
  }
  .grid .g-start-xl-3 {
    grid-column-start: 3;
  }
  .grid .g-start-xl-4 {
    grid-column-start: 4;
  }
  .grid .g-start-xl-5 {
    grid-column-start: 5;
  }
  .grid .g-start-xl-6 {
    grid-column-start: 6;
  }
  .grid .g-start-xl-7 {
    grid-column-start: 7;
  }
  .grid .g-start-xl-8 {
    grid-column-start: 8;
  }
  .grid .g-start-xl-9 {
    grid-column-start: 9;
  }
  .grid .g-start-xl-10 {
    grid-column-start: 10;
  }
  .grid .g-start-xl-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 1400px) {
  .grid .g-col-xxl-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-xxl-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-xxl-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-xxl-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-xxl-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-xxl-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-xxl-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-xxl-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-xxl-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-xxl-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-xxl-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-xxl-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-xxl-1 {
    grid-column-start: 1;
  }
  .grid .g-start-xxl-2 {
    grid-column-start: 2;
  }
  .grid .g-start-xxl-3 {
    grid-column-start: 3;
  }
  .grid .g-start-xxl-4 {
    grid-column-start: 4;
  }
  .grid .g-start-xxl-5 {
    grid-column-start: 5;
  }
  .grid .g-start-xxl-6 {
    grid-column-start: 6;
  }
  .grid .g-start-xxl-7 {
    grid-column-start: 7;
  }
  .grid .g-start-xxl-8 {
    grid-column-start: 8;
  }
  .grid .g-start-xxl-9 {
    grid-column-start: 9;
  }
  .grid .g-start-xxl-10 {
    grid-column-start: 10;
  }
  .grid .g-start-xxl-11 {
    grid-column-start: 11;
  }
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --gutter-x: 0;
}

.g-0,
.gy-0 {
  --gutter-y: 0;
}

.g-1,
.gx-1 {
  --gutter-x: var(--s-1);
}

.g-1,
.gy-1 {
  --gutter-y: var(--s-1);
}

.g-2,
.gx-2 {
  --gutter-x: var(--s-2);
}

.g-2,
.gy-2 {
  --gutter-y: var(--s-2);
}

.g-3,
.gx-3 {
  --gutter-x: var(--s-3);
}

.g-3,
.gy-3 {
  --gutter-y: var(--s-3);
}

.g-4,
.gx-4 {
  --gutter-x: var(--s-4);
}

.g-4,
.gy-4 {
  --gutter-y: var(--s-4);
}

.g-5,
.gx-5 {
  --gutter-x: var(--s-5);
}

.g-5,
.gy-5 {
  --gutter-y: var(--s-5);
}

.g-6,
.gx-6 {
  --gutter-x: var(--s-6);
}

.g-6,
.gy-6 {
  --gutter-y: var(--s-6);
}

.g-hero,
.gx-hero {
  --gutter-x: var(--s-hero);
}

.g-hero,
.gy-hero {
  --gutter-y: var(--s-hero);
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --gutter-x: var(--s-1);
  }
  .g-sm-1,
  .gy-sm-1 {
    --gutter-y: var(--s-1);
  }
  .g-sm-2,
  .gx-sm-2 {
    --gutter-x: var(--s-2);
  }
  .g-sm-2,
  .gy-sm-2 {
    --gutter-y: var(--s-2);
  }
  .g-sm-3,
  .gx-sm-3 {
    --gutter-x: var(--s-3);
  }
  .g-sm-3,
  .gy-sm-3 {
    --gutter-y: var(--s-3);
  }
  .g-sm-4,
  .gx-sm-4 {
    --gutter-x: var(--s-4);
  }
  .g-sm-4,
  .gy-sm-4 {
    --gutter-y: var(--s-4);
  }
  .g-sm-5,
  .gx-sm-5 {
    --gutter-x: var(--s-5);
  }
  .g-sm-5,
  .gy-sm-5 {
    --gutter-y: var(--s-5);
  }
  .g-sm-6,
  .gx-sm-6 {
    --gutter-x: var(--s-6);
  }
  .g-sm-6,
  .gy-sm-6 {
    --gutter-y: var(--s-6);
  }
  .g-sm-hero,
  .gx-sm-hero {
    --gutter-x: var(--s-hero);
  }
  .g-sm-hero,
  .gy-sm-hero {
    --gutter-y: var(--s-hero);
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --gutter-x: var(--s-1);
  }
  .g-md-1,
  .gy-md-1 {
    --gutter-y: var(--s-1);
  }
  .g-md-2,
  .gx-md-2 {
    --gutter-x: var(--s-2);
  }
  .g-md-2,
  .gy-md-2 {
    --gutter-y: var(--s-2);
  }
  .g-md-3,
  .gx-md-3 {
    --gutter-x: var(--s-3);
  }
  .g-md-3,
  .gy-md-3 {
    --gutter-y: var(--s-3);
  }
  .g-md-4,
  .gx-md-4 {
    --gutter-x: var(--s-4);
  }
  .g-md-4,
  .gy-md-4 {
    --gutter-y: var(--s-4);
  }
  .g-md-5,
  .gx-md-5 {
    --gutter-x: var(--s-5);
  }
  .g-md-5,
  .gy-md-5 {
    --gutter-y: var(--s-5);
  }
  .g-md-6,
  .gx-md-6 {
    --gutter-x: var(--s-6);
  }
  .g-md-6,
  .gy-md-6 {
    --gutter-y: var(--s-6);
  }
  .g-md-hero,
  .gx-md-hero {
    --gutter-x: var(--s-hero);
  }
  .g-md-hero,
  .gy-md-hero {
    --gutter-y: var(--s-hero);
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --gutter-x: var(--s-1);
  }
  .g-lg-1,
  .gy-lg-1 {
    --gutter-y: var(--s-1);
  }
  .g-lg-2,
  .gx-lg-2 {
    --gutter-x: var(--s-2);
  }
  .g-lg-2,
  .gy-lg-2 {
    --gutter-y: var(--s-2);
  }
  .g-lg-3,
  .gx-lg-3 {
    --gutter-x: var(--s-3);
  }
  .g-lg-3,
  .gy-lg-3 {
    --gutter-y: var(--s-3);
  }
  .g-lg-4,
  .gx-lg-4 {
    --gutter-x: var(--s-4);
  }
  .g-lg-4,
  .gy-lg-4 {
    --gutter-y: var(--s-4);
  }
  .g-lg-5,
  .gx-lg-5 {
    --gutter-x: var(--s-5);
  }
  .g-lg-5,
  .gy-lg-5 {
    --gutter-y: var(--s-5);
  }
  .g-lg-6,
  .gx-lg-6 {
    --gutter-x: var(--s-6);
  }
  .g-lg-6,
  .gy-lg-6 {
    --gutter-y: var(--s-6);
  }
  .g-lg-hero,
  .gx-lg-hero {
    --gutter-x: var(--s-hero);
  }
  .g-lg-hero,
  .gy-lg-hero {
    --gutter-y: var(--s-hero);
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --gutter-x: var(--s-1);
  }
  .g-xl-1,
  .gy-xl-1 {
    --gutter-y: var(--s-1);
  }
  .g-xl-2,
  .gx-xl-2 {
    --gutter-x: var(--s-2);
  }
  .g-xl-2,
  .gy-xl-2 {
    --gutter-y: var(--s-2);
  }
  .g-xl-3,
  .gx-xl-3 {
    --gutter-x: var(--s-3);
  }
  .g-xl-3,
  .gy-xl-3 {
    --gutter-y: var(--s-3);
  }
  .g-xl-4,
  .gx-xl-4 {
    --gutter-x: var(--s-4);
  }
  .g-xl-4,
  .gy-xl-4 {
    --gutter-y: var(--s-4);
  }
  .g-xl-5,
  .gx-xl-5 {
    --gutter-x: var(--s-5);
  }
  .g-xl-5,
  .gy-xl-5 {
    --gutter-y: var(--s-5);
  }
  .g-xl-6,
  .gx-xl-6 {
    --gutter-x: var(--s-6);
  }
  .g-xl-6,
  .gy-xl-6 {
    --gutter-y: var(--s-6);
  }
  .g-xl-hero,
  .gx-xl-hero {
    --gutter-x: var(--s-hero);
  }
  .g-xl-hero,
  .gy-xl-hero {
    --gutter-y: var(--s-hero);
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --gutter-x: var(--s-1);
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --gutter-y: var(--s-1);
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --gutter-x: var(--s-2);
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --gutter-y: var(--s-2);
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --gutter-x: var(--s-3);
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --gutter-y: var(--s-3);
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --gutter-x: var(--s-4);
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --gutter-y: var(--s-4);
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --gutter-x: var(--s-5);
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --gutter-y: var(--s-5);
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --gutter-x: var(--s-6);
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --gutter-y: var(--s-6);
  }
  .g-xxl-hero,
  .gx-xxl-hero {
    --gutter-x: var(--s-hero);
  }
  .g-xxl-hero,
  .gy-xxl-hero {
    --gutter-y: var(--s-hero);
  }
}
.table {
  --table-color-type: initial;
  --table-bg-type: initial;
  --table-color-state: initial;
  --table-bg-state: initial;
  --table-color: var(--emphasis-color);
  --table-bg: var(--body-bg);
  --table-border-color: var(--border-color);
  --table-accent-bg: transparent;
  --table-striped-color: var(--emphasis-color);
  --table-striped-bg: rgba(var(--emphasis-color-rgb), 0.05);
  --table-active-color: var(--emphasis-color);
  --table-active-bg: rgba(var(--emphasis-color-rgb), 0.1);
  --table-hover-color: var(--emphasis-color);
  --table-hover-bg: rgba(var(--emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--table-color-state, var(--table-color-type, var(--table-color)));
  background-color: var(--table-bg);
  border-bottom-width: var(--border-width);
  box-shadow: inset 0 0 0 9999px var(--table-bg-state, var(--table-bg-type, var(--table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --table-color-type: var(--table-striped-color);
  --table-bg-type: var(--table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --table-color-type: var(--table-striped-color);
  --table-bg-type: var(--table-striped-bg);
}

.table-active {
  --table-color-state: var(--table-active-color);
  --table-bg-state: var(--table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --table-color-state: var(--table-hover-color);
  --table-bg-state: var(--table-hover-bg);
}

.table-primary {
  --table-color: #000;
  --table-bg: rgb(207.6, 225.6, 243.4);
  --table-border-color: rgb(166.08, 180.48, 194.72);
  --table-striped-bg: rgb(197.22, 214.32, 231.23);
  --table-striped-color: #000;
  --table-active-bg: rgb(186.84, 203.04, 219.06);
  --table-active-color: #000;
  --table-hover-bg: rgb(192.03, 208.68, 225.145);
  --table-hover-color: #000;
  color: var(--table-color);
  border-color: var(--table-border-color);
}

.table-secondary {
  --table-color: #000;
  --table-bg: rgb(226.6, 226.6, 228.4);
  --table-border-color: rgb(181.28, 181.28, 182.72);
  --table-striped-bg: rgb(215.27, 215.27, 216.98);
  --table-striped-color: #000;
  --table-active-bg: rgb(203.94, 203.94, 205.56);
  --table-active-color: #000;
  --table-hover-bg: rgb(209.605, 209.605, 211.27);
  --table-hover-color: #000;
  color: var(--table-color);
  border-color: var(--table-border-color);
}

.table-success {
  --table-color: #000;
  --table-bg: rgb(204, 229.6, 216.8);
  --table-border-color: rgb(163.2, 183.68, 173.44);
  --table-striped-bg: rgb(193.8, 218.12, 205.96);
  --table-striped-color: #000;
  --table-active-bg: rgb(183.6, 206.64, 195.12);
  --table-active-color: #000;
  --table-hover-bg: rgb(188.7, 212.38, 200.54);
  --table-hover-color: #000;
  color: var(--table-color);
  border-color: var(--table-border-color);
}

.table-info {
  --table-color: #000;
  --table-bg: rgb(219.8, 218, 249.8);
  --table-border-color: rgb(175.84, 174.4, 199.84);
  --table-striped-bg: rgb(208.81, 207.1, 237.31);
  --table-striped-color: #000;
  --table-active-bg: rgb(197.82, 196.2, 224.82);
  --table-active-color: #000;
  --table-hover-bg: rgb(203.315, 201.65, 231.065);
  --table-hover-color: #000;
  color: var(--table-color);
  border-color: var(--table-border-color);
}

.table-warning {
  --table-color: #000;
  --table-bg: rgb(253.8, 227, 208.4);
  --table-border-color: rgb(203.04, 181.6, 166.72);
  --table-striped-bg: rgb(241.11, 215.65, 197.98);
  --table-striped-color: #000;
  --table-active-bg: rgb(228.42, 204.3, 187.56);
  --table-active-color: #000;
  --table-hover-bg: rgb(234.765, 209.975, 192.77);
  --table-hover-color: #000;
  color: var(--table-color);
  border-color: var(--table-border-color);
}

.table-danger {
  --table-color: #000;
  --table-bg: rgb(248, 211.6, 211.6);
  --table-border-color: rgb(198.4, 169.28, 169.28);
  --table-striped-bg: rgb(235.6, 201.02, 201.02);
  --table-striped-color: #000;
  --table-active-bg: rgb(223.2, 190.44, 190.44);
  --table-active-color: #000;
  --table-hover-bg: rgb(229.4, 195.73, 195.73);
  --table-hover-color: #000;
  color: var(--table-color);
  border-color: var(--table-border-color);
}

.table-light {
  --table-color: #000;
  --table-bg: #f4f4f5;
  --table-border-color: rgb(195.2, 195.2, 196);
  --table-striped-bg: rgb(231.8, 231.8, 232.75);
  --table-striped-color: #000;
  --table-active-bg: rgb(219.6, 219.6, 220.5);
  --table-active-color: #000;
  --table-hover-bg: rgb(225.7, 225.7, 226.625);
  --table-hover-color: #000;
  color: var(--table-color);
  border-color: var(--table-border-color);
}

.table-dark {
  --table-color: #fff;
  --table-bg: #27272a;
  --table-border-color: rgb(82.2, 82.2, 84.6);
  --table-striped-bg: rgb(49.8, 49.8, 52.65);
  --table-striped-color: #fff;
  --table-active-bg: rgb(60.6, 60.6, 63.3);
  --table-active-color: #fff;
  --table-hover-bg: rgb(55.2, 55.2, 57.975);
  --table-hover-color: #fff;
  color: var(--table-color);
  border-color: var(--table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
  font-weight: var(--font-medium);
  color: var(--gray-600);
}

.col-form-label {
  padding-top: calc(0.375rem + var(--border-width));
  padding-bottom: calc(0.375rem + var(--border-width));
  margin-bottom: 0;
  font-size: inherit;
  font-weight: var(--font-medium);
  line-height: 1.75;
  color: var(--gray-600);
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--border-width));
  padding-bottom: calc(0.5rem + var(--border-width));
  font-size: var(--text-lg);
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--border-width));
  padding-bottom: calc(0.25rem + var(--border-width));
  font-size: var(--text-xs);
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-family: var(--font-sans);
  font-size: var(--text-sm);
  font-weight: 400;
  line-height: 1.75;
  color: var(--body-color);
  appearance: none;
  background-color: var(--body-bg);
  background-clip: padding-box;
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--body-color);
  background-color: var(--body-bg);
  border-color: rgb(136.5, 181.5, 226);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(18, 108, 197, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.75em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--body-color);
  background-color: var(--tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--border-width);
  border-radius: 0;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out, border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.75;
  color: var(--body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.75em + 0.5rem + calc(var(--border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: var(--text-xs);
  border-radius: var(--border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.75em + 1rem + calc(var(--border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: var(--text-lg);
  border-radius: var(--border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.75em + 0.75rem + calc(var(--border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.75em + 0.5rem + calc(var(--border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.75em + 1rem + calc(var(--border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.75em + 0.75rem + calc(var(--border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.75em + 0.5rem + calc(var(--border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.75em + 1rem + calc(var(--border-width) * 2));
}

.form-select {
  --form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%2327272a' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-family: var(--font-sans);
  font-size: var(--text-sm);
  font-weight: 400;
  line-height: 1.75;
  color: var(--body-color);
  appearance: none;
  background-color: var(--body-bg);
  background-image: var(--form-select-bg-img), var(--form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: rgb(136.5, 181.5, 226);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(18, 108, 197, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: var(--text-xs);
  border-radius: var(--border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: var(--text-lg);
  border-radius: var(--border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23d4d4d8' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.75rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --form-check-bg: var(--body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.375em;
  vertical-align: top;
  appearance: none;
  background-color: var(--form-check-bg);
  background-image: var(--form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--border-width) solid var(--border-color);
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: rgb(136.5, 181.5, 226);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(18, 108, 197, 0.25);
}
.form-check-input:checked {
  background-color: #126cc5;
  border-color: #126cc5;
}
.form-check-input:checked[type=checkbox] {
  --form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #126cc5;
  border-color: #126cc5;
  --form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgb%28136.5, 181.5, 226%29'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(18, 108, 197, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(18, 108, 197, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #126cc5;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: rgb(183.9, 210.9, 237.6);
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #126cc5;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: rgb(183.9, 210.9, 237.6);
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--border-width) * 2));
  min-height: calc(3.5rem + calc(var(--border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--body-bg);
  border-radius: var(--border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #52525b;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: var(--text-sm);
  font-weight: 400;
  line-height: 1.75;
  color: var(--body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--tertiary-bg);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: var(--text-lg);
  border-radius: var(--border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: var(--text-xs);
  border-radius: var(--border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: var(--text-sm);
  color: #fff;
  background-color: var(--success);
  border-radius: var(--border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--form-valid-border-color);
  padding-right: calc(1.75em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23008040' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4375em + 0.1875rem) center;
  background-size: calc(0.875em + 0.375rem) calc(0.875em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.75em + 0.75rem);
  background-position: top calc(0.4375em + 0.1875rem) right calc(0.4375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23008040' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.875em + 0.375rem) calc(0.875em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.75em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: var(--text-sm);
  color: #fff;
  background-color: var(--danger);
  border-radius: var(--border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--form-invalid-border-color);
  padding-right: calc(1.75em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc2626'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc2626' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4375em + 0.1875rem) center;
  background-size: calc(0.875em + 0.375rem) calc(0.875em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.75em + 0.75rem);
  background-position: top calc(0.4375em + 0.1875rem) right calc(0.4375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc2626'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc2626' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.875em + 0.375rem) calc(0.875em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.75em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --btn-padding-x: 0.75rem;
  --btn-padding-y: var(--s-1);
  --btn-font-family: var(--font-sans);
  --btn-font-size: var(--text-sm);
  --btn-font-weight: var(--font-normal);
  --btn-line-height: 1.75;
  --btn-color: var(--body-color);
  --btn-bg: transparent;
  --btn-border-width: var(--border-width);
  --btn-border-color: transparent;
  --btn-border-radius: var(--border-radius);
  --btn-hover-border-color: transparent;
  --btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --btn-disabled-opacity: 0.65;
  --btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--btn-padding-y) var(--btn-padding-x);
  font-family: var(--btn-font-family);
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  line-height: var(--btn-line-height);
  color: var(--btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--btn-border-width) solid var(--btn-border-color);
  border-radius: var(--btn-border-radius);
  background-color: var(--btn-bg);
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out, border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--btn-hover-color);
  text-decoration: none;
  background-color: var(--btn-hover-bg);
  border-color: var(--btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--btn-color);
  background-color: var(--btn-bg);
  border-color: var(--btn-border-color);
}
.btn:focus-visible {
  color: var(--btn-hover-color);
  background-color: var(--btn-hover-bg);
  border-color: var(--btn-hover-border-color);
  outline: 0;
  box-shadow: var(--btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--btn-hover-border-color);
  outline: 0;
  box-shadow: var(--btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--btn-active-color);
  background-color: var(--btn-active-bg);
  border-color: var(--btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--btn-disabled-color);
  pointer-events: none;
  background-color: var(--btn-disabled-bg);
  border-color: var(--btn-disabled-border-color);
  opacity: var(--btn-disabled-opacity);
}

.btn-primary {
  --btn-color: #fff;
  --btn-bg: #126cc5;
  --btn-border-color: #126cc5;
  --btn-hover-color: #fff;
  --btn-hover-bg: rgb(15.3, 91.8, 167.45);
  --btn-hover-border-color: rgb(14.4, 86.4, 157.6);
  --btn-focus-shadow-rgb: 54, 130, 206;
  --btn-active-color: #fff;
  --btn-active-bg: rgb(14.4, 86.4, 157.6);
  --btn-active-border-color: rgb(13.5, 81, 147.75);
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: #126cc5;
  --btn-disabled-border-color: #126cc5;
}

.btn-secondary {
  --btn-color: #fff;
  --btn-bg: #71717a;
  --btn-border-color: #71717a;
  --btn-hover-color: #fff;
  --btn-hover-bg: rgb(96.05, 96.05, 103.7);
  --btn-hover-border-color: rgb(90.4, 90.4, 97.6);
  --btn-focus-shadow-rgb: 134, 134, 142;
  --btn-active-color: #fff;
  --btn-active-bg: rgb(90.4, 90.4, 97.6);
  --btn-active-border-color: rgb(84.75, 84.75, 91.5);
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: #71717a;
  --btn-disabled-border-color: #71717a;
}

.btn-success {
  --btn-color: #fff;
  --btn-bg: #008040;
  --btn-border-color: #008040;
  --btn-hover-color: #fff;
  --btn-hover-bg: rgb(0, 108.8, 54.4);
  --btn-hover-border-color: rgb(0, 102.4, 51.2);
  --btn-focus-shadow-rgb: 38, 147, 93;
  --btn-active-color: #fff;
  --btn-active-bg: rgb(0, 102.4, 51.2);
  --btn-active-border-color: #006030;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: #008040;
  --btn-disabled-border-color: #008040;
}

.btn-info {
  --btn-color: #fff;
  --btn-bg: #4f46e5;
  --btn-border-color: #4f46e5;
  --btn-hover-color: #fff;
  --btn-hover-bg: rgb(67.15, 59.5, 194.65);
  --btn-hover-border-color: rgb(63.2, 56, 183.2);
  --btn-focus-shadow-rgb: 105, 98, 233;
  --btn-active-color: #fff;
  --btn-active-bg: rgb(63.2, 56, 183.2);
  --btn-active-border-color: rgb(59.25, 52.5, 171.75);
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: #4f46e5;
  --btn-disabled-border-color: #4f46e5;
}

.btn-warning {
  --btn-color: #000;
  --btn-bg: #f97316;
  --btn-border-color: #f97316;
  --btn-hover-color: #000;
  --btn-hover-bg: rgb(249.9, 136, 56.95);
  --btn-hover-border-color: rgb(249.6, 129, 45.3);
  --btn-focus-shadow-rgb: 212, 98, 19;
  --btn-active-color: #000;
  --btn-active-bg: rgb(250.2, 143, 68.6);
  --btn-active-border-color: rgb(249.6, 129, 45.3);
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #000;
  --btn-disabled-bg: #f97316;
  --btn-disabled-border-color: #f97316;
}

.btn-danger {
  --btn-color: #fff;
  --btn-bg: #dc2626;
  --btn-border-color: #dc2626;
  --btn-hover-color: #fff;
  --btn-hover-bg: rgb(187, 32.3, 32.3);
  --btn-hover-border-color: rgb(176, 30.4, 30.4);
  --btn-focus-shadow-rgb: 225, 71, 71;
  --btn-active-color: #fff;
  --btn-active-bg: rgb(176, 30.4, 30.4);
  --btn-active-border-color: rgb(165, 28.5, 28.5);
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: #dc2626;
  --btn-disabled-border-color: #dc2626;
}

.btn-light {
  --btn-color: #000;
  --btn-bg: #f4f4f5;
  --btn-border-color: #f4f4f5;
  --btn-hover-color: #000;
  --btn-hover-bg: rgb(207.4, 207.4, 208.25);
  --btn-hover-border-color: rgb(195.2, 195.2, 196);
  --btn-focus-shadow-rgb: 207, 207, 208;
  --btn-active-color: #000;
  --btn-active-bg: rgb(195.2, 195.2, 196);
  --btn-active-border-color: rgb(183, 183, 183.75);
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #000;
  --btn-disabled-bg: #f4f4f5;
  --btn-disabled-border-color: #f4f4f5;
}

.btn-dark {
  --btn-color: #fff;
  --btn-bg: #27272a;
  --btn-border-color: #27272a;
  --btn-hover-color: #fff;
  --btn-hover-bg: rgb(71.4, 71.4, 73.95);
  --btn-hover-border-color: rgb(60.6, 60.6, 63.3);
  --btn-focus-shadow-rgb: 71, 71, 74;
  --btn-active-color: #fff;
  --btn-active-bg: rgb(82.2, 82.2, 84.6);
  --btn-active-border-color: rgb(60.6, 60.6, 63.3);
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: #27272a;
  --btn-disabled-border-color: #27272a;
}

.btn-outline-primary {
  --btn-color: #126cc5;
  --btn-border-color: #126cc5;
  --btn-hover-color: #fff;
  --btn-hover-bg: #126cc5;
  --btn-hover-border-color: #126cc5;
  --btn-focus-shadow-rgb: 18, 108, 197;
  --btn-active-color: #fff;
  --btn-active-bg: #126cc5;
  --btn-active-border-color: #126cc5;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #126cc5;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #126cc5;
  --gradient: none;
}

.btn-outline-secondary {
  --btn-color: #71717a;
  --btn-border-color: #71717a;
  --btn-hover-color: #fff;
  --btn-hover-bg: #71717a;
  --btn-hover-border-color: #71717a;
  --btn-focus-shadow-rgb: 113, 113, 122;
  --btn-active-color: #fff;
  --btn-active-bg: #71717a;
  --btn-active-border-color: #71717a;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #71717a;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #71717a;
  --gradient: none;
}

.btn-outline-success {
  --btn-color: #008040;
  --btn-border-color: #008040;
  --btn-hover-color: #fff;
  --btn-hover-bg: #008040;
  --btn-hover-border-color: #008040;
  --btn-focus-shadow-rgb: 0, 128, 64;
  --btn-active-color: #fff;
  --btn-active-bg: #008040;
  --btn-active-border-color: #008040;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #008040;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #008040;
  --gradient: none;
}

.btn-outline-info {
  --btn-color: #4f46e5;
  --btn-border-color: #4f46e5;
  --btn-hover-color: #fff;
  --btn-hover-bg: #4f46e5;
  --btn-hover-border-color: #4f46e5;
  --btn-focus-shadow-rgb: 79, 70, 229;
  --btn-active-color: #fff;
  --btn-active-bg: #4f46e5;
  --btn-active-border-color: #4f46e5;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #4f46e5;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #4f46e5;
  --gradient: none;
}

.btn-outline-warning {
  --btn-color: #f97316;
  --btn-border-color: #f97316;
  --btn-hover-color: #000;
  --btn-hover-bg: #f97316;
  --btn-hover-border-color: #f97316;
  --btn-focus-shadow-rgb: 249, 115, 22;
  --btn-active-color: #000;
  --btn-active-bg: #f97316;
  --btn-active-border-color: #f97316;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #f97316;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #f97316;
  --gradient: none;
}

.btn-outline-danger {
  --btn-color: #dc2626;
  --btn-border-color: #dc2626;
  --btn-hover-color: #fff;
  --btn-hover-bg: #dc2626;
  --btn-hover-border-color: #dc2626;
  --btn-focus-shadow-rgb: 220, 38, 38;
  --btn-active-color: #fff;
  --btn-active-bg: #dc2626;
  --btn-active-border-color: #dc2626;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #dc2626;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #dc2626;
  --gradient: none;
}

.btn-outline-light {
  --btn-color: #f4f4f5;
  --btn-border-color: #f4f4f5;
  --btn-hover-color: #000;
  --btn-hover-bg: #f4f4f5;
  --btn-hover-border-color: #f4f4f5;
  --btn-focus-shadow-rgb: 244, 244, 245;
  --btn-active-color: #000;
  --btn-active-bg: #f4f4f5;
  --btn-active-border-color: #f4f4f5;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #f4f4f5;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #f4f4f5;
  --gradient: none;
}

.btn-outline-dark {
  --btn-color: #27272a;
  --btn-border-color: #27272a;
  --btn-hover-color: #fff;
  --btn-hover-bg: #27272a;
  --btn-hover-border-color: #27272a;
  --btn-focus-shadow-rgb: 39, 39, 42;
  --btn-active-color: #fff;
  --btn-active-bg: #27272a;
  --btn-active-border-color: #27272a;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #27272a;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #27272a;
  --gradient: none;
}

.btn-link {
  --btn-font-weight: 400;
  --btn-color: var(--link-color);
  --btn-bg: transparent;
  --btn-border-color: transparent;
  --btn-hover-color: var(--link-hover-color);
  --btn-hover-border-color: transparent;
  --btn-active-color: var(--link-hover-color);
  --btn-active-border-color: transparent;
  --btn-disabled-color: #52525b;
  --btn-disabled-border-color: transparent;
  --btn-box-shadow: 0 0 0 #000;
  --btn-focus-shadow-rgb: 54, 130, 206;
  text-decoration: none;
}
.btn-link:hover, .btn-link:focus-visible {
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--btn-color);
}
.btn-link:hover {
  color: var(--btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --btn-padding-y: 0.5rem;
  --btn-padding-x: 1.5rem;
  --btn-font-size: var(--text-md);
  --btn-border-radius: var(--border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --btn-padding-y: 0.25rem;
  --btn-padding-x: 0.5rem;
  --btn-font-size: var(--text-xs);
  --btn-border-radius: var(--border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --dropdown-zindex: 1000;
  --dropdown-min-width: 10rem;
  --dropdown-padding-x: 0;
  --dropdown-padding-y: 0.5rem;
  --dropdown-spacer: 0.125rem;
  --dropdown-font-size: var(--text-sm);
  --dropdown-color: var(--body-color);
  --dropdown-bg: var(--body-bg);
  --dropdown-border-color: var(--border-color-translucent);
  --dropdown-border-radius: var(--border-radius);
  --dropdown-border-width: var(--border-width);
  --dropdown-inner-border-radius: calc(var(--border-radius) - var(--border-width));
  --dropdown-divider-bg: var(--border-color-translucent);
  --dropdown-divider-margin-y: 0.5rem;
  --dropdown-box-shadow: var(--box-shadow);
  --dropdown-link-color: var(--gray-900);
  --dropdown-link-hover-color: var(--dark);
  --dropdown-link-hover-bg: var(--gray-200);
  --dropdown-link-active-color: #fff;
  --dropdown-link-active-bg: #126cc5;
  --dropdown-link-disabled-color: var(--gray-500);
  --dropdown-item-padding-x: 1rem;
  --dropdown-item-padding-y: 0.25rem;
  --dropdown-header-color: var(--gray-600);
  --dropdown-header-padding-x: 1rem;
  --dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--dropdown-zindex);
  display: none;
  min-width: var(--dropdown-min-width);
  padding: var(--dropdown-padding-y) var(--dropdown-padding-x);
  margin: 0;
  font-size: var(--dropdown-font-size);
  color: var(--dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--dropdown-bg);
  background-clip: padding-box;
  border: var(--dropdown-border-width) solid var(--dropdown-border-color);
  border-radius: var(--dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--dropdown-item-padding-y) var(--dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--dropdown-link-hover-color);
  text-decoration: none;
  background-color: var(--dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--dropdown-header-padding-y) var(--dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: var(--text-sm);
  color: var(--dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--dropdown-item-padding-y) var(--dropdown-item-padding-x);
  color: var(--dropdown-link-color);
}

.dropdown-menu-dark {
  --dropdown-color: #d4d4d8;
  --dropdown-bg: #27272a;
  --dropdown-border-color: var(--border-color-translucent);
  --dropdown-box-shadow: ;
  --dropdown-link-color: #d4d4d8;
  --dropdown-link-hover-color: #fff;
  --dropdown-divider-bg: var(--border-color-translucent);
  --dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --dropdown-link-active-color: #fff;
  --dropdown-link-active-bg: #126cc5;
  --dropdown-link-disabled-color: #71717a;
  --dropdown-header-color: #71717a;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --nav-link-padding-x: 1rem;
  --nav-link-padding-y: 0.5rem;
  --nav-link-font-weight: ;
  --nav-link-color: var(--link-color);
  --nav-link-hover-color: var(--link-hover-color);
  --nav-link-disabled-color: var(--secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--nav-link-padding-y) var(--nav-link-padding-x);
  font-size: var(--nav-link-font-size);
  font-weight: var(--nav-link-font-weight);
  color: var(--nav-link-color);
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--nav-link-hover-color);
  text-decoration: none;
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(18, 108, 197, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --nav-tabs-border-width: var(--border-width);
  --nav-tabs-border-color: var(--border-color);
  --nav-tabs-border-radius: var(--border-radius);
  --nav-tabs-link-hover-border-color: var(--secondary-bg) var(--secondary-bg) var(--border-color);
  --nav-tabs-link-active-color: var(--emphasis-color);
  --nav-tabs-link-active-bg: var(--body-bg);
  --nav-tabs-link-active-border-color: var(--border-color) var(--border-color) var(--body-bg);
  border-bottom: var(--nav-tabs-border-width) solid var(--nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--nav-tabs-border-width));
  border: var(--nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--nav-tabs-border-radius);
  border-top-right-radius: var(--nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--nav-tabs-link-active-color);
  background-color: var(--nav-tabs-link-active-bg);
  border-color: var(--nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --nav-pills-border-radius: var(--border-radius);
  --nav-pills-link-active-color: #fff;
  --nav-pills-link-active-bg: #126cc5;
}
.nav-pills .nav-link {
  border-radius: var(--nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--nav-pills-link-active-color);
  background-color: var(--nav-pills-link-active-bg);
}

.nav-underline {
  --nav-underline-gap: 1rem;
  --nav-underline-border-width: 0.125rem;
  --nav-underline-link-active-color: var(--emphasis-color);
  gap: var(--nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --navbar-padding-x: 0;
  --navbar-padding-y: 0.1875rem;
  --navbar-color: rgba(var(--emphasis-color-rgb), 0.7);
  --navbar-hover-color: var(--primary);
  --navbar-disabled-color: rgba(var(--emphasis-color-rgb), 0.3);
  --navbar-active-color: rgba(var(--emphasis-color-rgb), 1);
  --navbar-brand-padding-y: 0.4453125rem;
  --navbar-brand-margin-end: 1rem;
  --navbar-brand-font-size: 0.9375rem;
  --navbar-brand-color: rgba(var(--emphasis-color-rgb), 1);
  --navbar-brand-hover-color: rgba(var(--emphasis-color-rgb), 1);
  --navbar-nav-link-padding-x: var(--s-3);
  --navbar-toggler-padding-y: 0.25rem;
  --navbar-toggler-padding-x: 0.75rem;
  --navbar-toggler-font-size: 1.0625rem;
  --navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2824, 24, 27, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --navbar-toggler-border-color: rgba(var(--emphasis-color-rgb), 0.15);
  --navbar-toggler-border-radius: var(--border-radius);
  --navbar-toggler-focus-width: 0;
  --navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--navbar-padding-y) var(--navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--navbar-brand-padding-y);
  padding-bottom: var(--navbar-brand-padding-y);
  margin-right: var(--navbar-brand-margin-end);
  font-size: var(--navbar-brand-font-size);
  color: var(--navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--navbar-brand-hover-color);
  text-decoration: none;
}

.navbar-nav {
  --nav-link-padding-x: 0;
  --nav-link-padding-y: 0.5rem;
  --nav-link-font-weight: ;
  --nav-link-color: var(--navbar-color);
  --nav-link-hover-color: var(--navbar-hover-color);
  --nav-link-disabled-color: var(--navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--navbar-toggler-padding-y) var(--navbar-toggler-padding-x);
  font-size: var(--navbar-toggler-font-size);
  line-height: 1;
  color: var(--navbar-color);
  background-color: transparent;
  border: var(--border-width) solid var(--navbar-toggler-border-color);
  border-radius: var(--navbar-toggler-border-radius);
  transition: var(--navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--navbar-nav-link-padding-x);
    padding-left: var(--navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--navbar-nav-link-padding-x);
    padding-left: var(--navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--navbar-nav-link-padding-x);
    padding-left: var(--navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--navbar-nav-link-padding-x);
    padding-left: var(--navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--navbar-nav-link-padding-x);
    padding-left: var(--navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--navbar-nav-link-padding-x);
  padding-left: var(--navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --navbar-color: var(--white);
  --navbar-hover-color: var(--white);
  --navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --navbar-active-color: var(--white);
  --navbar-brand-color: #fff;
  --navbar-brand-hover-color: #fff;
  --navbar-toggler-border-color: transparent;
  --navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.card {
  --card-spacer-y: 1rem;
  --card-spacer-x: 1rem;
  --card-title-spacer-y: 0.5rem;
  --card-title-color: ;
  --card-subtitle-color: ;
  --card-border-width: var(--border-width);
  --card-border-color: var(--border-color-translucent);
  --card-border-radius: var(--border-radius);
  --card-box-shadow: ;
  --card-inner-border-radius: calc(var(--border-radius) - (var(--border-width)));
  --card-cap-padding-y: 0.5rem;
  --card-cap-padding-x: 1rem;
  --card-cap-bg: rgba(var(--body-color-rgb), 0.03);
  --card-cap-color: ;
  --card-height: ;
  --card-color: ;
  --card-bg: var(--body-bg);
  --card-img-overlay-padding: 1rem;
  --card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--card-height);
  color: var(--body-color);
  word-wrap: break-word;
  background-color: var(--card-bg);
  background-clip: border-box;
  border: var(--card-border-width) solid var(--card-border-color);
  border-radius: var(--card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--card-inner-border-radius);
  border-top-right-radius: var(--card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--card-inner-border-radius);
  border-bottom-left-radius: var(--card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--card-spacer-y) var(--card-spacer-x);
  color: var(--card-color);
}

.card-title {
  margin-bottom: var(--card-title-spacer-y);
  color: var(--card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--card-title-spacer-y));
  margin-bottom: 0;
  color: var(--card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: var(--card-spacer-x);
}

.card-header {
  padding: var(--card-cap-padding-y) var(--card-cap-padding-x);
  margin-bottom: 0;
  color: var(--card-cap-color);
  background-color: var(--card-cap-bg);
  border-bottom: var(--card-border-width) solid var(--card-border-color);
}
.card-header:first-child {
  border-radius: var(--card-inner-border-radius) var(--card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--card-cap-padding-y) var(--card-cap-padding-x);
  color: var(--card-cap-color);
  background-color: var(--card-cap-bg);
  border-top: var(--card-border-width) solid var(--card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--card-inner-border-radius) var(--card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--card-cap-padding-x));
  margin-bottom: calc(-1 * var(--card-cap-padding-y));
  margin-left: calc(-0.5 * var(--card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--card-bg);
  border-bottom-color: var(--card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--card-cap-padding-x));
  margin-left: calc(-0.5 * var(--card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--card-img-overlay-padding);
  border-radius: var(--card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--card-inner-border-radius);
  border-top-right-radius: var(--card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--card-inner-border-radius);
  border-bottom-left-radius: var(--card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --accordion-color: var(--body-color);
  --accordion-bg: var(--body-bg);
  --accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --accordion-border-color: var(--border-color);
  --accordion-border-width: var(--border-width);
  --accordion-border-radius: var(--border-radius);
  --accordion-inner-border-radius: calc(var(--border-radius) - (var(--border-width)));
  --accordion-btn-padding-x: 1.25rem;
  --accordion-btn-padding-y: 1rem;
  --accordion-btn-color: var(--body-color);
  --accordion-btn-bg: var(--accordion-bg);
  --accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%2318181b' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --accordion-btn-icon-width: 1.25rem;
  --accordion-btn-icon-transform: rotate(-180deg);
  --accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='var%28--primary-700%29' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(18, 108, 197, 0.25);
  --accordion-body-padding-x: 1.25rem;
  --accordion-body-padding-y: 1rem;
  --accordion-active-color: var(--primary-text-emphasis);
  --accordion-active-bg: var(--primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--accordion-btn-padding-y) var(--accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--accordion-btn-color);
  text-align: left;
  background-color: var(--accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--accordion-active-color);
  background-color: var(--accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--accordion-border-width)) 0 var(--accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--accordion-btn-active-icon);
  transform: var(--accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--accordion-btn-icon-width);
  height: var(--accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--accordion-btn-icon-width);
  transition: var(--accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--accordion-color);
  background-color: var(--accordion-bg);
  border: var(--accordion-border-width) solid var(--accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--accordion-border-radius);
  border-top-right-radius: var(--accordion-border-radius);
}
.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--accordion-inner-border-radius);
  border-top-right-radius: var(--accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--accordion-border-radius);
  border-bottom-left-radius: var(--accordion-border-radius);
}
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--accordion-inner-border-radius);
  border-bottom-left-radius: var(--accordion-inner-border-radius);
}
.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--accordion-border-radius);
  border-bottom-left-radius: var(--accordion-border-radius);
}

.accordion-body {
  padding: var(--accordion-body-padding-y) var(--accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
}
.accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--primary-400%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--primary-400%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --breadcrumb-padding-x: 0;
  --breadcrumb-padding-y: 0;
  --breadcrumb-margin-bottom: 0;
  --breadcrumb-font-size: 0.9375rem;
  --breadcrumb-bg: transparent;
  --breadcrumb-border-radius: ;
  --breadcrumb-divider-color: var(--body-color);
  --breadcrumb-item-padding-x: 0.375rem;
  --breadcrumb-item-active-color: var(--body-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--breadcrumb-padding-y) var(--breadcrumb-padding-x);
  margin-bottom: var(--breadcrumb-margin-bottom);
  font-size: var(--breadcrumb-font-size);
  list-style: none;
  background-color: var(--breadcrumb-bg);
  border-radius: var(--breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--breadcrumb-item-padding-x);
  color: var(--breadcrumb-divider-color);
  content: var(--breadcrumb-divider, "\f285") /* rtl: var(--breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--breadcrumb-item-active-color);
}

.pagination {
  --pagination-padding-x: 0.75rem;
  --pagination-padding-y: 0.375rem;
  --pagination-font-size: 1rem;
  --pagination-color: var(--link-color);
  --pagination-bg: var(--body-bg);
  --pagination-border-width: var(--border-width);
  --pagination-border-color: var(--border-color);
  --pagination-border-radius: var(--border-radius);
  --pagination-hover-color: var(--link-hover-color);
  --pagination-hover-bg: var(--tertiary-bg);
  --pagination-hover-border-color: var(--border-color);
  --pagination-focus-color: var(--link-hover-color);
  --pagination-focus-bg: var(--secondary-bg);
  --pagination-focus-box-shadow: 0 0 0 0.25rem rgba(18, 108, 197, 0.25);
  --pagination-active-color: #fff;
  --pagination-active-bg: #126cc5;
  --pagination-active-border-color: #126cc5;
  --pagination-disabled-color: var(--secondary-color);
  --pagination-disabled-bg: var(--secondary-bg);
  --pagination-disabled-border-color: var(--border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--pagination-padding-y) var(--pagination-padding-x);
  font-size: var(--pagination-font-size);
  color: var(--pagination-color);
  background-color: var(--pagination-bg);
  border: var(--pagination-border-width) solid var(--pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--pagination-hover-color);
  text-decoration: none;
  background-color: var(--pagination-hover-bg);
  border-color: var(--pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--pagination-focus-color);
  background-color: var(--pagination-focus-bg);
  outline: 0;
  box-shadow: var(--pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--pagination-active-color);
  background-color: var(--pagination-active-bg);
  border-color: var(--pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--pagination-disabled-color);
  pointer-events: none;
  background-color: var(--pagination-disabled-bg);
  border-color: var(--pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--pagination-border-radius);
  border-bottom-left-radius: var(--pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--pagination-border-radius);
  border-bottom-right-radius: var(--pagination-border-radius);
}

.pagination-lg {
  --pagination-padding-x: 1.5rem;
  --pagination-padding-y: 0.75rem;
  --pagination-font-size: 1.0625rem;
  --pagination-border-radius: var(--border-radius-lg);
}

.pagination-sm {
  --pagination-padding-x: 0.5rem;
  --pagination-padding-y: 0.25rem;
  --pagination-font-size: var(--text-sm);
  --pagination-border-radius: var(--border-radius-sm);
}

.badge {
  --badge-padding-x: 0.65em;
  --badge-padding-y: 0.45em;
  --badge-font-size: var(--text-xxs);
  --badge-font-weight: var(--font-semibold);
  --badge-color: var(--white);
  --badge-border-radius: var(--border-radius-sm);
  display: inline-block;
  padding: var(--badge-padding-y) var(--badge-padding-x);
  font-size: var(--badge-font-size);
  font-weight: var(--badge-font-weight);
  line-height: 1;
  color: var(--badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --alert-bg: transparent;
  --alert-padding-x: 1rem;
  --alert-padding-y: 1rem;
  --alert-margin-bottom: 1rem;
  --alert-color: inherit;
  --alert-border-color: transparent;
  --alert-border: var(--border-width) solid var(--alert-border-color);
  --alert-border-radius: var(--border-radius);
  --alert-link-color: inherit;
  position: relative;
  padding: var(--alert-padding-y) var(--alert-padding-x);
  margin-bottom: var(--alert-margin-bottom);
  color: var(--alert-color);
  background-color: var(--alert-bg);
  border: var(--alert-border);
  border-radius: var(--alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --alert-color: var(--primary-text-emphasis);
  --alert-bg: var(--primary-bg-subtle);
  --alert-border-color: var(--primary-border-subtle);
  --alert-link-color: var(--primary-text-emphasis);
}

.alert-secondary {
  --alert-color: var(--secondary-text-emphasis);
  --alert-bg: var(--secondary-bg-subtle);
  --alert-border-color: var(--secondary-border-subtle);
  --alert-link-color: var(--secondary-text-emphasis);
}

.alert-success {
  --alert-color: var(--success-text-emphasis);
  --alert-bg: var(--success-bg-subtle);
  --alert-border-color: var(--success-border-subtle);
  --alert-link-color: var(--success-text-emphasis);
}

.alert-info {
  --alert-color: var(--info-text-emphasis);
  --alert-bg: var(--info-bg-subtle);
  --alert-border-color: var(--info-border-subtle);
  --alert-link-color: var(--info-text-emphasis);
}

.alert-warning {
  --alert-color: var(--warning-text-emphasis);
  --alert-bg: var(--warning-bg-subtle);
  --alert-border-color: var(--warning-border-subtle);
  --alert-link-color: var(--warning-text-emphasis);
}

.alert-danger {
  --alert-color: var(--danger-text-emphasis);
  --alert-bg: var(--danger-bg-subtle);
  --alert-border-color: var(--danger-border-subtle);
  --alert-link-color: var(--danger-text-emphasis);
}

.alert-light {
  --alert-color: var(--light-text-emphasis);
  --alert-bg: var(--light-bg-subtle);
  --alert-border-color: var(--light-border-subtle);
  --alert-link-color: var(--light-text-emphasis);
}

.alert-dark {
  --alert-color: var(--dark-text-emphasis);
  --alert-bg: var(--dark-bg-subtle);
  --alert-border-color: var(--dark-border-subtle);
  --alert-link-color: var(--dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --progress-height: 1rem;
  --progress-font-size: 0.75rem;
  --progress-bg: var(--secondary-bg);
  --progress-border-radius: var(--border-radius);
  --progress-box-shadow: var(--box-shadow-inset);
  --progress-bar-color: #fff;
  --progress-bar-bg: #126cc5;
  --progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--progress-height);
  overflow: hidden;
  font-size: var(--progress-font-size);
  background-color: var(--progress-bg);
  border-radius: var(--progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--progress-bar-bg);
  transition: var(--progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--progress-height) var(--progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --list-group-color: var(--body-color);
  --list-group-bg: var(--body-bg);
  --list-group-border-color: var(--border-color);
  --list-group-border-width: var(--border-width);
  --list-group-border-radius: var(--border-radius);
  --list-group-item-padding-x: 1rem;
  --list-group-item-padding-y: 0.5rem;
  --list-group-action-color: var(--secondary-color);
  --list-group-action-hover-color: var(--emphasis-color);
  --list-group-action-hover-bg: var(--tertiary-bg);
  --list-group-action-active-color: var(--body-color);
  --list-group-action-active-bg: var(--secondary-bg);
  --list-group-disabled-color: var(--secondary-color);
  --list-group-disabled-bg: var(--body-bg);
  --list-group-active-color: #fff;
  --list-group-active-bg: #126cc5;
  --list-group-active-border-color: #126cc5;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--list-group-action-active-color);
  background-color: var(--list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--list-group-item-padding-y) var(--list-group-item-padding-x);
  color: var(--list-group-color);
  background-color: var(--list-group-bg);
  border: var(--list-group-border-width) solid var(--list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--list-group-disabled-color);
  pointer-events: none;
  background-color: var(--list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--list-group-active-color);
  background-color: var(--list-group-active-bg);
  border-color: var(--list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--list-group-border-width));
  border-top-width: var(--list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--list-group-border-width));
  border-left-width: var(--list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--list-group-border-width));
    border-left-width: var(--list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--list-group-border-width));
    border-left-width: var(--list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--list-group-border-width));
    border-left-width: var(--list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--list-group-border-width));
    border-left-width: var(--list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--list-group-border-width));
    border-left-width: var(--list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --list-group-color: var(--primary-text-emphasis);
  --list-group-bg: var(--primary-bg-subtle);
  --list-group-border-color: var(--primary-border-subtle);
  --list-group-action-hover-color: var(--emphasis-color);
  --list-group-action-hover-bg: var(--primary-border-subtle);
  --list-group-action-active-color: var(--emphasis-color);
  --list-group-action-active-bg: var(--primary-border-subtle);
  --list-group-active-color: var(--primary-bg-subtle);
  --list-group-active-bg: var(--primary-text-emphasis);
  --list-group-active-border-color: var(--primary-text-emphasis);
}

.list-group-item-secondary {
  --list-group-color: var(--secondary-text-emphasis);
  --list-group-bg: var(--secondary-bg-subtle);
  --list-group-border-color: var(--secondary-border-subtle);
  --list-group-action-hover-color: var(--emphasis-color);
  --list-group-action-hover-bg: var(--secondary-border-subtle);
  --list-group-action-active-color: var(--emphasis-color);
  --list-group-action-active-bg: var(--secondary-border-subtle);
  --list-group-active-color: var(--secondary-bg-subtle);
  --list-group-active-bg: var(--secondary-text-emphasis);
  --list-group-active-border-color: var(--secondary-text-emphasis);
}

.list-group-item-success {
  --list-group-color: var(--success-text-emphasis);
  --list-group-bg: var(--success-bg-subtle);
  --list-group-border-color: var(--success-border-subtle);
  --list-group-action-hover-color: var(--emphasis-color);
  --list-group-action-hover-bg: var(--success-border-subtle);
  --list-group-action-active-color: var(--emphasis-color);
  --list-group-action-active-bg: var(--success-border-subtle);
  --list-group-active-color: var(--success-bg-subtle);
  --list-group-active-bg: var(--success-text-emphasis);
  --list-group-active-border-color: var(--success-text-emphasis);
}

.list-group-item-info {
  --list-group-color: var(--info-text-emphasis);
  --list-group-bg: var(--info-bg-subtle);
  --list-group-border-color: var(--info-border-subtle);
  --list-group-action-hover-color: var(--emphasis-color);
  --list-group-action-hover-bg: var(--info-border-subtle);
  --list-group-action-active-color: var(--emphasis-color);
  --list-group-action-active-bg: var(--info-border-subtle);
  --list-group-active-color: var(--info-bg-subtle);
  --list-group-active-bg: var(--info-text-emphasis);
  --list-group-active-border-color: var(--info-text-emphasis);
}

.list-group-item-warning {
  --list-group-color: var(--warning-text-emphasis);
  --list-group-bg: var(--warning-bg-subtle);
  --list-group-border-color: var(--warning-border-subtle);
  --list-group-action-hover-color: var(--emphasis-color);
  --list-group-action-hover-bg: var(--warning-border-subtle);
  --list-group-action-active-color: var(--emphasis-color);
  --list-group-action-active-bg: var(--warning-border-subtle);
  --list-group-active-color: var(--warning-bg-subtle);
  --list-group-active-bg: var(--warning-text-emphasis);
  --list-group-active-border-color: var(--warning-text-emphasis);
}

.list-group-item-danger {
  --list-group-color: var(--danger-text-emphasis);
  --list-group-bg: var(--danger-bg-subtle);
  --list-group-border-color: var(--danger-border-subtle);
  --list-group-action-hover-color: var(--emphasis-color);
  --list-group-action-hover-bg: var(--danger-border-subtle);
  --list-group-action-active-color: var(--emphasis-color);
  --list-group-action-active-bg: var(--danger-border-subtle);
  --list-group-active-color: var(--danger-bg-subtle);
  --list-group-active-bg: var(--danger-text-emphasis);
  --list-group-active-border-color: var(--danger-text-emphasis);
}

.list-group-item-light {
  --list-group-color: var(--light-text-emphasis);
  --list-group-bg: var(--light-bg-subtle);
  --list-group-border-color: var(--light-border-subtle);
  --list-group-action-hover-color: var(--emphasis-color);
  --list-group-action-hover-bg: var(--light-border-subtle);
  --list-group-action-active-color: var(--emphasis-color);
  --list-group-action-active-bg: var(--light-border-subtle);
  --list-group-active-color: var(--light-bg-subtle);
  --list-group-active-bg: var(--light-text-emphasis);
  --list-group-active-border-color: var(--light-text-emphasis);
}

.list-group-item-dark {
  --list-group-color: var(--dark-text-emphasis);
  --list-group-bg: var(--dark-bg-subtle);
  --list-group-border-color: var(--dark-border-subtle);
  --list-group-action-hover-color: var(--emphasis-color);
  --list-group-action-hover-bg: var(--dark-border-subtle);
  --list-group-action-active-color: var(--emphasis-color);
  --list-group-action-active-bg: var(--dark-border-subtle);
  --list-group-active-color: var(--dark-bg-subtle);
  --list-group-active-bg: var(--dark-text-emphasis);
  --list-group-active-border-color: var(--dark-text-emphasis);
}

.btn-close {
  --btn-close-color: #000;
  --btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --btn-close-opacity: 0.5;
  --btn-close-hover-opacity: 0.75;
  --btn-close-focus-shadow: 0 0 0 0.25rem rgba(18, 108, 197, 0.25);
  --btn-close-focus-opacity: 1;
  --btn-close-disabled-opacity: 0.25;
  --btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--btn-close-color);
  background: transparent var(--btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--btn-close-opacity);
}
.btn-close:hover {
  color: var(--btn-close-color);
  text-decoration: none;
  opacity: var(--btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--btn-close-focus-shadow);
  opacity: var(--btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--btn-close-white-filter);
}

.modal {
  --modal-zindex: 1055;
  --modal-width: 500px;
  --modal-padding: 1rem;
  --modal-margin: 0.5rem;
  --modal-color: ;
  --modal-bg: var(--body-bg);
  --modal-border-color: var(--border-color-translucent);
  --modal-border-width: var(--border-width);
  --modal-border-radius: var(--border-radius-lg);
  --modal-box-shadow: var(--box-shadow-sm);
  --modal-inner-border-radius: calc(var(--border-radius-lg) - (var(--border-width)));
  --modal-header-padding-x: 1rem;
  --modal-header-padding-y: 1rem;
  --modal-header-padding: 1rem 1rem;
  --modal-header-border-color: var(--border-color);
  --modal-header-border-width: var(--border-width);
  --modal-title-line-height: 1.75;
  --modal-footer-gap: 0.5rem;
  --modal-footer-bg: ;
  --modal-footer-border-color: var(--border-color);
  --modal-footer-border-width: var(--border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--modal-color);
  pointer-events: auto;
  background-color: var(--modal-bg);
  background-clip: padding-box;
  border: var(--modal-border-width) solid var(--modal-border-color);
  border-radius: var(--modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --backdrop-zindex: 1050;
  --backdrop-bg: #000;
  --backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--modal-header-padding);
  border-bottom: var(--modal-header-border-width) solid var(--modal-header-border-color);
  border-top-left-radius: var(--modal-inner-border-radius);
  border-top-right-radius: var(--modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--modal-header-padding-y) * 0.5) calc(var(--modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--modal-header-padding-y)) calc(-0.5 * var(--modal-header-padding-x)) calc(-0.5 * var(--modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--modal-padding) - var(--modal-footer-gap) * 0.5);
  background-color: var(--modal-footer-bg);
  border-top: var(--modal-footer-border-width) solid var(--modal-footer-border-color);
  border-bottom-right-radius: var(--modal-inner-border-radius);
  border-bottom-left-radius: var(--modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --modal-margin: 1.75rem;
    --modal-box-shadow: var(--box-shadow);
  }
  .modal-dialog {
    max-width: var(--modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --tooltip-zindex: 1080;
  --tooltip-max-width: 200px;
  --tooltip-padding-x: 0.5rem;
  --tooltip-padding-y: 0.25rem;
  --tooltip-margin: ;
  --tooltip-font-size: var(--text-sm);
  --tooltip-color: var(--body-bg);
  --tooltip-bg: var(--emphasis-color);
  --tooltip-border-radius: var(--border-radius);
  --tooltip-opacity: 0.9;
  --tooltip-arrow-width: 0.8rem;
  --tooltip-arrow-height: 0.4rem;
  z-index: var(--tooltip-zindex);
  display: block;
  margin: var(--tooltip-margin);
  font-family: var(--font-sans);
  font-style: normal;
  font-weight: 400;
  line-height: 1.75;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--tooltip-arrow-width);
  height: var(--tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--tooltip-arrow-height) calc(var(--tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--tooltip-arrow-height));
  width: var(--tooltip-arrow-height);
  height: var(--tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--tooltip-arrow-width) * 0.5) var(--tooltip-arrow-height) calc(var(--tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--tooltip-arrow-width) * 0.5) var(--tooltip-arrow-height);
  border-bottom-color: var(--tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--tooltip-arrow-height));
  width: var(--tooltip-arrow-height);
  height: var(--tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--tooltip-arrow-width) * 0.5) 0 calc(var(--tooltip-arrow-width) * 0.5) var(--tooltip-arrow-height);
  border-left-color: var(--tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--tooltip-max-width);
  padding: var(--tooltip-padding-y) var(--tooltip-padding-x);
  color: var(--tooltip-color);
  text-align: center;
  background-color: var(--tooltip-bg);
  border-radius: var(--tooltip-border-radius);
}

.popover {
  --popover-zindex: 1070;
  --popover-max-width: 276px;
  --popover-font-size: var(--text-sm);
  --popover-bg: var(--body-bg);
  --popover-border-width: var(--border-width);
  --popover-border-color: var(--border-color-translucent);
  --popover-border-radius: var(--border-radius-lg);
  --popover-inner-border-radius: calc(var(--border-radius-lg) - var(--border-width));
  --popover-box-shadow: var(--box-shadow);
  --popover-header-padding-x: 1rem;
  --popover-header-padding-y: 0.5rem;
  --popover-header-font-size: 1rem;
  --popover-header-color: var(--gray-800);
  --popover-header-bg: var(--secondary-bg);
  --popover-body-padding-x: 1rem;
  --popover-body-padding-y: 1rem;
  --popover-body-color: var(--body-color);
  --popover-arrow-width: 1rem;
  --popover-arrow-height: 0.5rem;
  --popover-arrow-border: var(--popover-border-color);
  z-index: var(--popover-zindex);
  display: block;
  max-width: var(--popover-max-width);
  font-family: var(--font-sans);
  font-style: normal;
  font-weight: 400;
  line-height: 1.75;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--popover-font-size);
  word-wrap: break-word;
  background-color: var(--popover-bg);
  background-clip: padding-box;
  border: var(--popover-border-width) solid var(--popover-border-color);
  border-radius: var(--popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--popover-arrow-width);
  height: var(--popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--popover-arrow-height)) - var(--popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--popover-arrow-height) calc(var(--popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--popover-border-width);
  border-top-color: var(--popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--popover-arrow-height)) - var(--popover-border-width));
  width: var(--popover-arrow-height);
  height: var(--popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--popover-arrow-width) * 0.5) var(--popover-arrow-height) calc(var(--popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--popover-border-width);
  border-right-color: var(--popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--popover-arrow-height)) - var(--popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--popover-arrow-width) * 0.5) var(--popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--popover-border-width);
  border-bottom-color: var(--popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--popover-arrow-width);
  margin-left: calc(-0.5 * var(--popover-arrow-width));
  content: "";
  border-bottom: var(--popover-border-width) solid var(--popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--popover-arrow-height)) - var(--popover-border-width));
  width: var(--popover-arrow-height);
  height: var(--popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--popover-arrow-width) * 0.5) 0 calc(var(--popover-arrow-width) * 0.5) var(--popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--popover-border-width);
  border-left-color: var(--popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--popover-header-padding-y) var(--popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--popover-header-font-size);
  color: var(--popover-header-color);
  background-color: var(--popover-header-bg);
  border-bottom: var(--popover-border-width) solid var(--popover-border-color);
  border-top-left-radius: var(--popover-inner-border-radius);
  border-top-right-radius: var(--popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--popover-body-padding-y) var(--popover-body-padding-x);
  color: var(--popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")*/;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")*/;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--spinner-width);
  height: var(--spinner-height);
  vertical-align: var(--spinner-vertical-align);
  border-radius: 50%;
  animation: var(--spinner-animation-speed) linear infinite var(--spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --spinner-width: 2rem;
  --spinner-height: 2rem;
  --spinner-vertical-align: -0.125em;
  --spinner-border-width: 0.25em;
  --spinner-animation-speed: 0.75s;
  --spinner-animation-name: spinner-border;
  border: var(--spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --spinner-width: 1rem;
  --spinner-height: 1rem;
  --spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --spinner-width: 2rem;
  --spinner-height: 2rem;
  --spinner-vertical-align: -0.125em;
  --spinner-animation-speed: 0.75s;
  --spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --spinner-width: 1rem;
  --spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --offcanvas-zindex: 1045;
  --offcanvas-width: 400px;
  --offcanvas-height: 30vh;
  --offcanvas-padding-x: 1rem;
  --offcanvas-padding-y: 1rem;
  --offcanvas-color: var(--body-color);
  --offcanvas-bg: var(--body-bg);
  --offcanvas-border-width: var(--border-width);
  --offcanvas-border-color: var(--border-color-translucent);
  --offcanvas-box-shadow: var(--box-shadow-sm);
  --offcanvas-transition: transform 0.3s ease-in-out;
  --offcanvas-title-line-height: 1.75;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--offcanvas-color);
    visibility: hidden;
    background-color: var(--offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--offcanvas-width);
    border-right: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--offcanvas-width);
    border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--offcanvas-height);
    max-height: 100%;
    border-bottom: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--offcanvas-height);
    max-height: 100%;
    border-top: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --offcanvas-height: auto;
    --offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--offcanvas-color);
    visibility: hidden;
    background-color: var(--offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--offcanvas-width);
    border-right: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--offcanvas-width);
    border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--offcanvas-height);
    max-height: 100%;
    border-bottom: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--offcanvas-height);
    max-height: 100%;
    border-top: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --offcanvas-height: auto;
    --offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--offcanvas-color);
    visibility: hidden;
    background-color: var(--offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--offcanvas-width);
    border-right: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--offcanvas-width);
    border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--offcanvas-height);
    max-height: 100%;
    border-bottom: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--offcanvas-height);
    max-height: 100%;
    border-top: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --offcanvas-height: auto;
    --offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--offcanvas-color);
    visibility: hidden;
    background-color: var(--offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--offcanvas-width);
    border-right: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--offcanvas-width);
    border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--offcanvas-height);
    max-height: 100%;
    border-bottom: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--offcanvas-height);
    max-height: 100%;
    border-top: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --offcanvas-height: auto;
    --offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--offcanvas-color);
    visibility: hidden;
    background-color: var(--offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--offcanvas-width);
    border-right: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--offcanvas-width);
    border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--offcanvas-height);
    max-height: 100%;
    border-bottom: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--offcanvas-height);
    max-height: 100%;
    border-top: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --offcanvas-height: auto;
    --offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--offcanvas-color);
  visibility: hidden;
  background-color: var(--offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--offcanvas-width);
  border-right: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--offcanvas-width);
  border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--offcanvas-height);
  max-height: 100%;
  border-bottom: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--offcanvas-height);
  max-height: 100%;
  border-top: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--offcanvas-padding-y) var(--offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--offcanvas-padding-y) * 0.5) calc(var(--offcanvas-padding-x) * 0.5);
  margin: calc(-0.5 * var(--offcanvas-padding-y)) calc(-0.5 * var(--offcanvas-padding-x)) calc(-0.5 * var(--offcanvas-padding-y)) auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--offcanvas-padding-y) var(--offcanvas-padding-x);
  overflow-y: auto;
}

.alert {
  line-height: var(--line-height-display);
}
.alert > :last-child {
  margin-bottom: 0;
}

.alert-lg .alert-icon {
  --alert-icon-size: 1.75rem;
}

.alert-icon {
  --alert-icon-size: 1.25rem;
  padding-left: calc(var(--alert-padding-x) * 2 + var(--alert-icon-size));
}
.alert-icon::before {
  position: absolute;
  top: calc(var(--alert-padding-y) - var(--alert-icon-size) / 4);
  left: var(--alert-padding-x);
  color: var(--alert-color);
  font-family: bootstrap-icons !important;
  font-size: var(--alert-icon-size);
}

.alert-info.alert-icon::before {
  content: "\f431";
}

.alert-success.alert-icon::before {
  content: "\f26b";
}

.alert-warning.alert-icon::before {
  content: "\f33b";
}

.alert-danger.alert-icon::before {
  content: "\f333";
}

.breadcrumbs-container {
  display: none;
  padding: var(--s-3) 0 var(--s-2);
}

.breadcrumb-item::before {
  font-family: bootstrap-icons;
}

@media (min-width: 992px) {
  .breadcrumbs-container {
    display: block;
  }
}
.btn-icon {
  border: none;
  font-size: var(--text-lg);
  padding: 0.375rem 0.625rem;
}

.btn-icon.btn-lg, .btn-group-lg > .btn-icon.btn {
  font-size: var(--text-xl);
  padding: 0.5rem 0.75rem;
}

.card-footer {
  background-color: transparent;
  border-top: none;
}

.card-btn-bottom {
  height: 2.5rem;
}
.card-btn-bottom .btn {
  position: absolute;
  bottom: var(--s-3);
}

html,
body {
  font-kerning: auto;
  font-variant-ligatures: common-ligatures;
  text-rendering: optimizeLegibility;
  letter-spacing: var(--letter-spacing-text);
}

#content {
  min-height: 50vh;
}

.lead + h2, .lead + .h2 {
  margin-top: var(--s-3);
}

.lead {
  font-family: var(--font-sans);
  font-size: var(--text-xl);
  font-weight: var(--font-normal);
}

blockquote {
  padding-left: 0.75rem;
  margin-left: 0;
  font-style: italic;
  border-left: 0.25rem solid var(--border-color);
}

@media print {
  #content {
    min-height: 0;
  }
}
@media (max-width: 767.98px) {
  .nav-item .dropdown-item {
    padding: 0.35rem 0.75rem;
    white-space: normal;
  }
}
figure,
figcaption,
.figure-caption {
  font-size: var(--text-sm);
}

figcaption {
  padding: 0.125rem;
}

label {
  font-family: var(--font-sans);
  letter-spacing: var(--letter-spacing-display);
}

.form-floating > label {
  text-align: initial;
}

.form-section {
  border-radius: var(--border-radius-lg);
  margin-bottom: var(--s-4);
  padding: var(--s-3);
  border: var(--border-width) var(--border-style) var(--border-color);
}

.form-input-section {
  margin-bottom: var(--s-4);
}

.form-input-section-legend {
  font-size: inherit;
}

.form-header-indicator {
  border-radius: 50%;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
  aspect-ratio: 1;
  height: 3rem;
  margin-right: var(--s-3);
  padding: var(--s-2);
  width: 3.25rem;
}

.form-primary .form-label,
.form-primary legend {
  color: var(--primary);
  font-size: 1.0625rem;
}

.form-primary h1, .form-primary .h1,
.form-primary h2,
.form-primary .h2,
.form-primary h3,
.form-primary .h3,
.form-primary h4,
.form-primary .h4,
.form-primary h5,
.form-primary .h5,
.form-primary h6,
.form-primary .h6,
.form-primary .form-label,
.form-primary legend {
  color: var(--primary);
}

.nav-tabs > .btn-link {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.navbar-brand > abbr {
  cursor: pointer;
}

.offcanvas.show .dropdown-menu {
  border: none;
  padding: 0;
}

.highlight {
  --highlight-bg: #f6f6f6;
  --highlight-padding-x: 6px;
  --highlight-padding-y: 2px;
  --highlight-color: inherit;
  --highlight-border-color: #e4e4e4;
  --highlight-border: 1px solid var(--highlight-border-color);
  --highlight-border-radius: 0.375rem;
  position: relative;
  padding: var(--highlight-padding-y) var(--highlight-padding-x);
  color: var(--highlight-color);
  background-color: var(--highlight-bg);
  border: var(--highlight-border);
  border-radius: var(--highlight-border-radius);
}

.btn-primary .highlight,
.btn-secondary .highlight,
.btn-success .highlight,
.btn-info .highlight,
.btn-warning .highlight,
.btn-danger .highlight,
.btn-dark .highlight,
.bg-primary .highlight,
.bg-secondary .highlight,
.bg-success .highlight,
.bg-info .highlight,
.bg-warning .highlight,
.bg-danger .highlight,
.bg-dark .highlight {
  color: #fff;
  border-color: #fff;
  background: transparent;
}

.gallery {
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  display: flex;
}

.gallery img {
  scroll-snap-align: center;
}

span-phoneme,
span-grapheme,
span-letter,
span-sound,
span-word,
span-phrase,
span-sentence,
span-digit,
span-numeral {
  display: inline;
  font-family: var(--font-sans);
}

span-phoneme,
span-sound {
  color: rgb(160, 50, 50);
}

span-grapheme,
span-letter {
  color: rgb(56, 28, 118);
}

span-word {
  color: rgb(35, 104, 151);
}

span-phrase,
span-sentence {
  color: rgb(21, 116, 103);
}

span-digit,
span-numeral {
  color: rgb(45, 120, 170);
}

span-phoneme::before {
  content: "/";
}

span-phoneme::after {
  content: "/";
}

span-grapheme::before {
  content: "⟨";
}

span-grapheme::after {
  content: "⟩";
}

span-word::before {
  content: "⟨";
}

span-word::after {
  content: "⟩";
}

span-sentence::before,
span-phrase::before {
  content: "{";
}

span-sentence::after,
span-phrase::after {
  content: "}";
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--primary-rgb), var(--bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--secondary-rgb), var(--bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--success-rgb), var(--bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(var(--info-rgb), var(--bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--warning-rgb), var(--bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--danger-rgb), var(--bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--light-rgb), var(--bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--dark-rgb), var(--bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--primary-rgb), var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--primary-rgb), var(--link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(14, 86, 158, var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(14, 86, 158, var(--link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--secondary-rgb), var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--secondary-rgb), var(--link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(90, 90, 98, var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(90, 90, 98, var(--link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--success-rgb), var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--success-rgb), var(--link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(0, 102, 51, var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 102, 51, var(--link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--info-rgb), var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--info-rgb), var(--link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(63, 56, 183, var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(63, 56, 183, var(--link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--warning-rgb), var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--warning-rgb), var(--link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(250, 143, 69, var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(250, 143, 69, var(--link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--danger-rgb), var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--danger-rgb), var(--link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 30, 30, var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 30, 30, var(--link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--light-rgb), var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--light-rgb), var(--link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(246, 246, 247, var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(246, 246, 247, var(--link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--dark-rgb), var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--dark-rgb), var(--link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(31, 31, 34, var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(31, 31, 34, var(--link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--emphasis-color-rgb), var(--link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--emphasis-color-rgb), var(--link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--emphasis-color-rgb), var(--link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--emphasis-color-rgb), var(--link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--focus-ring-x, 0) var(--focus-ring-y, 0) var(--focus-ring-blur, 0) var(--focus-ring-width) var(--focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--link-color-rgb), var(--link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --aspect-ratio: 100%;
}

.ratio-4x3 {
  --aspect-ratio: 75%;
}

.ratio-16x9 {
  --aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.clear-both {
  clear: both;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.font-family-sans {
  font-family: Noto Sans, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.font-family-serif {
  font-family: Noto Serif, Georgia, ui-serif, serif;
}

.font-family-monospace {
  font-family: Noto Sans Mono, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.font-family-sans-semi-condensed {
  font-family: Noto Sans SemiCondensed, serif;
}

.font-family-monospace-semi-condensed {
  font-family: Noto Sans Mono SemiCondensed, serif;
}

.font-family-system {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

.font-family-system-sans {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

.font-family-system-serif {
  font-family: -apple-system-ui-serif, Georgia, Times New Roman, serif, Apple Color Emoji, Segoe UI Emoji;
}

.small-caps {
  font-variant: small-caps;
}

.all-small-caps {
  font-variant: all-small-caps;
}

.numeric-slashed-zero {
  font-variant-numeric: slashed-zero;
}

.numeric-ordinal {
  font-variant-numeric: ordinal;
}

.bi-bold::before {
  font-weight: bolder !important;
}

.list-items-mb-0 > li,
.list-items-mb-0 > ol > li,
.list-items-mb-0 > ul > li {
  margin-bottom: var(--s-0);
}

.list-items-mb-1 > li,
.list-items-mb-1 > ol > li,
.list-items-mb-1 > ul > li {
  margin-bottom: var(--s-1);
}

.list-items-mb-2 > li,
.list-items-mb-2 > ol > li,
.list-items-mb-2 > ul > li {
  margin-bottom: var(--s-2);
}

.list-items-mb-3 > li,
.list-items-mb-3 > ol > li,
.list-items-mb-3 > ul > li {
  margin-bottom: var(--s-3);
}

.list-items-mb-4 > li,
.list-items-mb-4 > ol > li,
.list-items-mb-4 > ul > li {
  margin-bottom: var(--s-4);
}

.list-items-mb-5 > li,
.list-items-mb-5 > ol > li,
.list-items-mb-5 > ul > li {
  margin-bottom: var(--s-5);
}

.list-items-mb-6 > li,
.list-items-mb-6 > ol > li,
.list-items-mb-6 > ul > li {
  margin-bottom: var(--s-6);
}

.list-items-mb-hero > li,
.list-items-mb-hero > ol > li,
.list-items-mb-hero > ul > li {
  margin-bottom: var(--s-hero);
}

.object-cover {
  object-fit: cover;
}

.object-contain {
  object-fit: contain;
}

.w-300px {
  width: 300px;
}

.w-400px {
  width: 300px;
}

.w-500px {
  width: 300px;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --focus-ring-color: rgba(var(--primary-rgb), var(--focus-ring-opacity));
}

.focus-ring-secondary {
  --focus-ring-color: rgba(var(--secondary-rgb), var(--focus-ring-opacity));
}

.focus-ring-success {
  --focus-ring-color: rgba(var(--success-rgb), var(--focus-ring-opacity));
}

.focus-ring-info {
  --focus-ring-color: rgba(var(--info-rgb), var(--focus-ring-opacity));
}

.focus-ring-warning {
  --focus-ring-color: rgba(var(--warning-rgb), var(--focus-ring-opacity));
}

.focus-ring-danger {
  --focus-ring-color: rgba(var(--danger-rgb), var(--focus-ring-opacity));
}

.focus-ring-light {
  --focus-ring-color: rgba(var(--light-rgb), var(--focus-ring-opacity));
}

.focus-ring-dark {
  --focus-ring-color: rgba(var(--dark-rgb), var(--focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--border-width) var(--border-style) var(--border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--border-width) var(--border-style) var(--border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--border-width) var(--border-style) var(--border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--border-width) var(--border-style) var(--border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--border-width) var(--border-style) var(--border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --border-opacity: 1;
  border-color: rgba(var(--primary-rgb), var(--border-opacity)) !important;
}

.border-secondary {
  --border-opacity: 1;
  border-color: rgba(var(--secondary-rgb), var(--border-opacity)) !important;
}

.border-success {
  --border-opacity: 1;
  border-color: rgba(var(--success-rgb), var(--border-opacity)) !important;
}

.border-info {
  --border-opacity: 1;
  border-color: rgba(var(--info-rgb), var(--border-opacity)) !important;
}

.border-warning {
  --border-opacity: 1;
  border-color: rgba(var(--warning-rgb), var(--border-opacity)) !important;
}

.border-danger {
  --border-opacity: 1;
  border-color: rgba(var(--danger-rgb), var(--border-opacity)) !important;
}

.border-light {
  --border-opacity: 1;
  border-color: rgba(var(--light-rgb), var(--border-opacity)) !important;
}

.border-dark {
  --border-opacity: 1;
  border-color: rgba(var(--dark-rgb), var(--border-opacity)) !important;
}

.border-black {
  --border-opacity: 1;
  border-color: rgba(var(--black-rgb), var(--border-opacity)) !important;
}

.border-white {
  --border-opacity: 1;
  border-color: rgba(var(--white-rgb), var(--border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --border-opacity: 0.1;
}

.border-opacity-25 {
  --border-opacity: 0.25;
}

.border-opacity-50 {
  --border-opacity: 0.5;
}

.border-opacity-75 {
  --border-opacity: 0.75;
}

.border-opacity-100 {
  --border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-10rem {
  width: 10rem !important;
}

.w-12rem {
  width: 12rem !important;
}

.w-15rem {
  width: 15rem !important;
}

.w-18rem {
  width: 18rem !important;
}

.w-20rem {
  width: 20rem !important;
}

.w-22rem {
  width: 22rem !important;
}

.w-25rem {
  width: 25rem !important;
}

.w-28rem {
  width: 28rem !important;
}

.w-30rem {
  width: 30rem !important;
}

.w-32rem {
  width: 32rem !important;
}

.w-35rem {
  width: 35rem !important;
}

.w-40rem {
  width: 40rem !important;
}

.w-45rem {
  width: 45rem !important;
}

.w-50rem {
  width: 50rem !important;
}

.w-55rem {
  width: 55rem !important;
}

.w-60rem {
  width: 60rem !important;
}

.w-65rem {
  width: 65rem !important;
}

.w-70rem {
  width: 70rem !important;
}

.w-75rem {
  width: 75rem !important;
}

.w-80rem {
  width: 80rem !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mw-prose {
  max-width: var(--mw-prose) !important;
}

.mw-10 {
  max-width: 10% !important;
}

.mw-15 {
  max-width: 15% !important;
}

.mw-20 {
  max-width: 20% !important;
}

.mw-25 {
  max-width: 25% !important;
}

.mw-30 {
  max-width: 30% !important;
}

.mw-35 {
  max-width: 35% !important;
}

.mw-40 {
  max-width: 40% !important;
}

.mw-45 {
  max-width: 45% !important;
}

.mw-50 {
  max-width: 50% !important;
}

.mw-55 {
  max-width: 55% !important;
}

.mw-60 {
  max-width: 60% !important;
}

.mw-65 {
  max-width: 65% !important;
}

.mw-75 {
  max-width: 75% !important;
}

.mw-80 {
  max-width: 80% !important;
}

.mw-85 {
  max-width: 85% !important;
}

.mw-90 {
  max-width: 90% !important;
}

.mw-10rem {
  max-width: 10rem !important;
}

.mw-12rem {
  max-width: 12rem !important;
}

.mw-15rem {
  max-width: 15rem !important;
}

.mw-18rem {
  max-width: 18rem !important;
}

.mw-20rem {
  max-width: 20rem !important;
}

.mw-22rem {
  max-width: 22rem !important;
}

.mw-25rem {
  max-width: 25rem !important;
}

.mw-28rem {
  max-width: 28rem !important;
}

.mw-30rem {
  max-width: 30rem !important;
}

.mw-32rem {
  max-width: 32rem !important;
}

.mw-35rem {
  max-width: 35rem !important;
}

.mw-40rem {
  max-width: 40rem !important;
}

.mw-45rem {
  max-width: 45rem !important;
}

.mw-50rem {
  max-width: 50rem !important;
}

.mw-55rem {
  max-width: 55rem !important;
}

.mw-60rem {
  max-width: 60rem !important;
}

.mw-65rem {
  max-width: 65rem !important;
}

.mw-70rem {
  max-width: 70rem !important;
}

.mw-75rem {
  max-width: 75rem !important;
}

.mw-80rem {
  max-width: 80rem !important;
}

.mw-100px {
  max-width: 100px !important;
}

.mw-200px {
  max-width: 200px !important;
}

.mw-300px {
  max-width: 300px !important;
}

.mw-400px {
  max-width: 400px !important;
}

.mw-500px {
  max-width: 500px !important;
}

.mw-600px {
  max-width: 600px !important;
}

.mw-700px {
  max-width: 700px !important;
}

.mw-800px {
  max-width: 800px !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: var(--s-1) !important;
}

.m-2 {
  margin: var(--s-2) !important;
}

.m-3 {
  margin: var(--s-3) !important;
}

.m-4 {
  margin: var(--s-4) !important;
}

.m-5 {
  margin: var(--s-5) !important;
}

.m-6 {
  margin: var(--s-6) !important;
}

.m-hero {
  margin: var(--s-hero) !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: var(--s-1) !important;
  margin-left: var(--s-1) !important;
}

.mx-2 {
  margin-right: var(--s-2) !important;
  margin-left: var(--s-2) !important;
}

.mx-3 {
  margin-right: var(--s-3) !important;
  margin-left: var(--s-3) !important;
}

.mx-4 {
  margin-right: var(--s-4) !important;
  margin-left: var(--s-4) !important;
}

.mx-5 {
  margin-right: var(--s-5) !important;
  margin-left: var(--s-5) !important;
}

.mx-6 {
  margin-right: var(--s-6) !important;
  margin-left: var(--s-6) !important;
}

.mx-hero {
  margin-right: var(--s-hero) !important;
  margin-left: var(--s-hero) !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: var(--s-1) !important;
  margin-bottom: var(--s-1) !important;
}

.my-2 {
  margin-top: var(--s-2) !important;
  margin-bottom: var(--s-2) !important;
}

.my-3 {
  margin-top: var(--s-3) !important;
  margin-bottom: var(--s-3) !important;
}

.my-4 {
  margin-top: var(--s-4) !important;
  margin-bottom: var(--s-4) !important;
}

.my-5 {
  margin-top: var(--s-5) !important;
  margin-bottom: var(--s-5) !important;
}

.my-6 {
  margin-top: var(--s-6) !important;
  margin-bottom: var(--s-6) !important;
}

.my-hero {
  margin-top: var(--s-hero) !important;
  margin-bottom: var(--s-hero) !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: var(--s-1) !important;
}

.mt-2 {
  margin-top: var(--s-2) !important;
}

.mt-3 {
  margin-top: var(--s-3) !important;
}

.mt-4 {
  margin-top: var(--s-4) !important;
}

.mt-5 {
  margin-top: var(--s-5) !important;
}

.mt-6 {
  margin-top: var(--s-6) !important;
}

.mt-hero {
  margin-top: var(--s-hero) !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: var(--s-1) !important;
}

.me-2 {
  margin-right: var(--s-2) !important;
}

.me-3 {
  margin-right: var(--s-3) !important;
}

.me-4 {
  margin-right: var(--s-4) !important;
}

.me-5 {
  margin-right: var(--s-5) !important;
}

.me-6 {
  margin-right: var(--s-6) !important;
}

.me-hero {
  margin-right: var(--s-hero) !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: var(--s-1) !important;
}

.mb-2 {
  margin-bottom: var(--s-2) !important;
}

.mb-3 {
  margin-bottom: var(--s-3) !important;
}

.mb-4 {
  margin-bottom: var(--s-4) !important;
}

.mb-5 {
  margin-bottom: var(--s-5) !important;
}

.mb-6 {
  margin-bottom: var(--s-6) !important;
}

.mb-hero {
  margin-bottom: var(--s-hero) !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: var(--s-1) !important;
}

.ms-2 {
  margin-left: var(--s-2) !important;
}

.ms-3 {
  margin-left: var(--s-3) !important;
}

.ms-4 {
  margin-left: var(--s-4) !important;
}

.ms-5 {
  margin-left: var(--s-5) !important;
}

.ms-6 {
  margin-left: var(--s-6) !important;
}

.ms-hero {
  margin-left: var(--s-hero) !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n0 {
  margin: 0 !important;
}

.m-n1 {
  margin: -var(--s-1) !important;
}

.m-n2 {
  margin: -var(--s-2) !important;
}

.m-n3 {
  margin: -var(--s-3) !important;
}

.m-n4 {
  margin: -var(--s-4) !important;
}

.m-n5 {
  margin: -var(--s-5) !important;
}

.m-n6 {
  margin: -var(--s-6) !important;
}

.m-nhero {
  margin: -var(--s-hero) !important;
}

.mx-n0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-n1 {
  margin-right: -var(--s-1) !important;
  margin-left: -var(--s-1) !important;
}

.mx-n2 {
  margin-right: -var(--s-2) !important;
  margin-left: -var(--s-2) !important;
}

.mx-n3 {
  margin-right: -var(--s-3) !important;
  margin-left: -var(--s-3) !important;
}

.mx-n4 {
  margin-right: -var(--s-4) !important;
  margin-left: -var(--s-4) !important;
}

.mx-n5 {
  margin-right: -var(--s-5) !important;
  margin-left: -var(--s-5) !important;
}

.mx-n6 {
  margin-right: -var(--s-6) !important;
  margin-left: -var(--s-6) !important;
}

.mx-nhero {
  margin-right: -var(--s-hero) !important;
  margin-left: -var(--s-hero) !important;
}

.my-n0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-n1 {
  margin-top: -var(--s-1) !important;
  margin-bottom: -var(--s-1) !important;
}

.my-n2 {
  margin-top: -var(--s-2) !important;
  margin-bottom: -var(--s-2) !important;
}

.my-n3 {
  margin-top: -var(--s-3) !important;
  margin-bottom: -var(--s-3) !important;
}

.my-n4 {
  margin-top: -var(--s-4) !important;
  margin-bottom: -var(--s-4) !important;
}

.my-n5 {
  margin-top: -var(--s-5) !important;
  margin-bottom: -var(--s-5) !important;
}

.my-n6 {
  margin-top: -var(--s-6) !important;
  margin-bottom: -var(--s-6) !important;
}

.my-nhero {
  margin-top: -var(--s-hero) !important;
  margin-bottom: -var(--s-hero) !important;
}

.mt-n0 {
  margin-top: 0 !important;
}

.mt-n1 {
  margin-top: -var(--s-1) !important;
}

.mt-n2 {
  margin-top: -var(--s-2) !important;
}

.mt-n3 {
  margin-top: -var(--s-3) !important;
}

.mt-n4 {
  margin-top: -var(--s-4) !important;
}

.mt-n5 {
  margin-top: -var(--s-5) !important;
}

.mt-n6 {
  margin-top: -var(--s-6) !important;
}

.mt-nhero {
  margin-top: -var(--s-hero) !important;
}

.me-n0 {
  margin-right: 0 !important;
}

.me-n1 {
  margin-right: -var(--s-1) !important;
}

.me-n2 {
  margin-right: -var(--s-2) !important;
}

.me-n3 {
  margin-right: -var(--s-3) !important;
}

.me-n4 {
  margin-right: -var(--s-4) !important;
}

.me-n5 {
  margin-right: -var(--s-5) !important;
}

.me-n6 {
  margin-right: -var(--s-6) !important;
}

.me-nhero {
  margin-right: -var(--s-hero) !important;
}

.mb-n0 {
  margin-bottom: 0 !important;
}

.mb-n1 {
  margin-bottom: -var(--s-1) !important;
}

.mb-n2 {
  margin-bottom: -var(--s-2) !important;
}

.mb-n3 {
  margin-bottom: -var(--s-3) !important;
}

.mb-n4 {
  margin-bottom: -var(--s-4) !important;
}

.mb-n5 {
  margin-bottom: -var(--s-5) !important;
}

.mb-n6 {
  margin-bottom: -var(--s-6) !important;
}

.mb-nhero {
  margin-bottom: -var(--s-hero) !important;
}

.ms-n0 {
  margin-left: 0 !important;
}

.ms-n1 {
  margin-left: -var(--s-1) !important;
}

.ms-n2 {
  margin-left: -var(--s-2) !important;
}

.ms-n3 {
  margin-left: -var(--s-3) !important;
}

.ms-n4 {
  margin-left: -var(--s-4) !important;
}

.ms-n5 {
  margin-left: -var(--s-5) !important;
}

.ms-n6 {
  margin-left: -var(--s-6) !important;
}

.ms-nhero {
  margin-left: -var(--s-hero) !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: var(--s-1) !important;
}

.p-2 {
  padding: var(--s-2) !important;
}

.p-3 {
  padding: var(--s-3) !important;
}

.p-4 {
  padding: var(--s-4) !important;
}

.p-5 {
  padding: var(--s-5) !important;
}

.p-6 {
  padding: var(--s-6) !important;
}

.p-hero {
  padding: var(--s-hero) !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: var(--s-1) !important;
  padding-left: var(--s-1) !important;
}

.px-2 {
  padding-right: var(--s-2) !important;
  padding-left: var(--s-2) !important;
}

.px-3 {
  padding-right: var(--s-3) !important;
  padding-left: var(--s-3) !important;
}

.px-4 {
  padding-right: var(--s-4) !important;
  padding-left: var(--s-4) !important;
}

.px-5 {
  padding-right: var(--s-5) !important;
  padding-left: var(--s-5) !important;
}

.px-6 {
  padding-right: var(--s-6) !important;
  padding-left: var(--s-6) !important;
}

.px-hero {
  padding-right: var(--s-hero) !important;
  padding-left: var(--s-hero) !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: var(--s-1) !important;
  padding-bottom: var(--s-1) !important;
}

.py-2 {
  padding-top: var(--s-2) !important;
  padding-bottom: var(--s-2) !important;
}

.py-3 {
  padding-top: var(--s-3) !important;
  padding-bottom: var(--s-3) !important;
}

.py-4 {
  padding-top: var(--s-4) !important;
  padding-bottom: var(--s-4) !important;
}

.py-5 {
  padding-top: var(--s-5) !important;
  padding-bottom: var(--s-5) !important;
}

.py-6 {
  padding-top: var(--s-6) !important;
  padding-bottom: var(--s-6) !important;
}

.py-hero {
  padding-top: var(--s-hero) !important;
  padding-bottom: var(--s-hero) !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: var(--s-1) !important;
}

.pt-2 {
  padding-top: var(--s-2) !important;
}

.pt-3 {
  padding-top: var(--s-3) !important;
}

.pt-4 {
  padding-top: var(--s-4) !important;
}

.pt-5 {
  padding-top: var(--s-5) !important;
}

.pt-6 {
  padding-top: var(--s-6) !important;
}

.pt-hero {
  padding-top: var(--s-hero) !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: var(--s-1) !important;
}

.pe-2 {
  padding-right: var(--s-2) !important;
}

.pe-3 {
  padding-right: var(--s-3) !important;
}

.pe-4 {
  padding-right: var(--s-4) !important;
}

.pe-5 {
  padding-right: var(--s-5) !important;
}

.pe-6 {
  padding-right: var(--s-6) !important;
}

.pe-hero {
  padding-right: var(--s-hero) !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: var(--s-1) !important;
}

.pb-2 {
  padding-bottom: var(--s-2) !important;
}

.pb-3 {
  padding-bottom: var(--s-3) !important;
}

.pb-4 {
  padding-bottom: var(--s-4) !important;
}

.pb-5 {
  padding-bottom: var(--s-5) !important;
}

.pb-6 {
  padding-bottom: var(--s-6) !important;
}

.pb-hero {
  padding-bottom: var(--s-hero) !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: var(--s-1) !important;
}

.ps-2 {
  padding-left: var(--s-2) !important;
}

.ps-3 {
  padding-left: var(--s-3) !important;
}

.ps-4 {
  padding-left: var(--s-4) !important;
}

.ps-5 {
  padding-left: var(--s-5) !important;
}

.ps-6 {
  padding-left: var(--s-6) !important;
}

.ps-hero {
  padding-left: var(--s-hero) !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: var(--s-1) !important;
}

.gap-2 {
  gap: var(--s-2) !important;
}

.gap-3 {
  gap: var(--s-3) !important;
}

.gap-4 {
  gap: var(--s-4) !important;
}

.gap-5 {
  gap: var(--s-5) !important;
}

.gap-6 {
  gap: var(--s-6) !important;
}

.gap-hero {
  gap: var(--s-hero) !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: var(--s-1) !important;
}

.row-gap-2 {
  row-gap: var(--s-2) !important;
}

.row-gap-3 {
  row-gap: var(--s-3) !important;
}

.row-gap-4 {
  row-gap: var(--s-4) !important;
}

.row-gap-5 {
  row-gap: var(--s-5) !important;
}

.row-gap-6 {
  row-gap: var(--s-6) !important;
}

.row-gap-hero {
  row-gap: var(--s-hero) !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: var(--s-1) !important;
}

.column-gap-2 {
  column-gap: var(--s-2) !important;
}

.column-gap-3 {
  column-gap: var(--s-3) !important;
}

.column-gap-4 {
  column-gap: var(--s-4) !important;
}

.column-gap-5 {
  column-gap: var(--s-5) !important;
}

.column-gap-6 {
  column-gap: var(--s-6) !important;
}

.column-gap-hero {
  column-gap: var(--s-hero) !important;
}

.font-monospace {
  font-family: var(--font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fs-xxxs {
  font-size: var(--text-xxxs) !important;
}

.fs-xxs {
  font-size: var(--text-xxs) !important;
}

.fs-xs {
  font-size: var(--text-xs) !important;
}

.fs-sm {
  font-size: var(--text-sm) !important;
}

.fs-md {
  font-size: var(--text-md) !important;
}

.fs-lg {
  font-size: var(--text-lg) !important;
}

.fs-xl {
  font-size: var(--text-xl) !important;
}

.fs-xxl {
  font-size: var(--text-xxl) !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.75 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --text-opacity: 1;
  color: rgba(var(--primary-rgb), var(--text-opacity)) !important;
}

.text-secondary {
  --text-opacity: 1;
  color: rgba(var(--secondary-rgb), var(--text-opacity)) !important;
}

.text-success {
  --text-opacity: 1;
  color: rgba(var(--success-rgb), var(--text-opacity)) !important;
}

.text-info {
  --text-opacity: 1;
  color: rgba(var(--info-rgb), var(--text-opacity)) !important;
}

.text-warning {
  --text-opacity: 1;
  color: rgba(var(--warning-rgb), var(--text-opacity)) !important;
}

.text-danger {
  --text-opacity: 1;
  color: rgba(var(--danger-rgb), var(--text-opacity)) !important;
}

.text-light {
  --text-opacity: 1;
  color: rgba(var(--light-rgb), var(--text-opacity)) !important;
}

.text-dark {
  --text-opacity: 1;
  color: rgba(var(--dark-rgb), var(--text-opacity)) !important;
}

.text-black {
  --text-opacity: 1;
  color: rgba(var(--black-rgb), var(--text-opacity)) !important;
}

.text-white {
  --text-opacity: 1;
  color: rgba(var(--white-rgb), var(--text-opacity)) !important;
}

.text-body {
  --text-opacity: 1;
  color: rgba(var(--body-color-rgb), var(--text-opacity)) !important;
}

.text-muted {
  --text-opacity: 1;
  color: var(--secondary-color) !important;
}

.text-black-50 {
  --text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --text-opacity: 1;
  color: var(--secondary-color) !important;
}

.text-body-tertiary {
  --text-opacity: 1;
  color: var(--tertiary-color) !important;
}

.text-body-emphasis {
  --text-opacity: 1;
  color: var(--emphasis-color) !important;
}

.text-reset {
  --text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --text-opacity: 0.25;
}

.text-opacity-50 {
  --text-opacity: 0.5;
}

.text-opacity-75 {
  --text-opacity: 0.75;
}

.text-opacity-100 {
  --text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--dark-text-emphasis) !important;
}

.text-gray-50 {
  color: var(--gray-50) !important;
}

.text-gray-100 {
  color: var(--gray-100) !important;
}

.text-gray-200 {
  color: var(--gray-200) !important;
}

.text-gray-300 {
  color: var(--gray-300) !important;
}

.text-gray-400 {
  color: var(--gray-400) !important;
}

.text-gray-500 {
  color: var(--gray-500) !important;
}

.text-gray-600 {
  color: var(--gray-600) !important;
}

.text-gray-700 {
  color: var(--gray-700) !important;
}

.text-gray-800 {
  color: var(--gray-800) !important;
}

.text-gray-900 {
  color: var(--gray-900) !important;
}

.text-gray-950 {
  color: var(--gray-950) !important;
}

.text-red-50 {
  color: var(--red-50) !important;
}

.text-red-100 {
  color: var(--red-100) !important;
}

.text-red-200 {
  color: var(--red-200) !important;
}

.text-red-300 {
  color: var(--red-300) !important;
}

.text-red-400 {
  color: var(--red-400) !important;
}

.text-red-500 {
  color: var(--red-500) !important;
}

.text-red-600 {
  color: var(--red-600) !important;
}

.text-red-700 {
  color: var(--red-700) !important;
}

.text-red-800 {
  color: var(--red-800) !important;
}

.text-red-900 {
  color: var(--red-900) !important;
}

.text-red-950 {
  color: var(--red-950) !important;
}

.text-orange-50 {
  color: var(--orange-50) !important;
}

.text-orange-100 {
  color: var(--orange-100) !important;
}

.text-orange-200 {
  color: var(--orange-200) !important;
}

.text-orange-300 {
  color: var(--orange-300) !important;
}

.text-orange-400 {
  color: var(--orange-400) !important;
}

.text-orange-500 {
  color: var(--orange-500) !important;
}

.text-orange-600 {
  color: var(--orange-600) !important;
}

.text-orange-700 {
  color: var(--orange-700) !important;
}

.text-orange-800 {
  color: var(--orange-800) !important;
}

.text-orange-900 {
  color: var(--orange-900) !important;
}

.text-orange-950 {
  color: var(--orange-950) !important;
}

.text-yellow-50 {
  color: var(--yellow-50) !important;
}

.text-yellow-100 {
  color: var(--yellow-100) !important;
}

.text-yellow-200 {
  color: var(--yellow-200) !important;
}

.text-yellow-300 {
  color: var(--yellow-300) !important;
}

.text-yellow-400 {
  color: var(--yellow-400) !important;
}

.text-yellow-500 {
  color: var(--yellow-500) !important;
}

.text-yellow-600 {
  color: var(--yellow-600) !important;
}

.text-yellow-700 {
  color: var(--yellow-700) !important;
}

.text-yellow-800 {
  color: var(--yellow-800) !important;
}

.text-yellow-900 {
  color: var(--yellow-900) !important;
}

.text-yellow-950 {
  color: var(--yellow-950) !important;
}

.text-green-50 {
  color: var(--green-50) !important;
}

.text-green-100 {
  color: var(--green-100) !important;
}

.text-green-200 {
  color: var(--green-200) !important;
}

.text-green-300 {
  color: var(--green-300) !important;
}

.text-green-400 {
  color: var(--green-400) !important;
}

.text-green-500 {
  color: var(--green-500) !important;
}

.text-green-600 {
  color: var(--green-600) !important;
}

.text-green-700 {
  color: var(--green-700) !important;
}

.text-green-800 {
  color: var(--green-800) !important;
}

.text-green-900 {
  color: var(--green-900) !important;
}

.text-green-950 {
  color: var(--green-950) !important;
}

.text-teal-50 {
  color: var(--teal-50) !important;
}

.text-teal-100 {
  color: var(--teal-100) !important;
}

.text-teal-200 {
  color: var(--teal-200) !important;
}

.text-teal-300 {
  color: var(--teal-300) !important;
}

.text-teal-400 {
  color: var(--teal-400) !important;
}

.text-teal-500 {
  color: var(--teal-500) !important;
}

.text-teal-600 {
  color: var(--teal-600) !important;
}

.text-teal-700 {
  color: var(--teal-700) !important;
}

.text-teal-800 {
  color: var(--teal-800) !important;
}

.text-teal-900 {
  color: var(--teal-900) !important;
}

.text-teal-950 {
  color: var(--teal-950) !important;
}

.text-cyan-50 {
  color: var(--cyan-50) !important;
}

.text-cyan-100 {
  color: var(--cyan-100) !important;
}

.text-cyan-200 {
  color: var(--cyan-200) !important;
}

.text-cyan-300 {
  color: var(--cyan-300) !important;
}

.text-cyan-400 {
  color: var(--cyan-400) !important;
}

.text-cyan-500 {
  color: var(--cyan-500) !important;
}

.text-cyan-600 {
  color: var(--cyan-600) !important;
}

.text-cyan-700 {
  color: var(--cyan-700) !important;
}

.text-cyan-800 {
  color: var(--cyan-800) !important;
}

.text-cyan-900 {
  color: var(--cyan-900) !important;
}

.text-cyan-950 {
  color: var(--cyan-950) !important;
}

.text-blue-50 {
  color: var(--blue-50) !important;
}

.text-blue-100 {
  color: var(--blue-100) !important;
}

.text-blue-200 {
  color: var(--blue-200) !important;
}

.text-blue-300 {
  color: var(--blue-300) !important;
}

.text-blue-400 {
  color: var(--blue-400) !important;
}

.text-blue-500 {
  color: var(--blue-500) !important;
}

.text-blue-600 {
  color: var(--blue-600) !important;
}

.text-blue-700 {
  color: var(--blue-700) !important;
}

.text-blue-800 {
  color: var(--blue-800) !important;
}

.text-blue-900 {
  color: var(--blue-900) !important;
}

.text-blue-950 {
  color: var(--blue-950) !important;
}

.text-indigo-50 {
  color: var(--indigo-50) !important;
}

.text-indigo-100 {
  color: var(--indigo-100) !important;
}

.text-indigo-200 {
  color: var(--indigo-200) !important;
}

.text-indigo-300 {
  color: var(--indigo-300) !important;
}

.text-indigo-400 {
  color: var(--indigo-400) !important;
}

.text-indigo-500 {
  color: var(--indigo-500) !important;
}

.text-indigo-600 {
  color: var(--indigo-600) !important;
}

.text-indigo-700 {
  color: var(--indigo-700) !important;
}

.text-indigo-800 {
  color: var(--indigo-800) !important;
}

.text-indigo-900 {
  color: var(--indigo-900) !important;
}

.text-indigo-950 {
  color: var(--indigo-950) !important;
}

.text-purple-50 {
  color: var(--purple-50) !important;
}

.text-purple-100 {
  color: var(--purple-100) !important;
}

.text-purple-200 {
  color: var(--purple-200) !important;
}

.text-purple-300 {
  color: var(--purple-300) !important;
}

.text-purple-400 {
  color: var(--purple-400) !important;
}

.text-purple-500 {
  color: var(--purple-500) !important;
}

.text-purple-600 {
  color: var(--purple-600) !important;
}

.text-purple-700 {
  color: var(--purple-700) !important;
}

.text-purple-800 {
  color: var(--purple-800) !important;
}

.text-purple-900 {
  color: var(--purple-900) !important;
}

.text-purple-950 {
  color: var(--purple-950) !important;
}

.text-pink-50 {
  color: var(--pink-50) !important;
}

.text-pink-100 {
  color: var(--pink-100) !important;
}

.text-pink-200 {
  color: var(--pink-200) !important;
}

.text-pink-300 {
  color: var(--pink-300) !important;
}

.text-pink-400 {
  color: var(--pink-400) !important;
}

.text-pink-500 {
  color: var(--pink-500) !important;
}

.text-pink-600 {
  color: var(--pink-600) !important;
}

.text-pink-700 {
  color: var(--pink-700) !important;
}

.text-pink-800 {
  color: var(--pink-800) !important;
}

.text-pink-900 {
  color: var(--pink-900) !important;
}

.text-pink-950 {
  color: var(--pink-950) !important;
}

.text-primary-50 {
  color: var(--primary-50) !important;
}

.text-primary-100 {
  color: var(--primary-100) !important;
}

.text-primary-200 {
  color: var(--primary-200) !important;
}

.text-primary-300 {
  color: var(--primary-300) !important;
}

.text-primary-400 {
  color: var(--primary-400) !important;
}

.text-primary-500 {
  color: var(--primary-500) !important;
}

.text-primary-600 {
  color: var(--primary-600) !important;
}

.text-primary-700 {
  color: var(--primary-700) !important;
}

.text-primary-800 {
  color: var(--primary-800) !important;
}

.text-primary-900 {
  color: var(--primary-900) !important;
}

.text-primary-950 {
  color: var(--primary-950) !important;
}

.text-success-50 {
  color: var(--success-50) !important;
}

.text-success-100 {
  color: var(--success-100) !important;
}

.text-success-200 {
  color: var(--success-200) !important;
}

.text-success-300 {
  color: var(--success-300) !important;
}

.text-success-400 {
  color: var(--success-400) !important;
}

.text-success-500 {
  color: var(--success-500) !important;
}

.text-success-600 {
  color: var(--success-600) !important;
}

.text-success-700 {
  color: var(--success-700) !important;
}

.text-success-800 {
  color: var(--success-800) !important;
}

.text-success-900 {
  color: var(--success-900) !important;
}

.text-success-950 {
  color: var(--success-950) !important;
}

.text-warning-50 {
  color: var(--warning-50) !important;
}

.text-warning-100 {
  color: var(--warning-100) !important;
}

.text-warning-200 {
  color: var(--warning-200) !important;
}

.text-warning-300 {
  color: var(--warning-300) !important;
}

.text-warning-400 {
  color: var(--warning-400) !important;
}

.text-warning-500 {
  color: var(--warning-500) !important;
}

.text-warning-600 {
  color: var(--warning-600) !important;
}

.text-warning-700 {
  color: var(--warning-700) !important;
}

.text-warning-800 {
  color: var(--warning-800) !important;
}

.text-warning-900 {
  color: var(--warning-900) !important;
}

.text-warning-950 {
  color: var(--warning-950) !important;
}

.text-danger-50 {
  color: var(--danger-50) !important;
}

.text-danger-100 {
  color: var(--danger-100) !important;
}

.text-danger-200 {
  color: var(--danger-200) !important;
}

.text-danger-300 {
  color: var(--danger-300) !important;
}

.text-danger-400 {
  color: var(--danger-400) !important;
}

.text-danger-500 {
  color: var(--danger-500) !important;
}

.text-danger-600 {
  color: var(--danger-600) !important;
}

.text-danger-700 {
  color: var(--danger-700) !important;
}

.text-danger-800 {
  color: var(--danger-800) !important;
}

.text-danger-900 {
  color: var(--danger-900) !important;
}

.text-danger-950 {
  color: var(--danger-950) !important;
}

.text-info-50 {
  color: var(--info-50) !important;
}

.text-info-100 {
  color: var(--info-100) !important;
}

.text-info-200 {
  color: var(--info-200) !important;
}

.text-info-300 {
  color: var(--info-300) !important;
}

.text-info-400 {
  color: var(--info-400) !important;
}

.text-info-500 {
  color: var(--info-500) !important;
}

.text-info-600 {
  color: var(--info-600) !important;
}

.text-info-700 {
  color: var(--info-700) !important;
}

.text-info-800 {
  color: var(--info-800) !important;
}

.text-info-900 {
  color: var(--info-900) !important;
}

.text-info-950 {
  color: var(--info-950) !important;
}

.link-opacity-10 {
  --link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --link-opacity: 0.1;
}

.link-opacity-25 {
  --link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --link-opacity: 0.25;
}

.link-opacity-50 {
  --link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --link-opacity: 0.5;
}

.link-opacity-75 {
  --link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --link-opacity: 0.75;
}

.link-opacity-100 {
  --link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --link-underline-opacity: 1;
  text-decoration-color: rgba(var(--primary-rgb), var(--link-underline-opacity)) !important;
}

.link-underline-secondary {
  --link-underline-opacity: 1;
  text-decoration-color: rgba(var(--secondary-rgb), var(--link-underline-opacity)) !important;
}

.link-underline-success {
  --link-underline-opacity: 1;
  text-decoration-color: rgba(var(--success-rgb), var(--link-underline-opacity)) !important;
}

.link-underline-info {
  --link-underline-opacity: 1;
  text-decoration-color: rgba(var(--info-rgb), var(--link-underline-opacity)) !important;
}

.link-underline-warning {
  --link-underline-opacity: 1;
  text-decoration-color: rgba(var(--warning-rgb), var(--link-underline-opacity)) !important;
}

.link-underline-danger {
  --link-underline-opacity: 1;
  text-decoration-color: rgba(var(--danger-rgb), var(--link-underline-opacity)) !important;
}

.link-underline-light {
  --link-underline-opacity: 1;
  text-decoration-color: rgba(var(--light-rgb), var(--link-underline-opacity)) !important;
}

.link-underline-dark {
  --link-underline-opacity: 1;
  text-decoration-color: rgba(var(--dark-rgb), var(--link-underline-opacity)) !important;
}

.link-underline {
  --link-underline-opacity: 1;
  text-decoration-color: rgba(var(--link-color-rgb), var(--link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --link-underline-opacity: 1;
}

.bg-primary {
  --bg-opacity: 1;
  background-color: rgba(var(--primary-rgb), var(--bg-opacity)) !important;
}

.bg-secondary {
  --bg-opacity: 1;
  background-color: rgba(var(--secondary-rgb), var(--bg-opacity)) !important;
}

.bg-success {
  --bg-opacity: 1;
  background-color: rgba(var(--success-rgb), var(--bg-opacity)) !important;
}

.bg-info {
  --bg-opacity: 1;
  background-color: rgba(var(--info-rgb), var(--bg-opacity)) !important;
}

.bg-warning {
  --bg-opacity: 1;
  background-color: rgba(var(--warning-rgb), var(--bg-opacity)) !important;
}

.bg-danger {
  --bg-opacity: 1;
  background-color: rgba(var(--danger-rgb), var(--bg-opacity)) !important;
}

.bg-light {
  --bg-opacity: 1;
  background-color: rgba(var(--light-rgb), var(--bg-opacity)) !important;
}

.bg-dark {
  --bg-opacity: 1;
  background-color: rgba(var(--dark-rgb), var(--bg-opacity)) !important;
}

.bg-black {
  --bg-opacity: 1;
  background-color: rgba(var(--black-rgb), var(--bg-opacity)) !important;
}

.bg-white {
  --bg-opacity: 1;
  background-color: rgba(var(--white-rgb), var(--bg-opacity)) !important;
}

.bg-body {
  --bg-opacity: 1;
  background-color: rgba(var(--body-bg-rgb), var(--bg-opacity)) !important;
}

.bg-transparent {
  --bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bg-opacity: 1;
  background-color: rgba(var(--secondary-bg-rgb), var(--bg-opacity)) !important;
}

.bg-body-tertiary {
  --bg-opacity: 1;
  background-color: rgba(var(--tertiary-bg-rgb), var(--bg-opacity)) !important;
}

.bg-gray-50 {
  --bg-opacity: 1;
  background-color: var(--gray-50) !important;
}

.bg-gray-100 {
  --bg-opacity: 1;
  background-color: var(--gray-100) !important;
}

.bg-gray-200 {
  --bg-opacity: 1;
  background-color: var(--gray-200) !important;
}

.bg-gray-300 {
  --bg-opacity: 1;
  background-color: var(--gray-300) !important;
}

.bg-gray-400 {
  --bg-opacity: 1;
  background-color: var(--gray-400) !important;
}

.bg-gray-500 {
  --bg-opacity: 1;
  background-color: var(--gray-500) !important;
}

.bg-gray-600 {
  --bg-opacity: 1;
  background-color: var(--gray-600) !important;
}

.bg-gray-700 {
  --bg-opacity: 1;
  background-color: var(--gray-700) !important;
}

.bg-gray-800 {
  --bg-opacity: 1;
  background-color: var(--gray-800) !important;
}

.bg-gray-900 {
  --bg-opacity: 1;
  background-color: var(--gray-900) !important;
}

.bg-gray-950 {
  --bg-opacity: 1;
  background-color: var(--gray-950) !important;
}

.bg-red-50 {
  --bg-opacity: 1;
  background-color: var(--red-50) !important;
}

.bg-red-100 {
  --bg-opacity: 1;
  background-color: var(--red-100) !important;
}

.bg-red-200 {
  --bg-opacity: 1;
  background-color: var(--red-200) !important;
}

.bg-red-300 {
  --bg-opacity: 1;
  background-color: var(--red-300) !important;
}

.bg-red-400 {
  --bg-opacity: 1;
  background-color: var(--red-400) !important;
}

.bg-red-500 {
  --bg-opacity: 1;
  background-color: var(--red-500) !important;
}

.bg-red-600 {
  --bg-opacity: 1;
  background-color: var(--red-600) !important;
}

.bg-red-700 {
  --bg-opacity: 1;
  background-color: var(--red-700) !important;
}

.bg-red-800 {
  --bg-opacity: 1;
  background-color: var(--red-800) !important;
}

.bg-red-900 {
  --bg-opacity: 1;
  background-color: var(--red-900) !important;
}

.bg-red-950 {
  --bg-opacity: 1;
  background-color: var(--red-950) !important;
}

.bg-orange-50 {
  --bg-opacity: 1;
  background-color: var(--orange-50) !important;
}

.bg-orange-100 {
  --bg-opacity: 1;
  background-color: var(--orange-100) !important;
}

.bg-orange-200 {
  --bg-opacity: 1;
  background-color: var(--orange-200) !important;
}

.bg-orange-300 {
  --bg-opacity: 1;
  background-color: var(--orange-300) !important;
}

.bg-orange-400 {
  --bg-opacity: 1;
  background-color: var(--orange-400) !important;
}

.bg-orange-500 {
  --bg-opacity: 1;
  background-color: var(--orange-500) !important;
}

.bg-orange-600 {
  --bg-opacity: 1;
  background-color: var(--orange-600) !important;
}

.bg-orange-700 {
  --bg-opacity: 1;
  background-color: var(--orange-700) !important;
}

.bg-orange-800 {
  --bg-opacity: 1;
  background-color: var(--orange-800) !important;
}

.bg-orange-900 {
  --bg-opacity: 1;
  background-color: var(--orange-900) !important;
}

.bg-orange-950 {
  --bg-opacity: 1;
  background-color: var(--orange-950) !important;
}

.bg-yellow-50 {
  --bg-opacity: 1;
  background-color: var(--yellow-50) !important;
}

.bg-yellow-100 {
  --bg-opacity: 1;
  background-color: var(--yellow-100) !important;
}

.bg-yellow-200 {
  --bg-opacity: 1;
  background-color: var(--yellow-200) !important;
}

.bg-yellow-300 {
  --bg-opacity: 1;
  background-color: var(--yellow-300) !important;
}

.bg-yellow-400 {
  --bg-opacity: 1;
  background-color: var(--yellow-400) !important;
}

.bg-yellow-500 {
  --bg-opacity: 1;
  background-color: var(--yellow-500) !important;
}

.bg-yellow-600 {
  --bg-opacity: 1;
  background-color: var(--yellow-600) !important;
}

.bg-yellow-700 {
  --bg-opacity: 1;
  background-color: var(--yellow-700) !important;
}

.bg-yellow-800 {
  --bg-opacity: 1;
  background-color: var(--yellow-800) !important;
}

.bg-yellow-900 {
  --bg-opacity: 1;
  background-color: var(--yellow-900) !important;
}

.bg-yellow-950 {
  --bg-opacity: 1;
  background-color: var(--yellow-950) !important;
}

.bg-green-50 {
  --bg-opacity: 1;
  background-color: var(--green-50) !important;
}

.bg-green-100 {
  --bg-opacity: 1;
  background-color: var(--green-100) !important;
}

.bg-green-200 {
  --bg-opacity: 1;
  background-color: var(--green-200) !important;
}

.bg-green-300 {
  --bg-opacity: 1;
  background-color: var(--green-300) !important;
}

.bg-green-400 {
  --bg-opacity: 1;
  background-color: var(--green-400) !important;
}

.bg-green-500 {
  --bg-opacity: 1;
  background-color: var(--green-500) !important;
}

.bg-green-600 {
  --bg-opacity: 1;
  background-color: var(--green-600) !important;
}

.bg-green-700 {
  --bg-opacity: 1;
  background-color: var(--green-700) !important;
}

.bg-green-800 {
  --bg-opacity: 1;
  background-color: var(--green-800) !important;
}

.bg-green-900 {
  --bg-opacity: 1;
  background-color: var(--green-900) !important;
}

.bg-green-950 {
  --bg-opacity: 1;
  background-color: var(--green-950) !important;
}

.bg-teal-50 {
  --bg-opacity: 1;
  background-color: var(--teal-50) !important;
}

.bg-teal-100 {
  --bg-opacity: 1;
  background-color: var(--teal-100) !important;
}

.bg-teal-200 {
  --bg-opacity: 1;
  background-color: var(--teal-200) !important;
}

.bg-teal-300 {
  --bg-opacity: 1;
  background-color: var(--teal-300) !important;
}

.bg-teal-400 {
  --bg-opacity: 1;
  background-color: var(--teal-400) !important;
}

.bg-teal-500 {
  --bg-opacity: 1;
  background-color: var(--teal-500) !important;
}

.bg-teal-600 {
  --bg-opacity: 1;
  background-color: var(--teal-600) !important;
}

.bg-teal-700 {
  --bg-opacity: 1;
  background-color: var(--teal-700) !important;
}

.bg-teal-800 {
  --bg-opacity: 1;
  background-color: var(--teal-800) !important;
}

.bg-teal-900 {
  --bg-opacity: 1;
  background-color: var(--teal-900) !important;
}

.bg-teal-950 {
  --bg-opacity: 1;
  background-color: var(--teal-950) !important;
}

.bg-cyan-50 {
  --bg-opacity: 1;
  background-color: var(--cyan-50) !important;
}

.bg-cyan-100 {
  --bg-opacity: 1;
  background-color: var(--cyan-100) !important;
}

.bg-cyan-200 {
  --bg-opacity: 1;
  background-color: var(--cyan-200) !important;
}

.bg-cyan-300 {
  --bg-opacity: 1;
  background-color: var(--cyan-300) !important;
}

.bg-cyan-400 {
  --bg-opacity: 1;
  background-color: var(--cyan-400) !important;
}

.bg-cyan-500 {
  --bg-opacity: 1;
  background-color: var(--cyan-500) !important;
}

.bg-cyan-600 {
  --bg-opacity: 1;
  background-color: var(--cyan-600) !important;
}

.bg-cyan-700 {
  --bg-opacity: 1;
  background-color: var(--cyan-700) !important;
}

.bg-cyan-800 {
  --bg-opacity: 1;
  background-color: var(--cyan-800) !important;
}

.bg-cyan-900 {
  --bg-opacity: 1;
  background-color: var(--cyan-900) !important;
}

.bg-cyan-950 {
  --bg-opacity: 1;
  background-color: var(--cyan-950) !important;
}

.bg-blue-50 {
  --bg-opacity: 1;
  background-color: var(--blue-50) !important;
}

.bg-blue-100 {
  --bg-opacity: 1;
  background-color: var(--blue-100) !important;
}

.bg-blue-200 {
  --bg-opacity: 1;
  background-color: var(--blue-200) !important;
}

.bg-blue-300 {
  --bg-opacity: 1;
  background-color: var(--blue-300) !important;
}

.bg-blue-400 {
  --bg-opacity: 1;
  background-color: var(--blue-400) !important;
}

.bg-blue-500 {
  --bg-opacity: 1;
  background-color: var(--blue-500) !important;
}

.bg-blue-600 {
  --bg-opacity: 1;
  background-color: var(--blue-600) !important;
}

.bg-blue-700 {
  --bg-opacity: 1;
  background-color: var(--blue-700) !important;
}

.bg-blue-800 {
  --bg-opacity: 1;
  background-color: var(--blue-800) !important;
}

.bg-blue-900 {
  --bg-opacity: 1;
  background-color: var(--blue-900) !important;
}

.bg-blue-950 {
  --bg-opacity: 1;
  background-color: var(--blue-950) !important;
}

.bg-indigo-50 {
  --bg-opacity: 1;
  background-color: var(--indigo-50) !important;
}

.bg-indigo-100 {
  --bg-opacity: 1;
  background-color: var(--indigo-100) !important;
}

.bg-indigo-200 {
  --bg-opacity: 1;
  background-color: var(--indigo-200) !important;
}

.bg-indigo-300 {
  --bg-opacity: 1;
  background-color: var(--indigo-300) !important;
}

.bg-indigo-400 {
  --bg-opacity: 1;
  background-color: var(--indigo-400) !important;
}

.bg-indigo-500 {
  --bg-opacity: 1;
  background-color: var(--indigo-500) !important;
}

.bg-indigo-600 {
  --bg-opacity: 1;
  background-color: var(--indigo-600) !important;
}

.bg-indigo-700 {
  --bg-opacity: 1;
  background-color: var(--indigo-700) !important;
}

.bg-indigo-800 {
  --bg-opacity: 1;
  background-color: var(--indigo-800) !important;
}

.bg-indigo-900 {
  --bg-opacity: 1;
  background-color: var(--indigo-900) !important;
}

.bg-indigo-950 {
  --bg-opacity: 1;
  background-color: var(--indigo-950) !important;
}

.bg-purple-50 {
  --bg-opacity: 1;
  background-color: var(--purple-50) !important;
}

.bg-purple-100 {
  --bg-opacity: 1;
  background-color: var(--purple-100) !important;
}

.bg-purple-200 {
  --bg-opacity: 1;
  background-color: var(--purple-200) !important;
}

.bg-purple-300 {
  --bg-opacity: 1;
  background-color: var(--purple-300) !important;
}

.bg-purple-400 {
  --bg-opacity: 1;
  background-color: var(--purple-400) !important;
}

.bg-purple-500 {
  --bg-opacity: 1;
  background-color: var(--purple-500) !important;
}

.bg-purple-600 {
  --bg-opacity: 1;
  background-color: var(--purple-600) !important;
}

.bg-purple-700 {
  --bg-opacity: 1;
  background-color: var(--purple-700) !important;
}

.bg-purple-800 {
  --bg-opacity: 1;
  background-color: var(--purple-800) !important;
}

.bg-purple-900 {
  --bg-opacity: 1;
  background-color: var(--purple-900) !important;
}

.bg-purple-950 {
  --bg-opacity: 1;
  background-color: var(--purple-950) !important;
}

.bg-pink-50 {
  --bg-opacity: 1;
  background-color: var(--pink-50) !important;
}

.bg-pink-100 {
  --bg-opacity: 1;
  background-color: var(--pink-100) !important;
}

.bg-pink-200 {
  --bg-opacity: 1;
  background-color: var(--pink-200) !important;
}

.bg-pink-300 {
  --bg-opacity: 1;
  background-color: var(--pink-300) !important;
}

.bg-pink-400 {
  --bg-opacity: 1;
  background-color: var(--pink-400) !important;
}

.bg-pink-500 {
  --bg-opacity: 1;
  background-color: var(--pink-500) !important;
}

.bg-pink-600 {
  --bg-opacity: 1;
  background-color: var(--pink-600) !important;
}

.bg-pink-700 {
  --bg-opacity: 1;
  background-color: var(--pink-700) !important;
}

.bg-pink-800 {
  --bg-opacity: 1;
  background-color: var(--pink-800) !important;
}

.bg-pink-900 {
  --bg-opacity: 1;
  background-color: var(--pink-900) !important;
}

.bg-pink-950 {
  --bg-opacity: 1;
  background-color: var(--pink-950) !important;
}

.bg-primary-50 {
  --bg-opacity: 1;
  background-color: var(--primary-50) !important;
}

.bg-primary-100 {
  --bg-opacity: 1;
  background-color: var(--primary-100) !important;
}

.bg-primary-200 {
  --bg-opacity: 1;
  background-color: var(--primary-200) !important;
}

.bg-primary-300 {
  --bg-opacity: 1;
  background-color: var(--primary-300) !important;
}

.bg-primary-400 {
  --bg-opacity: 1;
  background-color: var(--primary-400) !important;
}

.bg-primary-500 {
  --bg-opacity: 1;
  background-color: var(--primary-500) !important;
}

.bg-primary-600 {
  --bg-opacity: 1;
  background-color: var(--primary-600) !important;
}

.bg-primary-700 {
  --bg-opacity: 1;
  background-color: var(--primary-700) !important;
}

.bg-primary-800 {
  --bg-opacity: 1;
  background-color: var(--primary-800) !important;
}

.bg-primary-900 {
  --bg-opacity: 1;
  background-color: var(--primary-900) !important;
}

.bg-primary-950 {
  --bg-opacity: 1;
  background-color: var(--primary-950) !important;
}

.bg-success-50 {
  --bg-opacity: 1;
  background-color: var(--success-50) !important;
}

.bg-success-100 {
  --bg-opacity: 1;
  background-color: var(--success-100) !important;
}

.bg-success-200 {
  --bg-opacity: 1;
  background-color: var(--success-200) !important;
}

.bg-success-300 {
  --bg-opacity: 1;
  background-color: var(--success-300) !important;
}

.bg-success-400 {
  --bg-opacity: 1;
  background-color: var(--success-400) !important;
}

.bg-success-500 {
  --bg-opacity: 1;
  background-color: var(--success-500) !important;
}

.bg-success-600 {
  --bg-opacity: 1;
  background-color: var(--success-600) !important;
}

.bg-success-700 {
  --bg-opacity: 1;
  background-color: var(--success-700) !important;
}

.bg-success-800 {
  --bg-opacity: 1;
  background-color: var(--success-800) !important;
}

.bg-success-900 {
  --bg-opacity: 1;
  background-color: var(--success-900) !important;
}

.bg-success-950 {
  --bg-opacity: 1;
  background-color: var(--success-950) !important;
}

.bg-warning-50 {
  --bg-opacity: 1;
  background-color: var(--warning-50) !important;
}

.bg-warning-100 {
  --bg-opacity: 1;
  background-color: var(--warning-100) !important;
}

.bg-warning-200 {
  --bg-opacity: 1;
  background-color: var(--warning-200) !important;
}

.bg-warning-300 {
  --bg-opacity: 1;
  background-color: var(--warning-300) !important;
}

.bg-warning-400 {
  --bg-opacity: 1;
  background-color: var(--warning-400) !important;
}

.bg-warning-500 {
  --bg-opacity: 1;
  background-color: var(--warning-500) !important;
}

.bg-warning-600 {
  --bg-opacity: 1;
  background-color: var(--warning-600) !important;
}

.bg-warning-700 {
  --bg-opacity: 1;
  background-color: var(--warning-700) !important;
}

.bg-warning-800 {
  --bg-opacity: 1;
  background-color: var(--warning-800) !important;
}

.bg-warning-900 {
  --bg-opacity: 1;
  background-color: var(--warning-900) !important;
}

.bg-warning-950 {
  --bg-opacity: 1;
  background-color: var(--warning-950) !important;
}

.bg-danger-50 {
  --bg-opacity: 1;
  background-color: var(--danger-50) !important;
}

.bg-danger-100 {
  --bg-opacity: 1;
  background-color: var(--danger-100) !important;
}

.bg-danger-200 {
  --bg-opacity: 1;
  background-color: var(--danger-200) !important;
}

.bg-danger-300 {
  --bg-opacity: 1;
  background-color: var(--danger-300) !important;
}

.bg-danger-400 {
  --bg-opacity: 1;
  background-color: var(--danger-400) !important;
}

.bg-danger-500 {
  --bg-opacity: 1;
  background-color: var(--danger-500) !important;
}

.bg-danger-600 {
  --bg-opacity: 1;
  background-color: var(--danger-600) !important;
}

.bg-danger-700 {
  --bg-opacity: 1;
  background-color: var(--danger-700) !important;
}

.bg-danger-800 {
  --bg-opacity: 1;
  background-color: var(--danger-800) !important;
}

.bg-danger-900 {
  --bg-opacity: 1;
  background-color: var(--danger-900) !important;
}

.bg-danger-950 {
  --bg-opacity: 1;
  background-color: var(--danger-950) !important;
}

.bg-info-50 {
  --bg-opacity: 1;
  background-color: var(--info-50) !important;
}

.bg-info-100 {
  --bg-opacity: 1;
  background-color: var(--info-100) !important;
}

.bg-info-200 {
  --bg-opacity: 1;
  background-color: var(--info-200) !important;
}

.bg-info-300 {
  --bg-opacity: 1;
  background-color: var(--info-300) !important;
}

.bg-info-400 {
  --bg-opacity: 1;
  background-color: var(--info-400) !important;
}

.bg-info-500 {
  --bg-opacity: 1;
  background-color: var(--info-500) !important;
}

.bg-info-600 {
  --bg-opacity: 1;
  background-color: var(--info-600) !important;
}

.bg-info-700 {
  --bg-opacity: 1;
  background-color: var(--info-700) !important;
}

.bg-info-800 {
  --bg-opacity: 1;
  background-color: var(--info-800) !important;
}

.bg-info-900 {
  --bg-opacity: 1;
  background-color: var(--info-900) !important;
}

.bg-info-950 {
  --bg-opacity: 1;
  background-color: var(--info-950) !important;
}

.bg-opacity-10 {
  --bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--border-radius) !important;
}

.rounded-3 {
  border-radius: var(--border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--border-radius) !important;
  border-top-right-radius: var(--border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--border-radius-sm) !important;
  border-top-right-radius: var(--border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--border-radius) !important;
  border-top-right-radius: var(--border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--border-radius-lg) !important;
  border-top-right-radius: var(--border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--border-radius-xl) !important;
  border-top-right-radius: var(--border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--border-radius-xxl) !important;
  border-top-right-radius: var(--border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--border-radius-pill) !important;
  border-top-right-radius: var(--border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--border-radius) !important;
  border-bottom-right-radius: var(--border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--border-radius-sm) !important;
  border-bottom-right-radius: var(--border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--border-radius) !important;
  border-bottom-right-radius: var(--border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--border-radius-lg) !important;
  border-bottom-right-radius: var(--border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--border-radius-xl) !important;
  border-bottom-right-radius: var(--border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--border-radius-xxl) !important;
  border-bottom-right-radius: var(--border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--border-radius-pill) !important;
  border-bottom-right-radius: var(--border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--border-radius) !important;
  border-bottom-left-radius: var(--border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--border-radius-sm) !important;
  border-bottom-left-radius: var(--border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--border-radius) !important;
  border-bottom-left-radius: var(--border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--border-radius-lg) !important;
  border-bottom-left-radius: var(--border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--border-radius-xl) !important;
  border-bottom-left-radius: var(--border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--border-radius-xxl) !important;
  border-bottom-left-radius: var(--border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--border-radius-pill) !important;
  border-bottom-left-radius: var(--border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--border-radius) !important;
  border-top-left-radius: var(--border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--border-radius-sm) !important;
  border-top-left-radius: var(--border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--border-radius) !important;
  border-top-left-radius: var(--border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--border-radius-lg) !important;
  border-top-left-radius: var(--border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--border-radius-xl) !important;
  border-top-left-radius: var(--border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--border-radius-xxl) !important;
  border-top-left-radius: var(--border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--border-radius-pill) !important;
  border-top-left-radius: var(--border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.aspect-3-by-2 {
  aspect-ratio: 3 / 2 !important;
}

.aspect-square {
  aspect-ratio: 1 / 1 !important;
}

.aspect-video {
  aspect-ratio: 16 / 9 !important;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr) !important;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr) !important;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, 1fr) !important;
}

.grid-cols-6 {
  grid-template-columns: repeat(6, 1fr) !important;
}

.grid-cols-8 {
  grid-template-columns: repeat(8, 1fr) !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: var(--s-1) !important;
  }
  .m-sm-2 {
    margin: var(--s-2) !important;
  }
  .m-sm-3 {
    margin: var(--s-3) !important;
  }
  .m-sm-4 {
    margin: var(--s-4) !important;
  }
  .m-sm-5 {
    margin: var(--s-5) !important;
  }
  .m-sm-6 {
    margin: var(--s-6) !important;
  }
  .m-sm-hero {
    margin: var(--s-hero) !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: var(--s-1) !important;
    margin-left: var(--s-1) !important;
  }
  .mx-sm-2 {
    margin-right: var(--s-2) !important;
    margin-left: var(--s-2) !important;
  }
  .mx-sm-3 {
    margin-right: var(--s-3) !important;
    margin-left: var(--s-3) !important;
  }
  .mx-sm-4 {
    margin-right: var(--s-4) !important;
    margin-left: var(--s-4) !important;
  }
  .mx-sm-5 {
    margin-right: var(--s-5) !important;
    margin-left: var(--s-5) !important;
  }
  .mx-sm-6 {
    margin-right: var(--s-6) !important;
    margin-left: var(--s-6) !important;
  }
  .mx-sm-hero {
    margin-right: var(--s-hero) !important;
    margin-left: var(--s-hero) !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: var(--s-1) !important;
    margin-bottom: var(--s-1) !important;
  }
  .my-sm-2 {
    margin-top: var(--s-2) !important;
    margin-bottom: var(--s-2) !important;
  }
  .my-sm-3 {
    margin-top: var(--s-3) !important;
    margin-bottom: var(--s-3) !important;
  }
  .my-sm-4 {
    margin-top: var(--s-4) !important;
    margin-bottom: var(--s-4) !important;
  }
  .my-sm-5 {
    margin-top: var(--s-5) !important;
    margin-bottom: var(--s-5) !important;
  }
  .my-sm-6 {
    margin-top: var(--s-6) !important;
    margin-bottom: var(--s-6) !important;
  }
  .my-sm-hero {
    margin-top: var(--s-hero) !important;
    margin-bottom: var(--s-hero) !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: var(--s-1) !important;
  }
  .mt-sm-2 {
    margin-top: var(--s-2) !important;
  }
  .mt-sm-3 {
    margin-top: var(--s-3) !important;
  }
  .mt-sm-4 {
    margin-top: var(--s-4) !important;
  }
  .mt-sm-5 {
    margin-top: var(--s-5) !important;
  }
  .mt-sm-6 {
    margin-top: var(--s-6) !important;
  }
  .mt-sm-hero {
    margin-top: var(--s-hero) !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: var(--s-1) !important;
  }
  .me-sm-2 {
    margin-right: var(--s-2) !important;
  }
  .me-sm-3 {
    margin-right: var(--s-3) !important;
  }
  .me-sm-4 {
    margin-right: var(--s-4) !important;
  }
  .me-sm-5 {
    margin-right: var(--s-5) !important;
  }
  .me-sm-6 {
    margin-right: var(--s-6) !important;
  }
  .me-sm-hero {
    margin-right: var(--s-hero) !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: var(--s-1) !important;
  }
  .mb-sm-2 {
    margin-bottom: var(--s-2) !important;
  }
  .mb-sm-3 {
    margin-bottom: var(--s-3) !important;
  }
  .mb-sm-4 {
    margin-bottom: var(--s-4) !important;
  }
  .mb-sm-5 {
    margin-bottom: var(--s-5) !important;
  }
  .mb-sm-6 {
    margin-bottom: var(--s-6) !important;
  }
  .mb-sm-hero {
    margin-bottom: var(--s-hero) !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: var(--s-1) !important;
  }
  .ms-sm-2 {
    margin-left: var(--s-2) !important;
  }
  .ms-sm-3 {
    margin-left: var(--s-3) !important;
  }
  .ms-sm-4 {
    margin-left: var(--s-4) !important;
  }
  .ms-sm-5 {
    margin-left: var(--s-5) !important;
  }
  .ms-sm-6 {
    margin-left: var(--s-6) !important;
  }
  .ms-sm-hero {
    margin-left: var(--s-hero) !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n0 {
    margin: 0 !important;
  }
  .m-sm-n1 {
    margin: -var(--s-1) !important;
  }
  .m-sm-n2 {
    margin: -var(--s-2) !important;
  }
  .m-sm-n3 {
    margin: -var(--s-3) !important;
  }
  .m-sm-n4 {
    margin: -var(--s-4) !important;
  }
  .m-sm-n5 {
    margin: -var(--s-5) !important;
  }
  .m-sm-n6 {
    margin: -var(--s-6) !important;
  }
  .m-sm-nhero {
    margin: -var(--s-hero) !important;
  }
  .mx-sm-n0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-n1 {
    margin-right: -var(--s-1) !important;
    margin-left: -var(--s-1) !important;
  }
  .mx-sm-n2 {
    margin-right: -var(--s-2) !important;
    margin-left: -var(--s-2) !important;
  }
  .mx-sm-n3 {
    margin-right: -var(--s-3) !important;
    margin-left: -var(--s-3) !important;
  }
  .mx-sm-n4 {
    margin-right: -var(--s-4) !important;
    margin-left: -var(--s-4) !important;
  }
  .mx-sm-n5 {
    margin-right: -var(--s-5) !important;
    margin-left: -var(--s-5) !important;
  }
  .mx-sm-n6 {
    margin-right: -var(--s-6) !important;
    margin-left: -var(--s-6) !important;
  }
  .mx-sm-nhero {
    margin-right: -var(--s-hero) !important;
    margin-left: -var(--s-hero) !important;
  }
  .my-sm-n0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-n1 {
    margin-top: -var(--s-1) !important;
    margin-bottom: -var(--s-1) !important;
  }
  .my-sm-n2 {
    margin-top: -var(--s-2) !important;
    margin-bottom: -var(--s-2) !important;
  }
  .my-sm-n3 {
    margin-top: -var(--s-3) !important;
    margin-bottom: -var(--s-3) !important;
  }
  .my-sm-n4 {
    margin-top: -var(--s-4) !important;
    margin-bottom: -var(--s-4) !important;
  }
  .my-sm-n5 {
    margin-top: -var(--s-5) !important;
    margin-bottom: -var(--s-5) !important;
  }
  .my-sm-n6 {
    margin-top: -var(--s-6) !important;
    margin-bottom: -var(--s-6) !important;
  }
  .my-sm-nhero {
    margin-top: -var(--s-hero) !important;
    margin-bottom: -var(--s-hero) !important;
  }
  .mt-sm-n0 {
    margin-top: 0 !important;
  }
  .mt-sm-n1 {
    margin-top: -var(--s-1) !important;
  }
  .mt-sm-n2 {
    margin-top: -var(--s-2) !important;
  }
  .mt-sm-n3 {
    margin-top: -var(--s-3) !important;
  }
  .mt-sm-n4 {
    margin-top: -var(--s-4) !important;
  }
  .mt-sm-n5 {
    margin-top: -var(--s-5) !important;
  }
  .mt-sm-n6 {
    margin-top: -var(--s-6) !important;
  }
  .mt-sm-nhero {
    margin-top: -var(--s-hero) !important;
  }
  .me-sm-n0 {
    margin-right: 0 !important;
  }
  .me-sm-n1 {
    margin-right: -var(--s-1) !important;
  }
  .me-sm-n2 {
    margin-right: -var(--s-2) !important;
  }
  .me-sm-n3 {
    margin-right: -var(--s-3) !important;
  }
  .me-sm-n4 {
    margin-right: -var(--s-4) !important;
  }
  .me-sm-n5 {
    margin-right: -var(--s-5) !important;
  }
  .me-sm-n6 {
    margin-right: -var(--s-6) !important;
  }
  .me-sm-nhero {
    margin-right: -var(--s-hero) !important;
  }
  .mb-sm-n0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-n1 {
    margin-bottom: -var(--s-1) !important;
  }
  .mb-sm-n2 {
    margin-bottom: -var(--s-2) !important;
  }
  .mb-sm-n3 {
    margin-bottom: -var(--s-3) !important;
  }
  .mb-sm-n4 {
    margin-bottom: -var(--s-4) !important;
  }
  .mb-sm-n5 {
    margin-bottom: -var(--s-5) !important;
  }
  .mb-sm-n6 {
    margin-bottom: -var(--s-6) !important;
  }
  .mb-sm-nhero {
    margin-bottom: -var(--s-hero) !important;
  }
  .ms-sm-n0 {
    margin-left: 0 !important;
  }
  .ms-sm-n1 {
    margin-left: -var(--s-1) !important;
  }
  .ms-sm-n2 {
    margin-left: -var(--s-2) !important;
  }
  .ms-sm-n3 {
    margin-left: -var(--s-3) !important;
  }
  .ms-sm-n4 {
    margin-left: -var(--s-4) !important;
  }
  .ms-sm-n5 {
    margin-left: -var(--s-5) !important;
  }
  .ms-sm-n6 {
    margin-left: -var(--s-6) !important;
  }
  .ms-sm-nhero {
    margin-left: -var(--s-hero) !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: var(--s-1) !important;
  }
  .p-sm-2 {
    padding: var(--s-2) !important;
  }
  .p-sm-3 {
    padding: var(--s-3) !important;
  }
  .p-sm-4 {
    padding: var(--s-4) !important;
  }
  .p-sm-5 {
    padding: var(--s-5) !important;
  }
  .p-sm-6 {
    padding: var(--s-6) !important;
  }
  .p-sm-hero {
    padding: var(--s-hero) !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: var(--s-1) !important;
    padding-left: var(--s-1) !important;
  }
  .px-sm-2 {
    padding-right: var(--s-2) !important;
    padding-left: var(--s-2) !important;
  }
  .px-sm-3 {
    padding-right: var(--s-3) !important;
    padding-left: var(--s-3) !important;
  }
  .px-sm-4 {
    padding-right: var(--s-4) !important;
    padding-left: var(--s-4) !important;
  }
  .px-sm-5 {
    padding-right: var(--s-5) !important;
    padding-left: var(--s-5) !important;
  }
  .px-sm-6 {
    padding-right: var(--s-6) !important;
    padding-left: var(--s-6) !important;
  }
  .px-sm-hero {
    padding-right: var(--s-hero) !important;
    padding-left: var(--s-hero) !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: var(--s-1) !important;
    padding-bottom: var(--s-1) !important;
  }
  .py-sm-2 {
    padding-top: var(--s-2) !important;
    padding-bottom: var(--s-2) !important;
  }
  .py-sm-3 {
    padding-top: var(--s-3) !important;
    padding-bottom: var(--s-3) !important;
  }
  .py-sm-4 {
    padding-top: var(--s-4) !important;
    padding-bottom: var(--s-4) !important;
  }
  .py-sm-5 {
    padding-top: var(--s-5) !important;
    padding-bottom: var(--s-5) !important;
  }
  .py-sm-6 {
    padding-top: var(--s-6) !important;
    padding-bottom: var(--s-6) !important;
  }
  .py-sm-hero {
    padding-top: var(--s-hero) !important;
    padding-bottom: var(--s-hero) !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: var(--s-1) !important;
  }
  .pt-sm-2 {
    padding-top: var(--s-2) !important;
  }
  .pt-sm-3 {
    padding-top: var(--s-3) !important;
  }
  .pt-sm-4 {
    padding-top: var(--s-4) !important;
  }
  .pt-sm-5 {
    padding-top: var(--s-5) !important;
  }
  .pt-sm-6 {
    padding-top: var(--s-6) !important;
  }
  .pt-sm-hero {
    padding-top: var(--s-hero) !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: var(--s-1) !important;
  }
  .pe-sm-2 {
    padding-right: var(--s-2) !important;
  }
  .pe-sm-3 {
    padding-right: var(--s-3) !important;
  }
  .pe-sm-4 {
    padding-right: var(--s-4) !important;
  }
  .pe-sm-5 {
    padding-right: var(--s-5) !important;
  }
  .pe-sm-6 {
    padding-right: var(--s-6) !important;
  }
  .pe-sm-hero {
    padding-right: var(--s-hero) !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: var(--s-1) !important;
  }
  .pb-sm-2 {
    padding-bottom: var(--s-2) !important;
  }
  .pb-sm-3 {
    padding-bottom: var(--s-3) !important;
  }
  .pb-sm-4 {
    padding-bottom: var(--s-4) !important;
  }
  .pb-sm-5 {
    padding-bottom: var(--s-5) !important;
  }
  .pb-sm-6 {
    padding-bottom: var(--s-6) !important;
  }
  .pb-sm-hero {
    padding-bottom: var(--s-hero) !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: var(--s-1) !important;
  }
  .ps-sm-2 {
    padding-left: var(--s-2) !important;
  }
  .ps-sm-3 {
    padding-left: var(--s-3) !important;
  }
  .ps-sm-4 {
    padding-left: var(--s-4) !important;
  }
  .ps-sm-5 {
    padding-left: var(--s-5) !important;
  }
  .ps-sm-6 {
    padding-left: var(--s-6) !important;
  }
  .ps-sm-hero {
    padding-left: var(--s-hero) !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: var(--s-1) !important;
  }
  .gap-sm-2 {
    gap: var(--s-2) !important;
  }
  .gap-sm-3 {
    gap: var(--s-3) !important;
  }
  .gap-sm-4 {
    gap: var(--s-4) !important;
  }
  .gap-sm-5 {
    gap: var(--s-5) !important;
  }
  .gap-sm-6 {
    gap: var(--s-6) !important;
  }
  .gap-sm-hero {
    gap: var(--s-hero) !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: var(--s-1) !important;
  }
  .row-gap-sm-2 {
    row-gap: var(--s-2) !important;
  }
  .row-gap-sm-3 {
    row-gap: var(--s-3) !important;
  }
  .row-gap-sm-4 {
    row-gap: var(--s-4) !important;
  }
  .row-gap-sm-5 {
    row-gap: var(--s-5) !important;
  }
  .row-gap-sm-6 {
    row-gap: var(--s-6) !important;
  }
  .row-gap-sm-hero {
    row-gap: var(--s-hero) !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: var(--s-1) !important;
  }
  .column-gap-sm-2 {
    column-gap: var(--s-2) !important;
  }
  .column-gap-sm-3 {
    column-gap: var(--s-3) !important;
  }
  .column-gap-sm-4 {
    column-gap: var(--s-4) !important;
  }
  .column-gap-sm-5 {
    column-gap: var(--s-5) !important;
  }
  .column-gap-sm-6 {
    column-gap: var(--s-6) !important;
  }
  .column-gap-sm-hero {
    column-gap: var(--s-hero) !important;
  }
  .fs-sm-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .fs-sm-2 {
    font-size: calc(1.325rem + 0.9vw) !important;
  }
  .fs-sm-3 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-sm-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-sm-5 {
    font-size: 1.25rem !important;
  }
  .fs-sm-6 {
    font-size: 1rem !important;
  }
  .fs-sm-xxxs {
    font-size: var(--text-xxxs) !important;
  }
  .fs-sm-xxs {
    font-size: var(--text-xxs) !important;
  }
  .fs-sm-xs {
    font-size: var(--text-xs) !important;
  }
  .fs-sm-sm {
    font-size: var(--text-sm) !important;
  }
  .fs-sm-md {
    font-size: var(--text-md) !important;
  }
  .fs-sm-lg {
    font-size: var(--text-lg) !important;
  }
  .fs-sm-xl {
    font-size: var(--text-xl) !important;
  }
  .fs-sm-xxl {
    font-size: var(--text-xxl) !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
  .grid-cols-sm-2 {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .grid-cols-sm-3 {
    grid-template-columns: repeat(3, 1fr) !important;
  }
  .grid-cols-sm-4 {
    grid-template-columns: repeat(4, 1fr) !important;
  }
  .grid-cols-sm-6 {
    grid-template-columns: repeat(6, 1fr) !important;
  }
  .grid-cols-sm-8 {
    grid-template-columns: repeat(8, 1fr) !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: var(--s-1) !important;
  }
  .m-md-2 {
    margin: var(--s-2) !important;
  }
  .m-md-3 {
    margin: var(--s-3) !important;
  }
  .m-md-4 {
    margin: var(--s-4) !important;
  }
  .m-md-5 {
    margin: var(--s-5) !important;
  }
  .m-md-6 {
    margin: var(--s-6) !important;
  }
  .m-md-hero {
    margin: var(--s-hero) !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: var(--s-1) !important;
    margin-left: var(--s-1) !important;
  }
  .mx-md-2 {
    margin-right: var(--s-2) !important;
    margin-left: var(--s-2) !important;
  }
  .mx-md-3 {
    margin-right: var(--s-3) !important;
    margin-left: var(--s-3) !important;
  }
  .mx-md-4 {
    margin-right: var(--s-4) !important;
    margin-left: var(--s-4) !important;
  }
  .mx-md-5 {
    margin-right: var(--s-5) !important;
    margin-left: var(--s-5) !important;
  }
  .mx-md-6 {
    margin-right: var(--s-6) !important;
    margin-left: var(--s-6) !important;
  }
  .mx-md-hero {
    margin-right: var(--s-hero) !important;
    margin-left: var(--s-hero) !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: var(--s-1) !important;
    margin-bottom: var(--s-1) !important;
  }
  .my-md-2 {
    margin-top: var(--s-2) !important;
    margin-bottom: var(--s-2) !important;
  }
  .my-md-3 {
    margin-top: var(--s-3) !important;
    margin-bottom: var(--s-3) !important;
  }
  .my-md-4 {
    margin-top: var(--s-4) !important;
    margin-bottom: var(--s-4) !important;
  }
  .my-md-5 {
    margin-top: var(--s-5) !important;
    margin-bottom: var(--s-5) !important;
  }
  .my-md-6 {
    margin-top: var(--s-6) !important;
    margin-bottom: var(--s-6) !important;
  }
  .my-md-hero {
    margin-top: var(--s-hero) !important;
    margin-bottom: var(--s-hero) !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: var(--s-1) !important;
  }
  .mt-md-2 {
    margin-top: var(--s-2) !important;
  }
  .mt-md-3 {
    margin-top: var(--s-3) !important;
  }
  .mt-md-4 {
    margin-top: var(--s-4) !important;
  }
  .mt-md-5 {
    margin-top: var(--s-5) !important;
  }
  .mt-md-6 {
    margin-top: var(--s-6) !important;
  }
  .mt-md-hero {
    margin-top: var(--s-hero) !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: var(--s-1) !important;
  }
  .me-md-2 {
    margin-right: var(--s-2) !important;
  }
  .me-md-3 {
    margin-right: var(--s-3) !important;
  }
  .me-md-4 {
    margin-right: var(--s-4) !important;
  }
  .me-md-5 {
    margin-right: var(--s-5) !important;
  }
  .me-md-6 {
    margin-right: var(--s-6) !important;
  }
  .me-md-hero {
    margin-right: var(--s-hero) !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: var(--s-1) !important;
  }
  .mb-md-2 {
    margin-bottom: var(--s-2) !important;
  }
  .mb-md-3 {
    margin-bottom: var(--s-3) !important;
  }
  .mb-md-4 {
    margin-bottom: var(--s-4) !important;
  }
  .mb-md-5 {
    margin-bottom: var(--s-5) !important;
  }
  .mb-md-6 {
    margin-bottom: var(--s-6) !important;
  }
  .mb-md-hero {
    margin-bottom: var(--s-hero) !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: var(--s-1) !important;
  }
  .ms-md-2 {
    margin-left: var(--s-2) !important;
  }
  .ms-md-3 {
    margin-left: var(--s-3) !important;
  }
  .ms-md-4 {
    margin-left: var(--s-4) !important;
  }
  .ms-md-5 {
    margin-left: var(--s-5) !important;
  }
  .ms-md-6 {
    margin-left: var(--s-6) !important;
  }
  .ms-md-hero {
    margin-left: var(--s-hero) !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n0 {
    margin: 0 !important;
  }
  .m-md-n1 {
    margin: -var(--s-1) !important;
  }
  .m-md-n2 {
    margin: -var(--s-2) !important;
  }
  .m-md-n3 {
    margin: -var(--s-3) !important;
  }
  .m-md-n4 {
    margin: -var(--s-4) !important;
  }
  .m-md-n5 {
    margin: -var(--s-5) !important;
  }
  .m-md-n6 {
    margin: -var(--s-6) !important;
  }
  .m-md-nhero {
    margin: -var(--s-hero) !important;
  }
  .mx-md-n0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-n1 {
    margin-right: -var(--s-1) !important;
    margin-left: -var(--s-1) !important;
  }
  .mx-md-n2 {
    margin-right: -var(--s-2) !important;
    margin-left: -var(--s-2) !important;
  }
  .mx-md-n3 {
    margin-right: -var(--s-3) !important;
    margin-left: -var(--s-3) !important;
  }
  .mx-md-n4 {
    margin-right: -var(--s-4) !important;
    margin-left: -var(--s-4) !important;
  }
  .mx-md-n5 {
    margin-right: -var(--s-5) !important;
    margin-left: -var(--s-5) !important;
  }
  .mx-md-n6 {
    margin-right: -var(--s-6) !important;
    margin-left: -var(--s-6) !important;
  }
  .mx-md-nhero {
    margin-right: -var(--s-hero) !important;
    margin-left: -var(--s-hero) !important;
  }
  .my-md-n0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-n1 {
    margin-top: -var(--s-1) !important;
    margin-bottom: -var(--s-1) !important;
  }
  .my-md-n2 {
    margin-top: -var(--s-2) !important;
    margin-bottom: -var(--s-2) !important;
  }
  .my-md-n3 {
    margin-top: -var(--s-3) !important;
    margin-bottom: -var(--s-3) !important;
  }
  .my-md-n4 {
    margin-top: -var(--s-4) !important;
    margin-bottom: -var(--s-4) !important;
  }
  .my-md-n5 {
    margin-top: -var(--s-5) !important;
    margin-bottom: -var(--s-5) !important;
  }
  .my-md-n6 {
    margin-top: -var(--s-6) !important;
    margin-bottom: -var(--s-6) !important;
  }
  .my-md-nhero {
    margin-top: -var(--s-hero) !important;
    margin-bottom: -var(--s-hero) !important;
  }
  .mt-md-n0 {
    margin-top: 0 !important;
  }
  .mt-md-n1 {
    margin-top: -var(--s-1) !important;
  }
  .mt-md-n2 {
    margin-top: -var(--s-2) !important;
  }
  .mt-md-n3 {
    margin-top: -var(--s-3) !important;
  }
  .mt-md-n4 {
    margin-top: -var(--s-4) !important;
  }
  .mt-md-n5 {
    margin-top: -var(--s-5) !important;
  }
  .mt-md-n6 {
    margin-top: -var(--s-6) !important;
  }
  .mt-md-nhero {
    margin-top: -var(--s-hero) !important;
  }
  .me-md-n0 {
    margin-right: 0 !important;
  }
  .me-md-n1 {
    margin-right: -var(--s-1) !important;
  }
  .me-md-n2 {
    margin-right: -var(--s-2) !important;
  }
  .me-md-n3 {
    margin-right: -var(--s-3) !important;
  }
  .me-md-n4 {
    margin-right: -var(--s-4) !important;
  }
  .me-md-n5 {
    margin-right: -var(--s-5) !important;
  }
  .me-md-n6 {
    margin-right: -var(--s-6) !important;
  }
  .me-md-nhero {
    margin-right: -var(--s-hero) !important;
  }
  .mb-md-n0 {
    margin-bottom: 0 !important;
  }
  .mb-md-n1 {
    margin-bottom: -var(--s-1) !important;
  }
  .mb-md-n2 {
    margin-bottom: -var(--s-2) !important;
  }
  .mb-md-n3 {
    margin-bottom: -var(--s-3) !important;
  }
  .mb-md-n4 {
    margin-bottom: -var(--s-4) !important;
  }
  .mb-md-n5 {
    margin-bottom: -var(--s-5) !important;
  }
  .mb-md-n6 {
    margin-bottom: -var(--s-6) !important;
  }
  .mb-md-nhero {
    margin-bottom: -var(--s-hero) !important;
  }
  .ms-md-n0 {
    margin-left: 0 !important;
  }
  .ms-md-n1 {
    margin-left: -var(--s-1) !important;
  }
  .ms-md-n2 {
    margin-left: -var(--s-2) !important;
  }
  .ms-md-n3 {
    margin-left: -var(--s-3) !important;
  }
  .ms-md-n4 {
    margin-left: -var(--s-4) !important;
  }
  .ms-md-n5 {
    margin-left: -var(--s-5) !important;
  }
  .ms-md-n6 {
    margin-left: -var(--s-6) !important;
  }
  .ms-md-nhero {
    margin-left: -var(--s-hero) !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: var(--s-1) !important;
  }
  .p-md-2 {
    padding: var(--s-2) !important;
  }
  .p-md-3 {
    padding: var(--s-3) !important;
  }
  .p-md-4 {
    padding: var(--s-4) !important;
  }
  .p-md-5 {
    padding: var(--s-5) !important;
  }
  .p-md-6 {
    padding: var(--s-6) !important;
  }
  .p-md-hero {
    padding: var(--s-hero) !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: var(--s-1) !important;
    padding-left: var(--s-1) !important;
  }
  .px-md-2 {
    padding-right: var(--s-2) !important;
    padding-left: var(--s-2) !important;
  }
  .px-md-3 {
    padding-right: var(--s-3) !important;
    padding-left: var(--s-3) !important;
  }
  .px-md-4 {
    padding-right: var(--s-4) !important;
    padding-left: var(--s-4) !important;
  }
  .px-md-5 {
    padding-right: var(--s-5) !important;
    padding-left: var(--s-5) !important;
  }
  .px-md-6 {
    padding-right: var(--s-6) !important;
    padding-left: var(--s-6) !important;
  }
  .px-md-hero {
    padding-right: var(--s-hero) !important;
    padding-left: var(--s-hero) !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: var(--s-1) !important;
    padding-bottom: var(--s-1) !important;
  }
  .py-md-2 {
    padding-top: var(--s-2) !important;
    padding-bottom: var(--s-2) !important;
  }
  .py-md-3 {
    padding-top: var(--s-3) !important;
    padding-bottom: var(--s-3) !important;
  }
  .py-md-4 {
    padding-top: var(--s-4) !important;
    padding-bottom: var(--s-4) !important;
  }
  .py-md-5 {
    padding-top: var(--s-5) !important;
    padding-bottom: var(--s-5) !important;
  }
  .py-md-6 {
    padding-top: var(--s-6) !important;
    padding-bottom: var(--s-6) !important;
  }
  .py-md-hero {
    padding-top: var(--s-hero) !important;
    padding-bottom: var(--s-hero) !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: var(--s-1) !important;
  }
  .pt-md-2 {
    padding-top: var(--s-2) !important;
  }
  .pt-md-3 {
    padding-top: var(--s-3) !important;
  }
  .pt-md-4 {
    padding-top: var(--s-4) !important;
  }
  .pt-md-5 {
    padding-top: var(--s-5) !important;
  }
  .pt-md-6 {
    padding-top: var(--s-6) !important;
  }
  .pt-md-hero {
    padding-top: var(--s-hero) !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: var(--s-1) !important;
  }
  .pe-md-2 {
    padding-right: var(--s-2) !important;
  }
  .pe-md-3 {
    padding-right: var(--s-3) !important;
  }
  .pe-md-4 {
    padding-right: var(--s-4) !important;
  }
  .pe-md-5 {
    padding-right: var(--s-5) !important;
  }
  .pe-md-6 {
    padding-right: var(--s-6) !important;
  }
  .pe-md-hero {
    padding-right: var(--s-hero) !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: var(--s-1) !important;
  }
  .pb-md-2 {
    padding-bottom: var(--s-2) !important;
  }
  .pb-md-3 {
    padding-bottom: var(--s-3) !important;
  }
  .pb-md-4 {
    padding-bottom: var(--s-4) !important;
  }
  .pb-md-5 {
    padding-bottom: var(--s-5) !important;
  }
  .pb-md-6 {
    padding-bottom: var(--s-6) !important;
  }
  .pb-md-hero {
    padding-bottom: var(--s-hero) !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: var(--s-1) !important;
  }
  .ps-md-2 {
    padding-left: var(--s-2) !important;
  }
  .ps-md-3 {
    padding-left: var(--s-3) !important;
  }
  .ps-md-4 {
    padding-left: var(--s-4) !important;
  }
  .ps-md-5 {
    padding-left: var(--s-5) !important;
  }
  .ps-md-6 {
    padding-left: var(--s-6) !important;
  }
  .ps-md-hero {
    padding-left: var(--s-hero) !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: var(--s-1) !important;
  }
  .gap-md-2 {
    gap: var(--s-2) !important;
  }
  .gap-md-3 {
    gap: var(--s-3) !important;
  }
  .gap-md-4 {
    gap: var(--s-4) !important;
  }
  .gap-md-5 {
    gap: var(--s-5) !important;
  }
  .gap-md-6 {
    gap: var(--s-6) !important;
  }
  .gap-md-hero {
    gap: var(--s-hero) !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: var(--s-1) !important;
  }
  .row-gap-md-2 {
    row-gap: var(--s-2) !important;
  }
  .row-gap-md-3 {
    row-gap: var(--s-3) !important;
  }
  .row-gap-md-4 {
    row-gap: var(--s-4) !important;
  }
  .row-gap-md-5 {
    row-gap: var(--s-5) !important;
  }
  .row-gap-md-6 {
    row-gap: var(--s-6) !important;
  }
  .row-gap-md-hero {
    row-gap: var(--s-hero) !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: var(--s-1) !important;
  }
  .column-gap-md-2 {
    column-gap: var(--s-2) !important;
  }
  .column-gap-md-3 {
    column-gap: var(--s-3) !important;
  }
  .column-gap-md-4 {
    column-gap: var(--s-4) !important;
  }
  .column-gap-md-5 {
    column-gap: var(--s-5) !important;
  }
  .column-gap-md-6 {
    column-gap: var(--s-6) !important;
  }
  .column-gap-md-hero {
    column-gap: var(--s-hero) !important;
  }
  .fs-md-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .fs-md-2 {
    font-size: calc(1.325rem + 0.9vw) !important;
  }
  .fs-md-3 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-md-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-md-5 {
    font-size: 1.25rem !important;
  }
  .fs-md-6 {
    font-size: 1rem !important;
  }
  .fs-md-xxxs {
    font-size: var(--text-xxxs) !important;
  }
  .fs-md-xxs {
    font-size: var(--text-xxs) !important;
  }
  .fs-md-xs {
    font-size: var(--text-xs) !important;
  }
  .fs-md-sm {
    font-size: var(--text-sm) !important;
  }
  .fs-md-md {
    font-size: var(--text-md) !important;
  }
  .fs-md-lg {
    font-size: var(--text-lg) !important;
  }
  .fs-md-xl {
    font-size: var(--text-xl) !important;
  }
  .fs-md-xxl {
    font-size: var(--text-xxl) !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
  .grid-cols-md-2 {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .grid-cols-md-3 {
    grid-template-columns: repeat(3, 1fr) !important;
  }
  .grid-cols-md-4 {
    grid-template-columns: repeat(4, 1fr) !important;
  }
  .grid-cols-md-6 {
    grid-template-columns: repeat(6, 1fr) !important;
  }
  .grid-cols-md-8 {
    grid-template-columns: repeat(8, 1fr) !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: var(--s-1) !important;
  }
  .m-lg-2 {
    margin: var(--s-2) !important;
  }
  .m-lg-3 {
    margin: var(--s-3) !important;
  }
  .m-lg-4 {
    margin: var(--s-4) !important;
  }
  .m-lg-5 {
    margin: var(--s-5) !important;
  }
  .m-lg-6 {
    margin: var(--s-6) !important;
  }
  .m-lg-hero {
    margin: var(--s-hero) !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: var(--s-1) !important;
    margin-left: var(--s-1) !important;
  }
  .mx-lg-2 {
    margin-right: var(--s-2) !important;
    margin-left: var(--s-2) !important;
  }
  .mx-lg-3 {
    margin-right: var(--s-3) !important;
    margin-left: var(--s-3) !important;
  }
  .mx-lg-4 {
    margin-right: var(--s-4) !important;
    margin-left: var(--s-4) !important;
  }
  .mx-lg-5 {
    margin-right: var(--s-5) !important;
    margin-left: var(--s-5) !important;
  }
  .mx-lg-6 {
    margin-right: var(--s-6) !important;
    margin-left: var(--s-6) !important;
  }
  .mx-lg-hero {
    margin-right: var(--s-hero) !important;
    margin-left: var(--s-hero) !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: var(--s-1) !important;
    margin-bottom: var(--s-1) !important;
  }
  .my-lg-2 {
    margin-top: var(--s-2) !important;
    margin-bottom: var(--s-2) !important;
  }
  .my-lg-3 {
    margin-top: var(--s-3) !important;
    margin-bottom: var(--s-3) !important;
  }
  .my-lg-4 {
    margin-top: var(--s-4) !important;
    margin-bottom: var(--s-4) !important;
  }
  .my-lg-5 {
    margin-top: var(--s-5) !important;
    margin-bottom: var(--s-5) !important;
  }
  .my-lg-6 {
    margin-top: var(--s-6) !important;
    margin-bottom: var(--s-6) !important;
  }
  .my-lg-hero {
    margin-top: var(--s-hero) !important;
    margin-bottom: var(--s-hero) !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: var(--s-1) !important;
  }
  .mt-lg-2 {
    margin-top: var(--s-2) !important;
  }
  .mt-lg-3 {
    margin-top: var(--s-3) !important;
  }
  .mt-lg-4 {
    margin-top: var(--s-4) !important;
  }
  .mt-lg-5 {
    margin-top: var(--s-5) !important;
  }
  .mt-lg-6 {
    margin-top: var(--s-6) !important;
  }
  .mt-lg-hero {
    margin-top: var(--s-hero) !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: var(--s-1) !important;
  }
  .me-lg-2 {
    margin-right: var(--s-2) !important;
  }
  .me-lg-3 {
    margin-right: var(--s-3) !important;
  }
  .me-lg-4 {
    margin-right: var(--s-4) !important;
  }
  .me-lg-5 {
    margin-right: var(--s-5) !important;
  }
  .me-lg-6 {
    margin-right: var(--s-6) !important;
  }
  .me-lg-hero {
    margin-right: var(--s-hero) !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: var(--s-1) !important;
  }
  .mb-lg-2 {
    margin-bottom: var(--s-2) !important;
  }
  .mb-lg-3 {
    margin-bottom: var(--s-3) !important;
  }
  .mb-lg-4 {
    margin-bottom: var(--s-4) !important;
  }
  .mb-lg-5 {
    margin-bottom: var(--s-5) !important;
  }
  .mb-lg-6 {
    margin-bottom: var(--s-6) !important;
  }
  .mb-lg-hero {
    margin-bottom: var(--s-hero) !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: var(--s-1) !important;
  }
  .ms-lg-2 {
    margin-left: var(--s-2) !important;
  }
  .ms-lg-3 {
    margin-left: var(--s-3) !important;
  }
  .ms-lg-4 {
    margin-left: var(--s-4) !important;
  }
  .ms-lg-5 {
    margin-left: var(--s-5) !important;
  }
  .ms-lg-6 {
    margin-left: var(--s-6) !important;
  }
  .ms-lg-hero {
    margin-left: var(--s-hero) !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n0 {
    margin: 0 !important;
  }
  .m-lg-n1 {
    margin: -var(--s-1) !important;
  }
  .m-lg-n2 {
    margin: -var(--s-2) !important;
  }
  .m-lg-n3 {
    margin: -var(--s-3) !important;
  }
  .m-lg-n4 {
    margin: -var(--s-4) !important;
  }
  .m-lg-n5 {
    margin: -var(--s-5) !important;
  }
  .m-lg-n6 {
    margin: -var(--s-6) !important;
  }
  .m-lg-nhero {
    margin: -var(--s-hero) !important;
  }
  .mx-lg-n0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-n1 {
    margin-right: -var(--s-1) !important;
    margin-left: -var(--s-1) !important;
  }
  .mx-lg-n2 {
    margin-right: -var(--s-2) !important;
    margin-left: -var(--s-2) !important;
  }
  .mx-lg-n3 {
    margin-right: -var(--s-3) !important;
    margin-left: -var(--s-3) !important;
  }
  .mx-lg-n4 {
    margin-right: -var(--s-4) !important;
    margin-left: -var(--s-4) !important;
  }
  .mx-lg-n5 {
    margin-right: -var(--s-5) !important;
    margin-left: -var(--s-5) !important;
  }
  .mx-lg-n6 {
    margin-right: -var(--s-6) !important;
    margin-left: -var(--s-6) !important;
  }
  .mx-lg-nhero {
    margin-right: -var(--s-hero) !important;
    margin-left: -var(--s-hero) !important;
  }
  .my-lg-n0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-n1 {
    margin-top: -var(--s-1) !important;
    margin-bottom: -var(--s-1) !important;
  }
  .my-lg-n2 {
    margin-top: -var(--s-2) !important;
    margin-bottom: -var(--s-2) !important;
  }
  .my-lg-n3 {
    margin-top: -var(--s-3) !important;
    margin-bottom: -var(--s-3) !important;
  }
  .my-lg-n4 {
    margin-top: -var(--s-4) !important;
    margin-bottom: -var(--s-4) !important;
  }
  .my-lg-n5 {
    margin-top: -var(--s-5) !important;
    margin-bottom: -var(--s-5) !important;
  }
  .my-lg-n6 {
    margin-top: -var(--s-6) !important;
    margin-bottom: -var(--s-6) !important;
  }
  .my-lg-nhero {
    margin-top: -var(--s-hero) !important;
    margin-bottom: -var(--s-hero) !important;
  }
  .mt-lg-n0 {
    margin-top: 0 !important;
  }
  .mt-lg-n1 {
    margin-top: -var(--s-1) !important;
  }
  .mt-lg-n2 {
    margin-top: -var(--s-2) !important;
  }
  .mt-lg-n3 {
    margin-top: -var(--s-3) !important;
  }
  .mt-lg-n4 {
    margin-top: -var(--s-4) !important;
  }
  .mt-lg-n5 {
    margin-top: -var(--s-5) !important;
  }
  .mt-lg-n6 {
    margin-top: -var(--s-6) !important;
  }
  .mt-lg-nhero {
    margin-top: -var(--s-hero) !important;
  }
  .me-lg-n0 {
    margin-right: 0 !important;
  }
  .me-lg-n1 {
    margin-right: -var(--s-1) !important;
  }
  .me-lg-n2 {
    margin-right: -var(--s-2) !important;
  }
  .me-lg-n3 {
    margin-right: -var(--s-3) !important;
  }
  .me-lg-n4 {
    margin-right: -var(--s-4) !important;
  }
  .me-lg-n5 {
    margin-right: -var(--s-5) !important;
  }
  .me-lg-n6 {
    margin-right: -var(--s-6) !important;
  }
  .me-lg-nhero {
    margin-right: -var(--s-hero) !important;
  }
  .mb-lg-n0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-n1 {
    margin-bottom: -var(--s-1) !important;
  }
  .mb-lg-n2 {
    margin-bottom: -var(--s-2) !important;
  }
  .mb-lg-n3 {
    margin-bottom: -var(--s-3) !important;
  }
  .mb-lg-n4 {
    margin-bottom: -var(--s-4) !important;
  }
  .mb-lg-n5 {
    margin-bottom: -var(--s-5) !important;
  }
  .mb-lg-n6 {
    margin-bottom: -var(--s-6) !important;
  }
  .mb-lg-nhero {
    margin-bottom: -var(--s-hero) !important;
  }
  .ms-lg-n0 {
    margin-left: 0 !important;
  }
  .ms-lg-n1 {
    margin-left: -var(--s-1) !important;
  }
  .ms-lg-n2 {
    margin-left: -var(--s-2) !important;
  }
  .ms-lg-n3 {
    margin-left: -var(--s-3) !important;
  }
  .ms-lg-n4 {
    margin-left: -var(--s-4) !important;
  }
  .ms-lg-n5 {
    margin-left: -var(--s-5) !important;
  }
  .ms-lg-n6 {
    margin-left: -var(--s-6) !important;
  }
  .ms-lg-nhero {
    margin-left: -var(--s-hero) !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: var(--s-1) !important;
  }
  .p-lg-2 {
    padding: var(--s-2) !important;
  }
  .p-lg-3 {
    padding: var(--s-3) !important;
  }
  .p-lg-4 {
    padding: var(--s-4) !important;
  }
  .p-lg-5 {
    padding: var(--s-5) !important;
  }
  .p-lg-6 {
    padding: var(--s-6) !important;
  }
  .p-lg-hero {
    padding: var(--s-hero) !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: var(--s-1) !important;
    padding-left: var(--s-1) !important;
  }
  .px-lg-2 {
    padding-right: var(--s-2) !important;
    padding-left: var(--s-2) !important;
  }
  .px-lg-3 {
    padding-right: var(--s-3) !important;
    padding-left: var(--s-3) !important;
  }
  .px-lg-4 {
    padding-right: var(--s-4) !important;
    padding-left: var(--s-4) !important;
  }
  .px-lg-5 {
    padding-right: var(--s-5) !important;
    padding-left: var(--s-5) !important;
  }
  .px-lg-6 {
    padding-right: var(--s-6) !important;
    padding-left: var(--s-6) !important;
  }
  .px-lg-hero {
    padding-right: var(--s-hero) !important;
    padding-left: var(--s-hero) !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: var(--s-1) !important;
    padding-bottom: var(--s-1) !important;
  }
  .py-lg-2 {
    padding-top: var(--s-2) !important;
    padding-bottom: var(--s-2) !important;
  }
  .py-lg-3 {
    padding-top: var(--s-3) !important;
    padding-bottom: var(--s-3) !important;
  }
  .py-lg-4 {
    padding-top: var(--s-4) !important;
    padding-bottom: var(--s-4) !important;
  }
  .py-lg-5 {
    padding-top: var(--s-5) !important;
    padding-bottom: var(--s-5) !important;
  }
  .py-lg-6 {
    padding-top: var(--s-6) !important;
    padding-bottom: var(--s-6) !important;
  }
  .py-lg-hero {
    padding-top: var(--s-hero) !important;
    padding-bottom: var(--s-hero) !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: var(--s-1) !important;
  }
  .pt-lg-2 {
    padding-top: var(--s-2) !important;
  }
  .pt-lg-3 {
    padding-top: var(--s-3) !important;
  }
  .pt-lg-4 {
    padding-top: var(--s-4) !important;
  }
  .pt-lg-5 {
    padding-top: var(--s-5) !important;
  }
  .pt-lg-6 {
    padding-top: var(--s-6) !important;
  }
  .pt-lg-hero {
    padding-top: var(--s-hero) !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: var(--s-1) !important;
  }
  .pe-lg-2 {
    padding-right: var(--s-2) !important;
  }
  .pe-lg-3 {
    padding-right: var(--s-3) !important;
  }
  .pe-lg-4 {
    padding-right: var(--s-4) !important;
  }
  .pe-lg-5 {
    padding-right: var(--s-5) !important;
  }
  .pe-lg-6 {
    padding-right: var(--s-6) !important;
  }
  .pe-lg-hero {
    padding-right: var(--s-hero) !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: var(--s-1) !important;
  }
  .pb-lg-2 {
    padding-bottom: var(--s-2) !important;
  }
  .pb-lg-3 {
    padding-bottom: var(--s-3) !important;
  }
  .pb-lg-4 {
    padding-bottom: var(--s-4) !important;
  }
  .pb-lg-5 {
    padding-bottom: var(--s-5) !important;
  }
  .pb-lg-6 {
    padding-bottom: var(--s-6) !important;
  }
  .pb-lg-hero {
    padding-bottom: var(--s-hero) !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: var(--s-1) !important;
  }
  .ps-lg-2 {
    padding-left: var(--s-2) !important;
  }
  .ps-lg-3 {
    padding-left: var(--s-3) !important;
  }
  .ps-lg-4 {
    padding-left: var(--s-4) !important;
  }
  .ps-lg-5 {
    padding-left: var(--s-5) !important;
  }
  .ps-lg-6 {
    padding-left: var(--s-6) !important;
  }
  .ps-lg-hero {
    padding-left: var(--s-hero) !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: var(--s-1) !important;
  }
  .gap-lg-2 {
    gap: var(--s-2) !important;
  }
  .gap-lg-3 {
    gap: var(--s-3) !important;
  }
  .gap-lg-4 {
    gap: var(--s-4) !important;
  }
  .gap-lg-5 {
    gap: var(--s-5) !important;
  }
  .gap-lg-6 {
    gap: var(--s-6) !important;
  }
  .gap-lg-hero {
    gap: var(--s-hero) !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: var(--s-1) !important;
  }
  .row-gap-lg-2 {
    row-gap: var(--s-2) !important;
  }
  .row-gap-lg-3 {
    row-gap: var(--s-3) !important;
  }
  .row-gap-lg-4 {
    row-gap: var(--s-4) !important;
  }
  .row-gap-lg-5 {
    row-gap: var(--s-5) !important;
  }
  .row-gap-lg-6 {
    row-gap: var(--s-6) !important;
  }
  .row-gap-lg-hero {
    row-gap: var(--s-hero) !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: var(--s-1) !important;
  }
  .column-gap-lg-2 {
    column-gap: var(--s-2) !important;
  }
  .column-gap-lg-3 {
    column-gap: var(--s-3) !important;
  }
  .column-gap-lg-4 {
    column-gap: var(--s-4) !important;
  }
  .column-gap-lg-5 {
    column-gap: var(--s-5) !important;
  }
  .column-gap-lg-6 {
    column-gap: var(--s-6) !important;
  }
  .column-gap-lg-hero {
    column-gap: var(--s-hero) !important;
  }
  .fs-lg-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .fs-lg-2 {
    font-size: calc(1.325rem + 0.9vw) !important;
  }
  .fs-lg-3 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-lg-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-lg-5 {
    font-size: 1.25rem !important;
  }
  .fs-lg-6 {
    font-size: 1rem !important;
  }
  .fs-lg-xxxs {
    font-size: var(--text-xxxs) !important;
  }
  .fs-lg-xxs {
    font-size: var(--text-xxs) !important;
  }
  .fs-lg-xs {
    font-size: var(--text-xs) !important;
  }
  .fs-lg-sm {
    font-size: var(--text-sm) !important;
  }
  .fs-lg-md {
    font-size: var(--text-md) !important;
  }
  .fs-lg-lg {
    font-size: var(--text-lg) !important;
  }
  .fs-lg-xl {
    font-size: var(--text-xl) !important;
  }
  .fs-lg-xxl {
    font-size: var(--text-xxl) !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
  .grid-cols-lg-2 {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .grid-cols-lg-3 {
    grid-template-columns: repeat(3, 1fr) !important;
  }
  .grid-cols-lg-4 {
    grid-template-columns: repeat(4, 1fr) !important;
  }
  .grid-cols-lg-6 {
    grid-template-columns: repeat(6, 1fr) !important;
  }
  .grid-cols-lg-8 {
    grid-template-columns: repeat(8, 1fr) !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: var(--s-1) !important;
  }
  .m-xl-2 {
    margin: var(--s-2) !important;
  }
  .m-xl-3 {
    margin: var(--s-3) !important;
  }
  .m-xl-4 {
    margin: var(--s-4) !important;
  }
  .m-xl-5 {
    margin: var(--s-5) !important;
  }
  .m-xl-6 {
    margin: var(--s-6) !important;
  }
  .m-xl-hero {
    margin: var(--s-hero) !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: var(--s-1) !important;
    margin-left: var(--s-1) !important;
  }
  .mx-xl-2 {
    margin-right: var(--s-2) !important;
    margin-left: var(--s-2) !important;
  }
  .mx-xl-3 {
    margin-right: var(--s-3) !important;
    margin-left: var(--s-3) !important;
  }
  .mx-xl-4 {
    margin-right: var(--s-4) !important;
    margin-left: var(--s-4) !important;
  }
  .mx-xl-5 {
    margin-right: var(--s-5) !important;
    margin-left: var(--s-5) !important;
  }
  .mx-xl-6 {
    margin-right: var(--s-6) !important;
    margin-left: var(--s-6) !important;
  }
  .mx-xl-hero {
    margin-right: var(--s-hero) !important;
    margin-left: var(--s-hero) !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: var(--s-1) !important;
    margin-bottom: var(--s-1) !important;
  }
  .my-xl-2 {
    margin-top: var(--s-2) !important;
    margin-bottom: var(--s-2) !important;
  }
  .my-xl-3 {
    margin-top: var(--s-3) !important;
    margin-bottom: var(--s-3) !important;
  }
  .my-xl-4 {
    margin-top: var(--s-4) !important;
    margin-bottom: var(--s-4) !important;
  }
  .my-xl-5 {
    margin-top: var(--s-5) !important;
    margin-bottom: var(--s-5) !important;
  }
  .my-xl-6 {
    margin-top: var(--s-6) !important;
    margin-bottom: var(--s-6) !important;
  }
  .my-xl-hero {
    margin-top: var(--s-hero) !important;
    margin-bottom: var(--s-hero) !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: var(--s-1) !important;
  }
  .mt-xl-2 {
    margin-top: var(--s-2) !important;
  }
  .mt-xl-3 {
    margin-top: var(--s-3) !important;
  }
  .mt-xl-4 {
    margin-top: var(--s-4) !important;
  }
  .mt-xl-5 {
    margin-top: var(--s-5) !important;
  }
  .mt-xl-6 {
    margin-top: var(--s-6) !important;
  }
  .mt-xl-hero {
    margin-top: var(--s-hero) !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: var(--s-1) !important;
  }
  .me-xl-2 {
    margin-right: var(--s-2) !important;
  }
  .me-xl-3 {
    margin-right: var(--s-3) !important;
  }
  .me-xl-4 {
    margin-right: var(--s-4) !important;
  }
  .me-xl-5 {
    margin-right: var(--s-5) !important;
  }
  .me-xl-6 {
    margin-right: var(--s-6) !important;
  }
  .me-xl-hero {
    margin-right: var(--s-hero) !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: var(--s-1) !important;
  }
  .mb-xl-2 {
    margin-bottom: var(--s-2) !important;
  }
  .mb-xl-3 {
    margin-bottom: var(--s-3) !important;
  }
  .mb-xl-4 {
    margin-bottom: var(--s-4) !important;
  }
  .mb-xl-5 {
    margin-bottom: var(--s-5) !important;
  }
  .mb-xl-6 {
    margin-bottom: var(--s-6) !important;
  }
  .mb-xl-hero {
    margin-bottom: var(--s-hero) !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: var(--s-1) !important;
  }
  .ms-xl-2 {
    margin-left: var(--s-2) !important;
  }
  .ms-xl-3 {
    margin-left: var(--s-3) !important;
  }
  .ms-xl-4 {
    margin-left: var(--s-4) !important;
  }
  .ms-xl-5 {
    margin-left: var(--s-5) !important;
  }
  .ms-xl-6 {
    margin-left: var(--s-6) !important;
  }
  .ms-xl-hero {
    margin-left: var(--s-hero) !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n0 {
    margin: 0 !important;
  }
  .m-xl-n1 {
    margin: -var(--s-1) !important;
  }
  .m-xl-n2 {
    margin: -var(--s-2) !important;
  }
  .m-xl-n3 {
    margin: -var(--s-3) !important;
  }
  .m-xl-n4 {
    margin: -var(--s-4) !important;
  }
  .m-xl-n5 {
    margin: -var(--s-5) !important;
  }
  .m-xl-n6 {
    margin: -var(--s-6) !important;
  }
  .m-xl-nhero {
    margin: -var(--s-hero) !important;
  }
  .mx-xl-n0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-n1 {
    margin-right: -var(--s-1) !important;
    margin-left: -var(--s-1) !important;
  }
  .mx-xl-n2 {
    margin-right: -var(--s-2) !important;
    margin-left: -var(--s-2) !important;
  }
  .mx-xl-n3 {
    margin-right: -var(--s-3) !important;
    margin-left: -var(--s-3) !important;
  }
  .mx-xl-n4 {
    margin-right: -var(--s-4) !important;
    margin-left: -var(--s-4) !important;
  }
  .mx-xl-n5 {
    margin-right: -var(--s-5) !important;
    margin-left: -var(--s-5) !important;
  }
  .mx-xl-n6 {
    margin-right: -var(--s-6) !important;
    margin-left: -var(--s-6) !important;
  }
  .mx-xl-nhero {
    margin-right: -var(--s-hero) !important;
    margin-left: -var(--s-hero) !important;
  }
  .my-xl-n0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-n1 {
    margin-top: -var(--s-1) !important;
    margin-bottom: -var(--s-1) !important;
  }
  .my-xl-n2 {
    margin-top: -var(--s-2) !important;
    margin-bottom: -var(--s-2) !important;
  }
  .my-xl-n3 {
    margin-top: -var(--s-3) !important;
    margin-bottom: -var(--s-3) !important;
  }
  .my-xl-n4 {
    margin-top: -var(--s-4) !important;
    margin-bottom: -var(--s-4) !important;
  }
  .my-xl-n5 {
    margin-top: -var(--s-5) !important;
    margin-bottom: -var(--s-5) !important;
  }
  .my-xl-n6 {
    margin-top: -var(--s-6) !important;
    margin-bottom: -var(--s-6) !important;
  }
  .my-xl-nhero {
    margin-top: -var(--s-hero) !important;
    margin-bottom: -var(--s-hero) !important;
  }
  .mt-xl-n0 {
    margin-top: 0 !important;
  }
  .mt-xl-n1 {
    margin-top: -var(--s-1) !important;
  }
  .mt-xl-n2 {
    margin-top: -var(--s-2) !important;
  }
  .mt-xl-n3 {
    margin-top: -var(--s-3) !important;
  }
  .mt-xl-n4 {
    margin-top: -var(--s-4) !important;
  }
  .mt-xl-n5 {
    margin-top: -var(--s-5) !important;
  }
  .mt-xl-n6 {
    margin-top: -var(--s-6) !important;
  }
  .mt-xl-nhero {
    margin-top: -var(--s-hero) !important;
  }
  .me-xl-n0 {
    margin-right: 0 !important;
  }
  .me-xl-n1 {
    margin-right: -var(--s-1) !important;
  }
  .me-xl-n2 {
    margin-right: -var(--s-2) !important;
  }
  .me-xl-n3 {
    margin-right: -var(--s-3) !important;
  }
  .me-xl-n4 {
    margin-right: -var(--s-4) !important;
  }
  .me-xl-n5 {
    margin-right: -var(--s-5) !important;
  }
  .me-xl-n6 {
    margin-right: -var(--s-6) !important;
  }
  .me-xl-nhero {
    margin-right: -var(--s-hero) !important;
  }
  .mb-xl-n0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-n1 {
    margin-bottom: -var(--s-1) !important;
  }
  .mb-xl-n2 {
    margin-bottom: -var(--s-2) !important;
  }
  .mb-xl-n3 {
    margin-bottom: -var(--s-3) !important;
  }
  .mb-xl-n4 {
    margin-bottom: -var(--s-4) !important;
  }
  .mb-xl-n5 {
    margin-bottom: -var(--s-5) !important;
  }
  .mb-xl-n6 {
    margin-bottom: -var(--s-6) !important;
  }
  .mb-xl-nhero {
    margin-bottom: -var(--s-hero) !important;
  }
  .ms-xl-n0 {
    margin-left: 0 !important;
  }
  .ms-xl-n1 {
    margin-left: -var(--s-1) !important;
  }
  .ms-xl-n2 {
    margin-left: -var(--s-2) !important;
  }
  .ms-xl-n3 {
    margin-left: -var(--s-3) !important;
  }
  .ms-xl-n4 {
    margin-left: -var(--s-4) !important;
  }
  .ms-xl-n5 {
    margin-left: -var(--s-5) !important;
  }
  .ms-xl-n6 {
    margin-left: -var(--s-6) !important;
  }
  .ms-xl-nhero {
    margin-left: -var(--s-hero) !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: var(--s-1) !important;
  }
  .p-xl-2 {
    padding: var(--s-2) !important;
  }
  .p-xl-3 {
    padding: var(--s-3) !important;
  }
  .p-xl-4 {
    padding: var(--s-4) !important;
  }
  .p-xl-5 {
    padding: var(--s-5) !important;
  }
  .p-xl-6 {
    padding: var(--s-6) !important;
  }
  .p-xl-hero {
    padding: var(--s-hero) !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: var(--s-1) !important;
    padding-left: var(--s-1) !important;
  }
  .px-xl-2 {
    padding-right: var(--s-2) !important;
    padding-left: var(--s-2) !important;
  }
  .px-xl-3 {
    padding-right: var(--s-3) !important;
    padding-left: var(--s-3) !important;
  }
  .px-xl-4 {
    padding-right: var(--s-4) !important;
    padding-left: var(--s-4) !important;
  }
  .px-xl-5 {
    padding-right: var(--s-5) !important;
    padding-left: var(--s-5) !important;
  }
  .px-xl-6 {
    padding-right: var(--s-6) !important;
    padding-left: var(--s-6) !important;
  }
  .px-xl-hero {
    padding-right: var(--s-hero) !important;
    padding-left: var(--s-hero) !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: var(--s-1) !important;
    padding-bottom: var(--s-1) !important;
  }
  .py-xl-2 {
    padding-top: var(--s-2) !important;
    padding-bottom: var(--s-2) !important;
  }
  .py-xl-3 {
    padding-top: var(--s-3) !important;
    padding-bottom: var(--s-3) !important;
  }
  .py-xl-4 {
    padding-top: var(--s-4) !important;
    padding-bottom: var(--s-4) !important;
  }
  .py-xl-5 {
    padding-top: var(--s-5) !important;
    padding-bottom: var(--s-5) !important;
  }
  .py-xl-6 {
    padding-top: var(--s-6) !important;
    padding-bottom: var(--s-6) !important;
  }
  .py-xl-hero {
    padding-top: var(--s-hero) !important;
    padding-bottom: var(--s-hero) !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: var(--s-1) !important;
  }
  .pt-xl-2 {
    padding-top: var(--s-2) !important;
  }
  .pt-xl-3 {
    padding-top: var(--s-3) !important;
  }
  .pt-xl-4 {
    padding-top: var(--s-4) !important;
  }
  .pt-xl-5 {
    padding-top: var(--s-5) !important;
  }
  .pt-xl-6 {
    padding-top: var(--s-6) !important;
  }
  .pt-xl-hero {
    padding-top: var(--s-hero) !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: var(--s-1) !important;
  }
  .pe-xl-2 {
    padding-right: var(--s-2) !important;
  }
  .pe-xl-3 {
    padding-right: var(--s-3) !important;
  }
  .pe-xl-4 {
    padding-right: var(--s-4) !important;
  }
  .pe-xl-5 {
    padding-right: var(--s-5) !important;
  }
  .pe-xl-6 {
    padding-right: var(--s-6) !important;
  }
  .pe-xl-hero {
    padding-right: var(--s-hero) !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: var(--s-1) !important;
  }
  .pb-xl-2 {
    padding-bottom: var(--s-2) !important;
  }
  .pb-xl-3 {
    padding-bottom: var(--s-3) !important;
  }
  .pb-xl-4 {
    padding-bottom: var(--s-4) !important;
  }
  .pb-xl-5 {
    padding-bottom: var(--s-5) !important;
  }
  .pb-xl-6 {
    padding-bottom: var(--s-6) !important;
  }
  .pb-xl-hero {
    padding-bottom: var(--s-hero) !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: var(--s-1) !important;
  }
  .ps-xl-2 {
    padding-left: var(--s-2) !important;
  }
  .ps-xl-3 {
    padding-left: var(--s-3) !important;
  }
  .ps-xl-4 {
    padding-left: var(--s-4) !important;
  }
  .ps-xl-5 {
    padding-left: var(--s-5) !important;
  }
  .ps-xl-6 {
    padding-left: var(--s-6) !important;
  }
  .ps-xl-hero {
    padding-left: var(--s-hero) !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: var(--s-1) !important;
  }
  .gap-xl-2 {
    gap: var(--s-2) !important;
  }
  .gap-xl-3 {
    gap: var(--s-3) !important;
  }
  .gap-xl-4 {
    gap: var(--s-4) !important;
  }
  .gap-xl-5 {
    gap: var(--s-5) !important;
  }
  .gap-xl-6 {
    gap: var(--s-6) !important;
  }
  .gap-xl-hero {
    gap: var(--s-hero) !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: var(--s-1) !important;
  }
  .row-gap-xl-2 {
    row-gap: var(--s-2) !important;
  }
  .row-gap-xl-3 {
    row-gap: var(--s-3) !important;
  }
  .row-gap-xl-4 {
    row-gap: var(--s-4) !important;
  }
  .row-gap-xl-5 {
    row-gap: var(--s-5) !important;
  }
  .row-gap-xl-6 {
    row-gap: var(--s-6) !important;
  }
  .row-gap-xl-hero {
    row-gap: var(--s-hero) !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: var(--s-1) !important;
  }
  .column-gap-xl-2 {
    column-gap: var(--s-2) !important;
  }
  .column-gap-xl-3 {
    column-gap: var(--s-3) !important;
  }
  .column-gap-xl-4 {
    column-gap: var(--s-4) !important;
  }
  .column-gap-xl-5 {
    column-gap: var(--s-5) !important;
  }
  .column-gap-xl-6 {
    column-gap: var(--s-6) !important;
  }
  .column-gap-xl-hero {
    column-gap: var(--s-hero) !important;
  }
  .fs-xl-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .fs-xl-2 {
    font-size: calc(1.325rem + 0.9vw) !important;
  }
  .fs-xl-3 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-xl-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-xl-5 {
    font-size: 1.25rem !important;
  }
  .fs-xl-6 {
    font-size: 1rem !important;
  }
  .fs-xl-xxxs {
    font-size: var(--text-xxxs) !important;
  }
  .fs-xl-xxs {
    font-size: var(--text-xxs) !important;
  }
  .fs-xl-xs {
    font-size: var(--text-xs) !important;
  }
  .fs-xl-sm {
    font-size: var(--text-sm) !important;
  }
  .fs-xl-md {
    font-size: var(--text-md) !important;
  }
  .fs-xl-lg {
    font-size: var(--text-lg) !important;
  }
  .fs-xl-xl {
    font-size: var(--text-xl) !important;
  }
  .fs-xl-xxl {
    font-size: var(--text-xxl) !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
  .grid-cols-xl-2 {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .grid-cols-xl-3 {
    grid-template-columns: repeat(3, 1fr) !important;
  }
  .grid-cols-xl-4 {
    grid-template-columns: repeat(4, 1fr) !important;
  }
  .grid-cols-xl-6 {
    grid-template-columns: repeat(6, 1fr) !important;
  }
  .grid-cols-xl-8 {
    grid-template-columns: repeat(8, 1fr) !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: var(--s-1) !important;
  }
  .m-xxl-2 {
    margin: var(--s-2) !important;
  }
  .m-xxl-3 {
    margin: var(--s-3) !important;
  }
  .m-xxl-4 {
    margin: var(--s-4) !important;
  }
  .m-xxl-5 {
    margin: var(--s-5) !important;
  }
  .m-xxl-6 {
    margin: var(--s-6) !important;
  }
  .m-xxl-hero {
    margin: var(--s-hero) !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: var(--s-1) !important;
    margin-left: var(--s-1) !important;
  }
  .mx-xxl-2 {
    margin-right: var(--s-2) !important;
    margin-left: var(--s-2) !important;
  }
  .mx-xxl-3 {
    margin-right: var(--s-3) !important;
    margin-left: var(--s-3) !important;
  }
  .mx-xxl-4 {
    margin-right: var(--s-4) !important;
    margin-left: var(--s-4) !important;
  }
  .mx-xxl-5 {
    margin-right: var(--s-5) !important;
    margin-left: var(--s-5) !important;
  }
  .mx-xxl-6 {
    margin-right: var(--s-6) !important;
    margin-left: var(--s-6) !important;
  }
  .mx-xxl-hero {
    margin-right: var(--s-hero) !important;
    margin-left: var(--s-hero) !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: var(--s-1) !important;
    margin-bottom: var(--s-1) !important;
  }
  .my-xxl-2 {
    margin-top: var(--s-2) !important;
    margin-bottom: var(--s-2) !important;
  }
  .my-xxl-3 {
    margin-top: var(--s-3) !important;
    margin-bottom: var(--s-3) !important;
  }
  .my-xxl-4 {
    margin-top: var(--s-4) !important;
    margin-bottom: var(--s-4) !important;
  }
  .my-xxl-5 {
    margin-top: var(--s-5) !important;
    margin-bottom: var(--s-5) !important;
  }
  .my-xxl-6 {
    margin-top: var(--s-6) !important;
    margin-bottom: var(--s-6) !important;
  }
  .my-xxl-hero {
    margin-top: var(--s-hero) !important;
    margin-bottom: var(--s-hero) !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: var(--s-1) !important;
  }
  .mt-xxl-2 {
    margin-top: var(--s-2) !important;
  }
  .mt-xxl-3 {
    margin-top: var(--s-3) !important;
  }
  .mt-xxl-4 {
    margin-top: var(--s-4) !important;
  }
  .mt-xxl-5 {
    margin-top: var(--s-5) !important;
  }
  .mt-xxl-6 {
    margin-top: var(--s-6) !important;
  }
  .mt-xxl-hero {
    margin-top: var(--s-hero) !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: var(--s-1) !important;
  }
  .me-xxl-2 {
    margin-right: var(--s-2) !important;
  }
  .me-xxl-3 {
    margin-right: var(--s-3) !important;
  }
  .me-xxl-4 {
    margin-right: var(--s-4) !important;
  }
  .me-xxl-5 {
    margin-right: var(--s-5) !important;
  }
  .me-xxl-6 {
    margin-right: var(--s-6) !important;
  }
  .me-xxl-hero {
    margin-right: var(--s-hero) !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: var(--s-1) !important;
  }
  .mb-xxl-2 {
    margin-bottom: var(--s-2) !important;
  }
  .mb-xxl-3 {
    margin-bottom: var(--s-3) !important;
  }
  .mb-xxl-4 {
    margin-bottom: var(--s-4) !important;
  }
  .mb-xxl-5 {
    margin-bottom: var(--s-5) !important;
  }
  .mb-xxl-6 {
    margin-bottom: var(--s-6) !important;
  }
  .mb-xxl-hero {
    margin-bottom: var(--s-hero) !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: var(--s-1) !important;
  }
  .ms-xxl-2 {
    margin-left: var(--s-2) !important;
  }
  .ms-xxl-3 {
    margin-left: var(--s-3) !important;
  }
  .ms-xxl-4 {
    margin-left: var(--s-4) !important;
  }
  .ms-xxl-5 {
    margin-left: var(--s-5) !important;
  }
  .ms-xxl-6 {
    margin-left: var(--s-6) !important;
  }
  .ms-xxl-hero {
    margin-left: var(--s-hero) !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n0 {
    margin: 0 !important;
  }
  .m-xxl-n1 {
    margin: -var(--s-1) !important;
  }
  .m-xxl-n2 {
    margin: -var(--s-2) !important;
  }
  .m-xxl-n3 {
    margin: -var(--s-3) !important;
  }
  .m-xxl-n4 {
    margin: -var(--s-4) !important;
  }
  .m-xxl-n5 {
    margin: -var(--s-5) !important;
  }
  .m-xxl-n6 {
    margin: -var(--s-6) !important;
  }
  .m-xxl-nhero {
    margin: -var(--s-hero) !important;
  }
  .mx-xxl-n0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-n1 {
    margin-right: -var(--s-1) !important;
    margin-left: -var(--s-1) !important;
  }
  .mx-xxl-n2 {
    margin-right: -var(--s-2) !important;
    margin-left: -var(--s-2) !important;
  }
  .mx-xxl-n3 {
    margin-right: -var(--s-3) !important;
    margin-left: -var(--s-3) !important;
  }
  .mx-xxl-n4 {
    margin-right: -var(--s-4) !important;
    margin-left: -var(--s-4) !important;
  }
  .mx-xxl-n5 {
    margin-right: -var(--s-5) !important;
    margin-left: -var(--s-5) !important;
  }
  .mx-xxl-n6 {
    margin-right: -var(--s-6) !important;
    margin-left: -var(--s-6) !important;
  }
  .mx-xxl-nhero {
    margin-right: -var(--s-hero) !important;
    margin-left: -var(--s-hero) !important;
  }
  .my-xxl-n0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-n1 {
    margin-top: -var(--s-1) !important;
    margin-bottom: -var(--s-1) !important;
  }
  .my-xxl-n2 {
    margin-top: -var(--s-2) !important;
    margin-bottom: -var(--s-2) !important;
  }
  .my-xxl-n3 {
    margin-top: -var(--s-3) !important;
    margin-bottom: -var(--s-3) !important;
  }
  .my-xxl-n4 {
    margin-top: -var(--s-4) !important;
    margin-bottom: -var(--s-4) !important;
  }
  .my-xxl-n5 {
    margin-top: -var(--s-5) !important;
    margin-bottom: -var(--s-5) !important;
  }
  .my-xxl-n6 {
    margin-top: -var(--s-6) !important;
    margin-bottom: -var(--s-6) !important;
  }
  .my-xxl-nhero {
    margin-top: -var(--s-hero) !important;
    margin-bottom: -var(--s-hero) !important;
  }
  .mt-xxl-n0 {
    margin-top: 0 !important;
  }
  .mt-xxl-n1 {
    margin-top: -var(--s-1) !important;
  }
  .mt-xxl-n2 {
    margin-top: -var(--s-2) !important;
  }
  .mt-xxl-n3 {
    margin-top: -var(--s-3) !important;
  }
  .mt-xxl-n4 {
    margin-top: -var(--s-4) !important;
  }
  .mt-xxl-n5 {
    margin-top: -var(--s-5) !important;
  }
  .mt-xxl-n6 {
    margin-top: -var(--s-6) !important;
  }
  .mt-xxl-nhero {
    margin-top: -var(--s-hero) !important;
  }
  .me-xxl-n0 {
    margin-right: 0 !important;
  }
  .me-xxl-n1 {
    margin-right: -var(--s-1) !important;
  }
  .me-xxl-n2 {
    margin-right: -var(--s-2) !important;
  }
  .me-xxl-n3 {
    margin-right: -var(--s-3) !important;
  }
  .me-xxl-n4 {
    margin-right: -var(--s-4) !important;
  }
  .me-xxl-n5 {
    margin-right: -var(--s-5) !important;
  }
  .me-xxl-n6 {
    margin-right: -var(--s-6) !important;
  }
  .me-xxl-nhero {
    margin-right: -var(--s-hero) !important;
  }
  .mb-xxl-n0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -var(--s-1) !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -var(--s-2) !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -var(--s-3) !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -var(--s-4) !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -var(--s-5) !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -var(--s-6) !important;
  }
  .mb-xxl-nhero {
    margin-bottom: -var(--s-hero) !important;
  }
  .ms-xxl-n0 {
    margin-left: 0 !important;
  }
  .ms-xxl-n1 {
    margin-left: -var(--s-1) !important;
  }
  .ms-xxl-n2 {
    margin-left: -var(--s-2) !important;
  }
  .ms-xxl-n3 {
    margin-left: -var(--s-3) !important;
  }
  .ms-xxl-n4 {
    margin-left: -var(--s-4) !important;
  }
  .ms-xxl-n5 {
    margin-left: -var(--s-5) !important;
  }
  .ms-xxl-n6 {
    margin-left: -var(--s-6) !important;
  }
  .ms-xxl-nhero {
    margin-left: -var(--s-hero) !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: var(--s-1) !important;
  }
  .p-xxl-2 {
    padding: var(--s-2) !important;
  }
  .p-xxl-3 {
    padding: var(--s-3) !important;
  }
  .p-xxl-4 {
    padding: var(--s-4) !important;
  }
  .p-xxl-5 {
    padding: var(--s-5) !important;
  }
  .p-xxl-6 {
    padding: var(--s-6) !important;
  }
  .p-xxl-hero {
    padding: var(--s-hero) !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: var(--s-1) !important;
    padding-left: var(--s-1) !important;
  }
  .px-xxl-2 {
    padding-right: var(--s-2) !important;
    padding-left: var(--s-2) !important;
  }
  .px-xxl-3 {
    padding-right: var(--s-3) !important;
    padding-left: var(--s-3) !important;
  }
  .px-xxl-4 {
    padding-right: var(--s-4) !important;
    padding-left: var(--s-4) !important;
  }
  .px-xxl-5 {
    padding-right: var(--s-5) !important;
    padding-left: var(--s-5) !important;
  }
  .px-xxl-6 {
    padding-right: var(--s-6) !important;
    padding-left: var(--s-6) !important;
  }
  .px-xxl-hero {
    padding-right: var(--s-hero) !important;
    padding-left: var(--s-hero) !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: var(--s-1) !important;
    padding-bottom: var(--s-1) !important;
  }
  .py-xxl-2 {
    padding-top: var(--s-2) !important;
    padding-bottom: var(--s-2) !important;
  }
  .py-xxl-3 {
    padding-top: var(--s-3) !important;
    padding-bottom: var(--s-3) !important;
  }
  .py-xxl-4 {
    padding-top: var(--s-4) !important;
    padding-bottom: var(--s-4) !important;
  }
  .py-xxl-5 {
    padding-top: var(--s-5) !important;
    padding-bottom: var(--s-5) !important;
  }
  .py-xxl-6 {
    padding-top: var(--s-6) !important;
    padding-bottom: var(--s-6) !important;
  }
  .py-xxl-hero {
    padding-top: var(--s-hero) !important;
    padding-bottom: var(--s-hero) !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: var(--s-1) !important;
  }
  .pt-xxl-2 {
    padding-top: var(--s-2) !important;
  }
  .pt-xxl-3 {
    padding-top: var(--s-3) !important;
  }
  .pt-xxl-4 {
    padding-top: var(--s-4) !important;
  }
  .pt-xxl-5 {
    padding-top: var(--s-5) !important;
  }
  .pt-xxl-6 {
    padding-top: var(--s-6) !important;
  }
  .pt-xxl-hero {
    padding-top: var(--s-hero) !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: var(--s-1) !important;
  }
  .pe-xxl-2 {
    padding-right: var(--s-2) !important;
  }
  .pe-xxl-3 {
    padding-right: var(--s-3) !important;
  }
  .pe-xxl-4 {
    padding-right: var(--s-4) !important;
  }
  .pe-xxl-5 {
    padding-right: var(--s-5) !important;
  }
  .pe-xxl-6 {
    padding-right: var(--s-6) !important;
  }
  .pe-xxl-hero {
    padding-right: var(--s-hero) !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: var(--s-1) !important;
  }
  .pb-xxl-2 {
    padding-bottom: var(--s-2) !important;
  }
  .pb-xxl-3 {
    padding-bottom: var(--s-3) !important;
  }
  .pb-xxl-4 {
    padding-bottom: var(--s-4) !important;
  }
  .pb-xxl-5 {
    padding-bottom: var(--s-5) !important;
  }
  .pb-xxl-6 {
    padding-bottom: var(--s-6) !important;
  }
  .pb-xxl-hero {
    padding-bottom: var(--s-hero) !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: var(--s-1) !important;
  }
  .ps-xxl-2 {
    padding-left: var(--s-2) !important;
  }
  .ps-xxl-3 {
    padding-left: var(--s-3) !important;
  }
  .ps-xxl-4 {
    padding-left: var(--s-4) !important;
  }
  .ps-xxl-5 {
    padding-left: var(--s-5) !important;
  }
  .ps-xxl-6 {
    padding-left: var(--s-6) !important;
  }
  .ps-xxl-hero {
    padding-left: var(--s-hero) !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: var(--s-1) !important;
  }
  .gap-xxl-2 {
    gap: var(--s-2) !important;
  }
  .gap-xxl-3 {
    gap: var(--s-3) !important;
  }
  .gap-xxl-4 {
    gap: var(--s-4) !important;
  }
  .gap-xxl-5 {
    gap: var(--s-5) !important;
  }
  .gap-xxl-6 {
    gap: var(--s-6) !important;
  }
  .gap-xxl-hero {
    gap: var(--s-hero) !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: var(--s-1) !important;
  }
  .row-gap-xxl-2 {
    row-gap: var(--s-2) !important;
  }
  .row-gap-xxl-3 {
    row-gap: var(--s-3) !important;
  }
  .row-gap-xxl-4 {
    row-gap: var(--s-4) !important;
  }
  .row-gap-xxl-5 {
    row-gap: var(--s-5) !important;
  }
  .row-gap-xxl-6 {
    row-gap: var(--s-6) !important;
  }
  .row-gap-xxl-hero {
    row-gap: var(--s-hero) !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: var(--s-1) !important;
  }
  .column-gap-xxl-2 {
    column-gap: var(--s-2) !important;
  }
  .column-gap-xxl-3 {
    column-gap: var(--s-3) !important;
  }
  .column-gap-xxl-4 {
    column-gap: var(--s-4) !important;
  }
  .column-gap-xxl-5 {
    column-gap: var(--s-5) !important;
  }
  .column-gap-xxl-6 {
    column-gap: var(--s-6) !important;
  }
  .column-gap-xxl-hero {
    column-gap: var(--s-hero) !important;
  }
  .fs-xxl-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .fs-xxl-2 {
    font-size: calc(1.325rem + 0.9vw) !important;
  }
  .fs-xxl-3 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-xxl-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-xxl-5 {
    font-size: 1.25rem !important;
  }
  .fs-xxl-6 {
    font-size: 1rem !important;
  }
  .fs-xxl-xxxs {
    font-size: var(--text-xxxs) !important;
  }
  .fs-xxl-xxs {
    font-size: var(--text-xxs) !important;
  }
  .fs-xxl-xs {
    font-size: var(--text-xs) !important;
  }
  .fs-xxl-sm {
    font-size: var(--text-sm) !important;
  }
  .fs-xxl-md {
    font-size: var(--text-md) !important;
  }
  .fs-xxl-lg {
    font-size: var(--text-lg) !important;
  }
  .fs-xxl-xl {
    font-size: var(--text-xl) !important;
  }
  .fs-xxl-xxl {
    font-size: var(--text-xxl) !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
  .grid-cols-xxl-2 {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .grid-cols-xxl-3 {
    grid-template-columns: repeat(3, 1fr) !important;
  }
  .grid-cols-xxl-4 {
    grid-template-columns: repeat(4, 1fr) !important;
  }
  .grid-cols-xxl-6 {
    grid-template-columns: repeat(6, 1fr) !important;
  }
  .grid-cols-xxl-8 {
    grid-template-columns: repeat(8, 1fr) !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
  .fs-sm-1 {
    font-size: 2.5rem !important;
  }
  .fs-sm-2 {
    font-size: 2rem !important;
  }
  .fs-sm-3 {
    font-size: 1.75rem !important;
  }
  .fs-sm-4 {
    font-size: 1.5rem !important;
  }
  .fs-md-1 {
    font-size: 2.5rem !important;
  }
  .fs-md-2 {
    font-size: 2rem !important;
  }
  .fs-md-3 {
    font-size: 1.75rem !important;
  }
  .fs-md-4 {
    font-size: 1.5rem !important;
  }
  .fs-lg-1 {
    font-size: 2.5rem !important;
  }
  .fs-lg-2 {
    font-size: 2rem !important;
  }
  .fs-lg-3 {
    font-size: 1.75rem !important;
  }
  .fs-lg-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}