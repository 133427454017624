label {
  font-family: var(--font-sans);
  letter-spacing: var(--letter-spacing-display);
}

.form-floating > label {
  text-align: initial;
}

.form-section {
  border-radius: var(--border-radius-lg);
  margin-bottom: var(--s-4);
  padding: var(--s-3);
  border: var(--border-width) var(--border-style) var(--border-color);
}

.form-input-section {
  margin-bottom: var(--s-4);
}

.form-input-section-legend {
  font-size: inherit;
}

.form-header-indicator {
  border-radius: 50%;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
  aspect-ratio: 1;
  height: 3rem;
  margin-right: var(--s-3);
  padding: var(--s-2);
  width: 3.25rem;
}

.form-primary .form-label,
.form-primary legend {
  color: var(--primary);
  font-size: 1.0625rem;
}

.form-primary h1,
.form-primary h2,
.form-primary h3,
.form-primary h4,
.form-primary h5,
.form-primary h6,
.form-primary .form-label,
.form-primary legend {
  color: var(--primary);
}

