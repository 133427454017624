// Sans

@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 100;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-Thin.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 300;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-Light.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-Regular.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-Medium.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiBold.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-Bold.woff2") format("woff2");
  font-display: swap;
}

// Sans Italic

@font-face {
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 100;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-ThinItalic.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 300;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-LightItalic.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 400;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-Italic.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 600;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiBoldItalic.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  font-style: italic;
  font-weight: 700;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-BoldItalic.woff2") format("woff2");
  font-display: swap;
}

// Sans SemiCondensed

@font-face {
  font-family: "Noto Sans SemiCondensed";
  font-style: normal;
  font-weight: 100;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiCondensed.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans SemiCondensed";
  font-style: normal;
  font-weight: 300;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiCondensedLight.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans SemiCondensed";
  font-style: normal;
  font-weight: 400;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiCondensed.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans SemiCondensed";
  font-style: normal;
  font-weight: 500;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiCondensedMedium.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans SemiCondensed";
  font-style: normal;
  font-weight: 600;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiCondensedSemiBold.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans SemiCondensed";
  font-style: normal;
  font-weight: 700;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiCondensedBold.woff2") format("woff2");
  font-display: swap;
}

// Sans SemiCondensedItalic

@font-face {
  font-family: "Noto Sans SemiCondensed";
  font-style: italic;
  font-weight: 100;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiCondensedItalic.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans SemiCondensed";
  font-style: italic;
  font-weight: 300;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiCondensedLightItalic.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans SemiCondensed";
  font-style: italic;
  font-weight: 400;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiCondensedItalic.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans SemiCondensed";
  font-style: italic;
  font-weight: 500;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiCondensedMediumItalic.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans SemiCondensed";
  font-style: italic;
  font-weight: 600;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiCondensedSemiBoldItalic.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans SemiCondensed";
  font-style: italic;
  font-weight: 700;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans/v2.015/NotoSans-SemiCondensedBoldItalic.woff2") format("woff2");
  font-display: swap;
}

// Sans Serif

@font-face {
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 300;
  src: url("https://cdn.down-syndrome.org/fonts/noto-serif/v2.015/NotoSerif-Light.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 400;
  src: url("https://cdn.down-syndrome.org/fonts/noto-serif/v2.015/NotoSerif-Regular.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Serif";
  font-style: italic;
  font-weight: 300;
  src: url("https://cdn.down-syndrome.org/fonts/noto-serif/v2.015/NotoSerif-ThinItalic.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Serif";
  font-style: italic;
  font-weight: 400;
  src: url("https://cdn.down-syndrome.org/fonts/noto-serif/v2.015/NotoSerif-Italic.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 700;
  src: url("https://cdn.down-syndrome.org/fonts/noto-serif/v2.015/NotoSerif-Bold.woff2") format("woff2");
  font-display: swap;
}

// Mono

@font-face {
  font-family: "Noto Sans Mono";
  font-style: normal;
  font-weight: 400;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans-mono/v2.014/NotoSansMono-Regular.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans Mono";
  font-style: normal;
  font-weight: 600;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans-mono/v2.014/NotoSansMono-SemiBold.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans Mono";
  font-style: normal;
  font-weight: 700;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans-mono/v2.014/NotoSansMono-Bold.woff2") format("woff2");
  font-display: swap;
}

// Mono SemiCondensed

@font-face {
  font-family: "Noto Sans Mono SemiCondensed";
  font-style: normal;
  font-weight: 400;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans-mono/v2.014/NotoSansMono-SemiCondensed.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans Mono SemiCondensed";
  font-style: normal;
  font-weight: 600;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans-mono/v2.014/NotoSansMono-SemiCondensedSemiBold.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans Mono SemiCondensed";
  font-style: normal;
  font-weight: 700;
  src: url("https://cdn.down-syndrome.org/fonts/noto-sans-mono/v2.014/NotoSansMono-SemiCondensedBold.woff2") format("woff2");
  font-display: swap;
}
