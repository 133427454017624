$prefix: ""; // Bootstrap's prefix
$dse-prefix: ""; // DSE's prefix
$dse-root-vars: ();

// Sets a var to the base :root
@mixin root-var($name, $value) {
  $dse-root-vars: map-merge($dse-root-vars, (#{$name}: $value)) !global;
}

// Sets all vars in a map to the current scope
@mixin set-vars($var-map) {
  @each $name, $value in $var-map {
    --#{$name}: #{$value};
  }
}
