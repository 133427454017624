@each $spacer, $value in $spacers {
  @include root-var("s-#{$spacer}", $value);
}

@each $color, $value in $all-colors {
  @include root-var("#{$color}", $value);
}

@include root-var("s-6", 5rem);
@include root-var("s-hero", clamp(4rem, 3.5rem + 2vw, 5rem));

$spacers: (
  "0": 0,
  "1": var(--s-1),
  "2": var(--s-2),
  "3": var(--s-3),
  "4": var(--s-4),
  "5": var(--s-5),
  "6": var(--s-6),
  "hero": var(--s-hero),
);

$font-sans: "Noto Sans", ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol", "Noto Color Emoji";
@include root-var(font-sans, $font-sans);
$font-serif: "Noto Serif", "Georgia", ui-serif, serif;
@include root-var(font-serif, $font-serif);
$font-monospace: "Noto Sans Mono", ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace;
@include root-var(font-monospace, $font-monospace);
$font-sans-semi-condensed: "Noto Sans SemiCondensed", serif;
$font-monospace-semi-condensed: "Noto Sans Mono SemiCondensed", serif;
$font-system: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji";
@include root-var(font-system, $font-system);
$font-system-sans: $font-system;
$font-system-serif: -apple-system-ui-serif, Georgia, "Times New Roman", serif, "Apple Color Emoji",
  "Segoe UI Emoji";

$font-families: (
  "sans": $font-sans,
  "serif": $font-serif,
  "monospace": $font-monospace,
  "sans-semi-condensed": $font-sans-semi-condensed,
  "monospace-semi-condensed": $font-monospace-semi-condensed,
  "system": $font-system,
  "system-sans": $font-system-sans,
  "system-serif": $font-system-serif,
);

$body-color: map-get($grays, 700);
$body-color-dark: map-get($grays, 300);

// Font sizes
$font-sizes-text: (
  xxxs: 0.625rem,
  xxs: 0.75rem,
  xs: 0.875rem,
  sm: 0.9375rem,
  md: 1rem,
  lg: $font-size-lg,
  xl: 1.25rem,
  xxl: 1.5rem,
);

@each $size, $value in $font-sizes-text {
  @include root-var("text-#{$size}", $value);
}

$font-size-nav: var(--text-sm);
$font-size-tables: var(--text-sm);
$font-size-figures: var(--text-sm);
$font-size-asides: var(--text-sm);

$font-weights: (
  "thin": 100,
  "extralight": 200,
  "light": 300,
  "normal": 400,
  "medium": 500,
  "semibold": 600,
  "bold": 700,
  "extrabold": 800,
  "black": 900,
);

@each $weight, $value in $font-weights {
  @include root-var("font-#{$weight}", $value);
}

$letter-spacing-text: normal;
@include root-var(letter-spacing-text, $letter-spacing-text);
$letter-spacing-display: -0.025em;
@include root-var(letter-spacing-display, $letter-spacing-display);

$link-color: $primary;
$link-decoration: none;
$link-hover-decoration: underline;

$paragraph-margin-bottom: 1em;

$prose-width: 45rem;
@include root-var(mw-prose, $prose-width);
$prose-line-height: 1.75;
@include root-var(prose-line-height, $prose-line-height);

$list-margin-bottom: $paragraph-margin-bottom;

$headings-margin-bottom: var(--s-3);
$headings-font-family: var(--font-sans);
$headings-font-weight: var(--font-semibold);
$headings-line-height: var(--line-height-display);

$h1-font-size: 2.5rem;
$h2-font-size: 2rem;
$h3-font-size: 1.75rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1rem;

$display-font-weight: var(--font-medium);
$display-line-height: var(--line-height-display);

$lead-font-size: min(5vw, var(--text-lg));
$lead-font-weight: var(--font-medium);
$lead-font-family: var(--font-sans);

$content-main-header-margin-top: 0.75em;

$btn-font-weight: var(--font-normal);
$btn-border-radius-sm: var(--border-radius-sm);
$btn-border-radius: var(--border-radius);
$btn-border-radius-lg: var(--border-radius-lg);
$btn-transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out, border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;

$btn-padding-y: var(--s-1);
$btn-padding-y-lg: 0.5rem;
$btn-padding-x-lg: 1.5rem;

$btn-font-size-lg: var(--text-md);

$navbar-padding-y: 0.1875rem;
$navbar-nav-link-padding-x: var(--s-3);
$navbar-brand-font-size: 0.9375rem;
$navbar-toggler-focus-width: 0; // Hides default focus box shadow

$navbar-background-color: var(--primary);
$navbar-dark-toggler-border-color: transparent;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
$navbar-dark-color: var(--white);
$navbar-dark-hover-color: var(--white);
$navbar-dark-active-color: var(--white);

$dropdown-font-size: var(--text-sm);
$dropdown-border-radius: var(--border-radius);
$dropdown-link-color: var(--gray-900);
$dropdown-link-hover-color: var(--dark);
$dropdown-link-hover-bg: var(--gray-200);
$dropdown-link-disabled-color: var(--gray-500);
$dropdown-header-color: var(--gray-600);

$search-container-max-width: 25rem;

$footer-header-font-weight: var(--font-medium);
$footer-text-color: var(--gray-900);
$footer-text-color-dark: var(--gray-400);
$footer-text-hover-color: var(--black);
$footer-text-hover-color-dark: var(--gray-300);
$footer-background-color: var(--gray-100);
$footer-background-color-dark: var(--gray-900);
$footer-link-font-size: var(--text-sm);
$footer-header-font-size: var(--text-lg);
$footer-header-margin-top: 0;
$footer-header-margin-bottom: 0.375rem;
$footer-padding-top: max(8vmin, var(--s-5));
$footer-section-padding: var(--s-3);
$footer-link-text-decoration-hover: underline;
$site-footer-img-height: 80px;
$site-footer-img-margin-bottom: $spacer;

$breadcrumb-font-size: 0.9375rem;
$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding-x: to-rem(6);
$breadcrumb-margin-bottom: 0;
$breadcrumb-bg: transparent;
$breadcrumb-divider-color: var(--body-color);
$breadcrumb-active-color: var(--body-color);
$breadcrumb-divider: "\F285"; // Requires Bootstrap Icons font

$form-label-font-weight: var(--font-medium);
$form-label-color: var(--gray-600);

$badge-font-size: var(--text-xxs);
$badge-font-weight: var(--font-semibold);
$badge-color: var(--white);
$badge-padding-y: 0.45em;
$badge-padding-x: 0.65em;
$badge-border-radius: var(--border-radius-sm);

$highlight-padding-y: 2px !default;
$highlight-padding-x: 6px !default;
$highlight-bg: #f6f6f6 !default;
$highlight-border-color: #e4e4e4 !default;
$highlight-border-radius: $border-radius !default;
$highlight-border-width: $border-width !default;
$highlight-bg-scale: -80% !default;
$highlight-border-scale: -70% !default;
$highlight-color-scale: 40% !default;

// https://getbootstrap.com/docs/5.3/utilities/spacing/#negative-margin
$enable-negative-margins: true;

// https://getbootstrap.com/docs/5.3/layout/css-grid/
$enable-cssgrid: true;
