.w-300px {
  width: 300px;
}

.w-400px {
  width: 300px;
}

.w-500px {
  width: 300px;
}

$utilities: map-merge(
  $utilities,
  (
    "width":
      map-merge(
        map-get($utilities, "width"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "width"), "values"),
              (
                "10": 10%,
                "15": 15%,
                "20": 20%,
                "25": 25%,
                "30": 30%,
                "35": 35%,
                "40": 40%,
                "45": 45%,
                "55": 55%,
                "60": 60%,
                "65": 65%,
                "80": 80%,
                "85": 85%,
                "90": 90%,

                "10rem": 10rem,
                "12rem": 12rem,
                "15rem": 15rem,
                "18rem": 18rem,
                "20rem": 20rem,
                "22rem": 22rem,
                "25rem": 25rem,
                "28rem": 28rem,
                "30rem": 30rem,
                "32rem": 32rem,
                "35rem": 35rem,
                "40rem": 40rem,
                "45rem": 45rem,
                "50rem": 50rem,
                "55rem": 55rem,
                "60rem": 60rem,
                "65rem": 65rem,
                "70rem": 70rem,
                "75rem": 75rem,
                "80rem": 80rem,
              )
            ),
        )
      ),
  )
);
