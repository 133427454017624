html,
body {
  font-kerning: auto;
  font-variant-ligatures: common-ligatures;
  text-rendering: optimizeLegibility;
  letter-spacing: var(--letter-spacing-text);
}

#content {
  min-height: 50vh;
}

// Decreases the top margin on a header when it directly precedes a .lead
.lead + h2 {
  margin-top: var(--s-3);
}

.lead {
  font-family: $lead-font-family;
  font-size: var(--text-xl);
  font-weight: var(--font-normal);
}

blockquote {
  padding-left: 0.75rem;
  margin-left: 0;
  font-style: italic;
  border-left: 0.25rem solid var(--border-color);
}

@media print {
  #content {
    min-height: 0;
  }
}
