.small-caps {
  font-variant: small-caps;
}

.all-small-caps {
  font-variant: all-small-caps;
}

.numeric-slashed-zero {
  font-variant-numeric: slashed-zero;
}

.numeric-ordinal {
  font-variant-numeric: ordinal;
}
