.card-footer {
  background-color: transparent;
  border-top: none;
}

.card-btn-bottom {
  height: 2.5rem;

  .btn {
    position: absolute;
    bottom: var(--s-3);
  }
}
