span-phoneme,
span-grapheme,
span-letter,
span-sound,
span-word,
span-phrase,
span-sentence,
span-digit,      // single symbol used to make numerals
span-numeral     // symbol or name that stands for a number
{
  display: inline;
  font-family: var(--font-sans);
}

// TODO: refine colours and styling

span-phoneme,
span-sound {
  color: rgb(160, 50, 50);
}

span-grapheme,
span-letter {
  color: rgb(56, 28, 118);
}

span-word {
  color: rgb(35, 104, 151);
}

span-phrase,
span-sentence {
  color: rgb(21, 116, 103);
}

span-digit,
span-numeral {
  color: rgb(45, 120, 170);
}

span-phoneme::before {
  content: "/";
}

span-phoneme::after {
  content: "/";
}

span-grapheme::before {
  content: "⟨";
}

span-grapheme::after {
  content: "⟩";
}

span-word::before {
  content: "⟨";
}

span-word::after {
  content: "⟩";
}

span-sentence::before,
span-phrase::before {
  content: "{";
}

span-sentence::after,
span-phrase::after {
  content: "}";
}
